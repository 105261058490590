export const landingImage1 = require('../assets/landing-image-1.png');
export const platformImage = require('../assets/platform.png');
export const analyticsImage = require('../assets/analytics.png');
export const applestoreImage = require('../assets/applestore.png');
export const computerImage = require('../assets/computer.png');
export const googleplayImage = require('../assets/googleplay.png');
export const keyboardImage = require('../assets/keyboard.png');
export const tabletImage = require('../assets/tablet.png');
export const glassesImage = require('../assets/glasses.png');
export const card1Image = require('../assets/card2.png');
export const card2Image = require('../assets/card1.png');
export const lampImage = require('../assets/lamp.svg');
export const lamp2Svg = require('../assets/lamp-2.svg');
export const appLogo = require('../assets/app-logo.png');
export const upArrow = require('../assets/up.png');
