Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";
exports.UpdateAdminApiMethodType = "PUT";

exports.UpdateAdminApiEndPoint ="bx_block_user/users/update_user?id="
exports.getShowCompaniesEndPoint ="bx_block_user/industry_sectors"
exports.UpdateShowComaniesEndPoint ="bx_block_user/industry_sectors/"
exports.PostShowIndusSectors="bx_block_user/industry_sectors"
exports.getDistrictsEndPoint ="bx_block_user/districts"
exports.UpdateDistrictsEndPoint ="bx_block_user/districts/"
exports.PostDistrictsEndPoint ="bx_block_user/districts"
exports.industrySectorsSelectedEndPoint ="bx_block_user/industry_sectors/industry_sectors"
exports.getTermsAndConditions = "bx_block_terms_and_conditions/terms_and_conditions/latest_record";
exports.getPrivacyPolicy = "bx_block_privacy_policy/privacy_policies/latest_record";
exports.districtSectorsEndPoint ="bx_block_user/districts/districts"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End