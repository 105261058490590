import React, { FunctionComponent, useState, forwardRef } from 'react';
import { Typography, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface Props {
    readOnly?:boolean;
    type?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    style?: React.CSSProperties;
    placeholder?: string;
    error?: string;
    value?: string;
    id?: string;
    maxLength?: number;
    autoComplete?: string;
    ref?: React.Ref<HTMLInputElement>;
    name?: string;
    disabled?: boolean;
    isHeadingBold?: boolean;
    inputStyle?: React.CSSProperties;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
    minValueName?: string;
    maxValueName?: string;
    otherValue?: string;
}

const Input: FunctionComponent<Props> = forwardRef(({
    readOnly=false,
    type = '',
    onChange,
    label = '',
    style = {},
    placeholder = '',
    inputStyle = {},
    error = '',
    id = '',
    value = '',
    disabled = false,
    isHeadingBold = true,
    maxLength = undefined,
    autoComplete = "on",
    name = '',
    min,
    max,
    minValueName,
    maxValueName,
    otherValue,
    ...props
},
    ref
) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        
        if (type === 'number' && !/[0-9]/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (props.onKeyPress) {
            props.onKeyPress(event);
        }
    };

    return (
        <div style={{ ...styles.container, ...style }}>
            <style>
                {`
            input::-ms-reveal,
            input::-ms-clear {
              display: none;
            }
            input[type=number]{
                -moz-appearance: textfield;
            }
           `}
            </style>
            <label htmlFor="myInput">
                <Typography style={isHeadingBold ? styles.labelBold : styles.labelNormal}>{label}</Typography>
            </label>
            <div style={{ position: 'relative', display: 'flex' }}>
                <input
                    readOnly={readOnly}
                    disabled = {label.startsWith("Statement")}
                    autoComplete={autoComplete}
                    maxLength={maxLength}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    ref={ref}
                    style={{
                        ...styles.input,
                        ...inputStyle,
                        ...((!value && error) && { ...styles.error }),
                        ...((disabled) && { ...styles.disabled })
                    }}
                    type={showPassword ? 'text' : type}
                    onKeyPress={handleKeyPress}
                    name={name}
                    min={min}
                    max={max}
                    {...props}
                    // draggable
                />
            </div>
        </div>
    )
});

const styles = {
    container: {
        display: 'flex',
        flex: '1 1 0px',
        flexDirection: 'column' as const,
    },
    input: {
        display: 'flex',
        padding: '16px',
        paddingRight : "16px",// Adjust padding as needed
        flex: '1 0 0',
        alignSelf: 'stretch',
        borderRadius: '10px',
        border: '1px solid #E0E0E0',
        background: '#FFF',
        outline: 'none',
        color: '#1C386D',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        boxSizing: 'border-box' as const,
        width: '100%',
        
    },
    labelBold: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontFamily: 'aeonik-bold',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '12px'
    },
    labelNormal: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginBottom: '12px',
        fontFamily: 'aeonik-regular',
    },
    error: {
        border: '1px solid red'
    },
    errorText: {
        color: 'red',
        marginTop: '8px'
    },
    disabled: {
        cursor: 'not-allowed',
        background: '#F5F5F5',
        
    },
    '@global': {
        '.input[type="password"]::-ms-reveal, .input[type="password"]::-ms-clear': {
            display: 'none',
        },
    },
};

export default Input;
