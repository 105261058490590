import React from "react";

import {
  Box,
  Typography,
  Grid,
  // Customizable Area Start
  Tabs,
  Tab,
  // Customizable Area End
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import DashboardController, { Props } from "./DashboardController.web";
import SurveyCardWeb from "../../../components/src/SurveyCard.web";
import ExportAnalyticsWeb from "./ExportAnalytics.web";
// Customizable Area End

export class DashboardWeb extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { surveyList } = this.state;
    const { classes } = this.props;
    const { tabValue } = this.state;
    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    // Customizable Area End

    return (
      // Customizable Area Start

<Box className={classes.mainContainer}>
  
          <Box sx={{ width: '100%' }} className={classes.tabs} >
            <Box className={classes.cardHeader} >
              <Tabs value={tabValue} data-test-id="tabChange" onChange={this.handleChangeTab} aria-label="basic tabs example" id="tabChangeSection"
                TabIndicatorProps={{ style: { background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)", height: "10px", borderRadius: "15px 15px 0 0" } }
                }>
                <Tab   className={classes.heading} label="Dashboard" {...a11yProps(0)} />
                <Tab  className={classes.heading} label="Ad-Hoc Reporting" {...a11yProps(1)} />
                <Tab  className={classes.heading} label="Export bulk results" {...a11yProps(2)} />
              </Tabs>
            </Box>
            {tabValue === 0 && (
             
              <Box className={classes.maintab} >
    
              <Grid container spacing={3}>
                {surveyList.map((unitSurvey: any, ind: number) => (
                  <SurveyCardWeb
                    exportSurvey={()=>this.exportSurveyReport(unitSurvey.id,unitSurvey.attributes.name)}
                    // exportSurvey={() => this.jsonToExcel({})}
                    surveyData={unitSurvey}
                    surveyInd={ind}
                    key={`${unitSurvey?.id}-${ind}`}
                  />
                ))}
              </Grid>
            </Box>
            )}
            {tabValue === 1 && (            
        
              <> AdHoc Page.....</>  

            )}
            {tabValue === 2 && (

              
              <>
              <ExportAnalyticsWeb id={this.props.id} navigation={this.props.navigation}/>
              </>

            )}
            
          </Box>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  mainContainer: {
    border: "1px solid #ddd",
    margin: "50px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    height: "auto",
    margintop:"0px",
    paddingTop:"0px",
    flex: 1,
    "@media (max-width: 500px)": {
      margin: "20px",
    },
  },
  maintab: {
    borderRadius: "20px",
    background: "#FFFFFF",
    height: "auto",
    padding:"32px",
    margintop:"0px",
    paddingTop:"0px",
    flex: 1,
    "@media (max-width: 500px)": {
      margin: "20px",
    },
  },
  header: {
    height: "48px",
    borderBottom: "1px solid #dedede",
    marginBottom: "42px",
  },
  heading: {
    '&.MuiTab-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: ' 13px',
      fontFamily: 'aeonik-bold',
      color: ' #202020',
      opacity: ' 0.4',
      textTransform: 'none' as const,
      minHeight: "100px",

    },
    '&.MuiTab-root.Mui-selected': {
      color: ' #202020',
      opacity: 1
    },
    
  },
  cardHeader: {
    borderBottom: '2px solid #E7E7E7',
    marginBottom:"20px",
    paddingLeft:'20px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 402px)": {
      padding: '0 10px',
    }
  },
};
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(DashboardWeb);
// Customizable Area End
