// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { buildRequestMessage } from "../../../components/src/buildRequestMessage.web";

export const configJSON = require("./config");

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface Survey {
  id?: number
  name: string;
  description: string;
  start_date: string | null;
  start_time: string | null;
  end_date: string | null;
  end_time: string | null;
  respondents: number | null;
  is_activated: boolean;
}

interface S {
  visible: boolean;
  surveys: Survey[];
  surveyName: string;
  surveyDescription: string;
  survey: Survey;
  errors: any;
  visibleDeleteConfirmationDialog: boolean;
  surveyId: number | null;
  loading: boolean;
}

interface SS {
  // visible: boolean;
  // surveys: 
}

export default class SurveysTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      visible: false,
      surveys: [],
      surveyName: "",
      surveyDescription: "",
      errors: {
        surveyName: '',
        surveyDescription: ''
      },
      survey: {
        name: "",
        description: "",
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        respondents: null,
        is_activated: false
      },
      visibleDeleteConfirmationDialog: false,
      surveyId: null,
      loading: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  public RequestMessage = {
    createSurveyTemplate: buildRequestMessage(Method.POST, configJSON),
    getSurveys: buildRequestMessage(Method.GET, configJSON),
    editSurveyTemplate: buildRequestMessage(Method.POST, configJSON),
    deleteSurveyTemplate: buildRequestMessage(Method.DELETE, configJSON),
    Null: undefined as any,
  };

  async componentDidMount() {
    this.getSurveys();
  }

  createSurveyTemplate = (form: any) => {
    this.RequestMessage.createSurveyTemplate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSurveyTemplateEndpoint
    );

    this.RequestMessage.createSurveyTemplate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );
    this.setState({ loading: true })
    runEngine.sendMessage(this.RequestMessage.createSurveyTemplate.id, this.RequestMessage.createSurveyTemplate);
  };

  getSurveys = () => {
    this.RequestMessage.getSurveys.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSurveysEndpoint
    );

    runEngine.sendMessage(this.RequestMessage.getSurveys.id, this.RequestMessage.getSurveys);
  }

  editSurveyTemplate = (form: any) => {
    const id = form.id;

    this.RequestMessage.editSurveyTemplate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_surveys/surveys/update_details/?id=${id}`
    );

    this.RequestMessage.editSurveyTemplate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );
    this.setState({ loading: true });
    runEngine.sendMessage(this.RequestMessage.editSurveyTemplate.id, this.RequestMessage.editSurveyTemplate);
  };

  deleteSurveyTemplate = (id: number) => {
    this.RequestMessage.deleteSurveyTemplate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSurveyTemplateEndpoint + "?id=" + id
    );

    this.RequestMessage.deleteSurveyTemplate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ is_activated: false })
    );
    this.setState({ loading: true });
    runEngine.sendMessage(this.RequestMessage.deleteSurveyTemplate.id, this.RequestMessage.deleteSurveyTemplate);
  }

  addSurvey = (response: any) => {
    this.setState({ visible: false, surveyName: "", surveyDescription: "" });
    const surveyId = response?.data?.id;
    this.props.navigation.navigate("SurveyQuestions", { surveyId: surveyId });
  };

  updateSurveys = (response: any) => {
    const surveys = response?.surveys?.data?.map((el: any) => {
      if (el.attributes?.start_date) {
        return {
          ...el?.attributes,
          isLiveOrPast: new Date() > new Date(el.attributes.start_date) ? true : false,
        }  
      } else {
        return {
          ...el?.attributes,
          isLiveOrPast: false,
        } 
      }
    });
    this.setState({ surveys });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    this.setState({ loading: false });

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (callID) {
      case this.RequestMessage.createSurveyTemplate.messageId:
        if (response) {
          this.addSurvey(response);
        }
        break;
      case this.RequestMessage.getSurveys.messageId:
        if (response) {
          this.updateSurveys(response);
        }
      case this.RequestMessage.editSurveyTemplate.messageId:
        if (response) {
          this.handleEditSurveyResponse(response);
        }
        break;
      case this.RequestMessage.deleteSurveyTemplate.messageId:
        if (response) {
          this.handledeleteSurveyResponse(response);
        }
        break;
    }
  }

  handledeleteSurveyResponse = (response:any) => {
     if (response.message) {
      this.setState({
        surveys: this.state.surveys.filter((el: any) => el.id !== this.state.surveyId),
        visibleDeleteConfirmationDialog: false,
        surveyId: null });

     }
  }

  handleEditSurveyResponse = (response:any) => {
    if (response?.data) {
      const surveys = this.state.surveys.map((el: any) => {
        if (el.id === Number(response.data.id)) {
          return {
            ...el,
            name: response.data.attributes.name,
            description: response.data.attributes.description
          }
        }
        return el;
      });
      this.setState({ surveys, visible: false, surveyId: null });
    }
  }

  renderCard = () => {
    return this.state.surveys;
  }

  surveyNameChange = (e: any) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/^\s+/, '');
    this.setState({
      surveyName: trimmedValue,
      errors: {
        ...this.state.errors,
        surveyName: ''
      }
    });
  }

  surveyDescriptionChange = (e: any) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/^\s+/, '');
    this.setState({
      surveyDescription: trimmedValue,
      errors: {
        ...this.state.errors,
        surveyDescription: ''
      }
    });
  }

  createSurveyClick = () => {
    if(!this.state.surveyName && !this.state.surveyDescription){
      this.setState(prevState => { return { ...prevState, errors: { ...prevState.errors, surveyName: 'Missed required fields' } } });
      this.setState(prevState => { return { ...prevState, errors: { ...prevState.errors, surveyDescription: 'Missed required fields' } } });
      return;
    }
    if (!this.state.surveyName) {
      this.setState(prevState => { return { ...prevState, errors: { ...prevState.errors, surveyName: 'Missed required fields' } } });
      return;
    }

    if (!this.state.surveyDescription) {
      this.setState(prevState => { return { ...prevState, errors: { ...prevState.errors, surveyDescription: 'Missed required fields' } } });
      return;
    }

    this.createSurveyTemplate({
      survey: {
        name: this.state.surveyName,
        description: this.state.surveyDescription,
        is_acitvated: true
      }
    })
  }

  openCreateSurveyDialog = () => {
    this.setState({ visible: true, surveyId: null, surveyName: "", surveyDescription: "" });
  }

  closeDialog = () => {
    this.setState({ visible: false });
    this.setState({ errors: {} });
  }

  onDeleteClick = (id: number) => {
    this.setState({ visibleDeleteConfirmationDialog: true, surveyId: id });
  }

  onDeleteSurvey = () => {
    const id = this.state.surveyId;
    if (id) {
      this.deleteSurveyTemplate(id);
    }
  }

  onEditSurvey = () => {
    const id = this.state.surveyId;
    if (id) {
      this.editSurveyTemplate({
          id: id,
          name: this.state.surveyName,
          description: this.state.surveyDescription,
      })
    }
  };

  onEditClick = (item: any) => {
    if (item.id) {
      this.setState({
        visible: true,
        surveyId: item.id,
        surveyName: item.name,
        surveyDescription: item.description
      });

    }
  }

  handleCloseConfirmationDialog = () => {
    this.setState({ visibleDeleteConfirmationDialog: false });
  }
}

// Customizable Area End
