import React, { FunctionComponent, CSSProperties } from 'react';
import {
  Typography,
  withStyles,
  Tooltip,
  IconButton
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  CheckboxSVG,
  HeaderSVG,
  McqSVG,
  MenuSVG,
  ShortAnswerSVG,
  SliderSVG,
  RankingSVG,
  DateSVG,
  QuestionSVG
} from "./Checkbox";

interface Item {
    id: number;
    question_type: string;
    question_title: string;
    icon: any;
    answer?: string;
    required?: boolean;
    options?: any[];
    min_number?: number | null;
    max_number?: number | null;
    higher_label?: string;
    lower_label?: string;
}

interface SelectedItem {
    id: number | null,
    question_type?: string,
    question_title?: string,
    options?: any
    answer?: any
    required?: boolean
    minumum?: number
    maximum?: number
    ind?: number
}

interface Props {
  activeItem: number | null | undefined;
  selectedItem: SelectedItem | undefined;
  onItemHover: (data: any) => void;
  onMouseLeave: () => void;
  onItemClick: (data: any) => void;
  onDragStart: (item: string, itemType: string, boxIndex: number, itemIndex: number) => void;
  onDragEnter: (e: React.DragEvent<HTMLDivElement>, ind: number) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onHeaderClick: () => void;  
  onStandaloneClick: () => void;  
  isIndividualSection: boolean; 
  isPastorLiveSurvey:boolean;

}

type RenderItemsFunction = () => Item[][];
const SurveyQuestionSidebar: FunctionComponent<Props> = ({ activeItem, selectedItem, onItemHover, onMouseLeave, onItemClick, onDragStart, onDragEnter, onDragEnd ,onHeaderClick,onStandaloneClick,isIndividualSection,isPastorLiveSurvey}) => {

  const titles: any = {
    'header': 'Header section',
    'short-text': 'Text Box',
    'long-text': 'Comment Box',
    'radio': 'Radio',
    'checkbox': 'Checkbox',
    'slider': 'Slider',
    'date': 'Date',
    'ranking': 'Ranking',
    'standalone':'Standalone section'
  };

  const tooltips: any = {
    'header': 'Help users view the header',
    'short-text': 'Help users to answer in 128 characters through short responses', // updated tool tip 
    'long-text': 'Help users to answer in 512 characters through long responses',   // updated tool tip 
    'radio': 'Help users to answer by selecting one choice out of options provided',
    'checkbox': 'Help users to answer by selecting multiple choices from options provided',
    'slider': 'Help users to score within a specific range on the scale',
    'date': 'Help users to enter dates for specific events',
    'ranking': 'Help users rank the responses in order of their choice',
    'standalone':'Add questions that will display individually to the end user'
  };

  const renderItems: RenderItemsFunction = () => {
    return [
      [
        { id: 9, question_type: 'standalone', question_title: '', required: false, options: [], icon: QuestionSVG },
        { id: 1, question_type: 'header', question_title: '', icon: HeaderSVG },
      ],
      [
        { id: 2, question_type: 'short-text', question_title: '', answer: '', required: false, icon: ShortAnswerSVG },
        { id: 3, question_type: 'long-text', question_title: '', answer: '', required: false, icon: MenuSVG },
      ],
      [
        { id: 4, question_type: 'radio', question_title: '', required: false, options: [], icon: McqSVG },
        { id: 5, question_type: 'checkbox', question_title: '', required: false, options: [], icon: CheckboxSVG },
      ],
      [
        { id: 6, question_type: 'slider', question_title: '', min_number: null, max_number: null, higher_label: '', lower_label: '', required: false, icon: SliderSVG },
        { id: 7, question_type: 'date', question_title: '', required: false, icon: DateSVG },
        { id: 8, question_type: 'ranking', question_title: '', required: false, options: [], icon: RankingSVG },
      ]
    ]
  };

    const itemsArray: Item[][] = renderItems();

  return (
    <div style={webStyle.sidebar}>
      <div style={webStyle.questionTypes}>Question Types</div>
      {itemsArray?.map((group: any, i: number) => {
        return (
          <div key={i} style={{ ...webStyle.divider, ...(i === itemsArray.length - 1 ? { borderBottom: 'none' } : {}) }}>
            {group.map((item: any, ind: number) => {
              const active = activeItem === item.id;
              const isSelected = selectedItem?.id === item.id;
              const Icon = item.icon;
              const isHeader = item.question_type === 'header';
              const isStandalone = item.question_type === 'standalone';
              const iconStyle = isStandalone && isIndividualSection ? { filter: 'blur(1px)', opacity: 0.5 } : {};
              return (
                <div
                style={{
                  ...webStyle.item,
                  cursor: isStandalone && isIndividualSection ? 'not-allowed' : 'pointer',
                  userSelect: isHeader || isStandalone ? 'none' : 'auto',
                }}
                  key={`${ind}-${i}`}
                  onMouseEnter={() => onItemHover(item.id)}
                  onMouseLeave={() => onMouseLeave()}
                  onClick={() => {
                    if (isPastorLiveSurvey) {
                      // Do nothing if the survey is live or past
                      return;
                    }
                    if (item.question_type === 'header') {
                      onHeaderClick();
                    } 
                  }}
                  onMouseDown={() => {
                    if (isPastorLiveSurvey) {
                      // Do nothing if the survey is live or past
                      return;
                    }
                    if (isStandalone && isIndividualSection) {
                      // Do nothing
                    } else if (isStandalone) {
                      onStandaloneClick();
                    } else {
                      onItemClick(item);
                    }
                  }}
                  onDragStart={(e) => onDragStart(item.question_title, item.question_type, -1, -1)}
                  onDragEnter={(e) => onDragEnter(e, ind)}
                  onDragEnd={onDragEnd}
                  draggable={!(isHeader || isStandalone)} 
                >
                  <div style={{
                    ...webStyle.imgContainer,
                    ...((active || isSelected) ?
                      { background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)' } : {}),
                      ...iconStyle
                  }}>
                    
                    <Icon
                      key={item.id}
                      selected={active || isSelected}
                      
                    />
                    
                  </div>
                  <Typography style={{
                    ...webStyle.text, ...((active || isSelected) ? {
                      fontWeight: 700,
                      color: '#0F172A'
                    } : {})
                  }}>{titles[item?.question_type]}</Typography>
                  <CustomTooltip title={tooltips[item?.question_type]} placement="top-start">
                    <IconButton style={{ position: 'absolute', top: 5, right: -5}} >
                      <InfoOutlinedIcon style={{ color: 'black' }} />
                    </IconButton>
                  </CustomTooltip>
                  <div style={webStyle.spacer} />
                  <div
                    style={{
                      ...webStyle.active,
                      opacity: active || isSelected ? 1 : 0
                    }}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #F9F9F9',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    fontSize: '16px',
    borderRadius: '4px',
    padding: '8px 12px',
    maxWidth: '200px',
    whiteSpace: 'normal',
  },

})(Tooltip);


const webStyle: { [key: string]: CSSProperties } = {
  sidebar: {
    position: 'sticky',
    top: '85px',
    padding: '16px 16px 0px',
    paddingBottom:'0px',
    background: '#FFF',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    borderRadius: '12px',
    width: '360px',
    height: 'calc(90vh - 85px)',
    overflowY: 'auto',
    display:'inlineblock',
    whiteSpace:'nowrap',
  },
  divider: {
    borderBottom: '1px solid #DEDEDE',
    marginBottom: '8px',
    paddingBottom: '20px',
    paddingTop: '20px'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '8px',
    position: 'relative',
  },
  imgContainer: {
    width: '53px',
    height: '53px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    background: 'rgba(191, 202, 228, 0.30)',
  },
  text: {
    flex: 1,
    fontSize: '15px',
    fontWeight: 600,
    color: '#797979',
    whiteSpace:'nowrap',
  },
  spacer: {
    flex: 1,
  },
  active: {
    width: '4px',
    height: '56px',
    borderRadius: '5px 0px 0px 5px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
  },
  questionTypes: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 200,
    lineHeight: 'normal',
    color: 'black',
    fontFamily: 'aeonik-bold',
    marginLeft: '8px',
    marginBottom: '35px'
  },
};

export default SurveyQuestionSidebar;
