// Customizable Area Start
import React, { Component } from 'react';
import {
  Box, Grid, FormLabel, FormControlLabel, Select, Checkbox, MenuItem, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ErrorIcon } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import CommanInputCode from './CommanInputCode';
import SignUpSuccessDialog from '../../../components/src/SignUpSuccessDialog.web';
const configJSON = require("./config");

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  industry_sector: "",
  terms_of_service: false,
  company: ""
};

const passwordRegex = /^(?!^\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s$).{10,}$/;
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Error : Missing Required Field(s)"),
  last_name: Yup.string().required("Error : Missing Required Field(s)"),
  company: Yup.string().required("Error : Missing Required Field(s)"),
  term_of_service: Yup.boolean().oneOf([true], "Error : Please accept the Privacy Policy and Terms of Use."),
  email: Yup.string().email("Invalid email address").required("Error : Missing Required Field(s)"),
  password: Yup.string().required("Error : Missing Required Field(s)").matches(passwordRegex, 'Error: Passwords must contain 10 characters, 1 special character and one number.'),
  confirm_password: Yup.string().required("Error: Missing Required Field(s)").test('passwords-match', 'Passwords do not match', function (value) {
    return this.parent.password === value;
  }),
  terms_of_service: Yup.boolean().oneOf([true], "Please accept the Privacy Policy and Terms of Use."),
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    flex: '1 1 0px',
    height: 'calc(100% - 160px)',
    padding: '32px 52px'
  },
  termsWrapper:{
    display:'flex',
    '@media (max-width:537px)':{
      alignItems:'flex-start',
    },
  },
  FormLabelStyle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#333333',
    marginBottom: '12px',
    display: "block",
    fontFamily: 'aeonik-bold',
  },
  PasswordTooltip: {
    display: "flex",
    alignItems: "center",
  },
  CheckboxSection: {
    "&.MuiTypography-body1": {
      fontSize: "14px",
    }
  },
  dialogBox: {
    borderRadius: '10px',
    backgroundColor: "#FFFFFF",
    boxShadow: 'none',
    padding: "42px"
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    color: "Red"
  },
  policy: {
    color: '#202020',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily: 'aeonik-regular',
    marginLeft:'-10px',
    '@media (max-width:537px)':{
      marginTop:'8px'
    }
    // display: 'flex',
  },
  privacy: {
    fontWeight: 700,
    color: '#4c6eb0',
    cursor: 'pointer',
    fontFamily: 'aeonik-regular',
    textDecoration: 'underline',
  },
  buttonBox: {
    justifyContent: 'flex-end',
    display: 'flex',
    '@media (max-width:768px)':{
      justifyContent:'center',
    }
  },
  btn: {
    borderRadius: "10px",
    padding: "18px 24px",
    alignItems: "center",
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "white",
    display: "flex",
    fontWeight: 700,
    minWidth: '200px',
    textTransform: "unset" as const,
    cursor: 'pointer',
    fontSize: "16px",
    userSelect: 'none' as const,
    border: 'none',
    fontFamily: 'aeonik-bold',
    justifyContent: 'center',
  },
  spacer: {
    flex: '1 1 0px',
    display: 'flex',
  },
  checkboxIcon:{
    "&.MuiCheckbox-root":{
      '@media (max-width:560px)':{
        // marginTop:'-20px',
      },
      '@media (max-width:374px)':{
        // marginTop:'-45px',
      }
    }
  }
};

interface HandleSubmitProps {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  industry_sector: string;
  terms_of_service: boolean;
  company: string;
}

interface S {
  handleSignUpClick: (values: any, errors: any) => void;
  setShowPasswordConfirm: (showPasswordConfirm: boolean) => void;
  handleSignUpFieldChange: (event: any) => void;
  handleTermsOfServiceChange: (event: any) => void;
  handleContinueToVerifyEmail: () => void;
  onSignUpClick: () => void;
  signUpConfirmaionDialogVisible: boolean;
  showPasswordConfirm: boolean;
  classes: any;
  touched: any;
  signUpForm: any;
  errors: any;
  industrySectors: any[];
  handleOpenTermsAndConditionDialog: () => void;
  handleOpenPrivacyPolicyDialog: () => void;
}

// Customizable Area End
export class Signup extends Component<S, HandleSubmitProps> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, touched, handleTermsOfServiceChange, handleContinueToVerifyEmail, handleSignUpFieldChange, signUpForm: values, errors, signUpConfirmaionDialogVisible, industrySectors, onSignUpClick, handleOpenPrivacyPolicyDialog, handleOpenTermsAndConditionDialog } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={classes.container}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.props.handleSignUpClick}
        >
          {({ isSubmitting }) => (

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CommanInputCode
                  classes={classes}
                  FormLabelText="First name"
                  error={Boolean(touched) && Boolean(errors.first_name)}
                  id="first_name"
                  placeholder='Example: ABC'
                  value={values.first_name}
                  onChange={handleSignUpFieldChange}
                  type="text"
                  name="first_name"
                  errorMessage={errors.first_name}
                  maxLength={64}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CommanInputCode
                  classes={classes}
                  FormLabelText="Last name"
                  error={Boolean(touched) && Boolean(errors.last_name)}
                  id="last_name"
                  placeholder='Example: XYZ'
                  value={values.last_name}
                  onChange={handleSignUpFieldChange}
                  type="text"
                  name="last_name"
                  errorMessage={errors.last_name}
                  maxLength={64}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CommanInputCode
                  classes={classes}
                  FormLabelText="Company name"
                  error={Boolean(touched) && Boolean(errors.company)}
                  id="company"
                  placeholder='Example: Company Name'
                  value={values.company}
                  onChange={handleSignUpFieldChange}
                  type="company"
                  name="company"
                  errorMessage={errors.company}
                  maxLength={160}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <FormLabel htmlFor="outlined-basic" className={classes.FormLabelStyle}>Industry sector</FormLabel>
                  <Select
                    displayEmpty
                    error={Boolean(touched) && Boolean(errors.industry_sector)}
                    fullWidth
                    variant="outlined"
                    value={industrySectors.find((sector) => values.industry_sector === sector.id)?.name || "" }
                    onChange={handleSignUpFieldChange}
                    renderValue={(selected: any) => {
                      if (selected.length === 0) {
                        return <div style={{ color: '#a2a2a3' }}>Example: Industry Sector</div>;
                      }
                      return selected;
                    }}
                    id="industry_sector"
                    name="industry_sector"
                    label="Industry sector"
                  >
                    {industrySectors.map((sector) => (
                      <MenuItem style={{ fontFamily: "aeonik-regular" }} key={sector.id} value={sector.id}>
                        {sector.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {
                    errors.industry_sector &&
                    <div className={classes.errorMessage}>
                      <img src={ErrorIcon} alt="Logo" style={{ width: '24px', height: '28px', verticalAlign: "middle" }} />
                      <span style={{ fontSize: '16px', color: '#202020', marginLeft: '10px' }}>{errors.industry_sector}</span>
                    </div>
                  }
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CommanInputCode
                  classes={classes}
                  FormLabelText="Email address"
                  error={Boolean(touched) && Boolean(errors.email)}
                  id="email"
                  placeholder='Example: user@gmail.com'
                  value={values.email}
                  onChange={handleSignUpFieldChange}
                  type="text"
                  name="email"
                  errorMessage={errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CommanInputCode
                  classes={classes}
                  FormLabelText="Password"
                  error={Boolean(errors.password) && (!!values.password || touched)}
                  id="password"
                  placeholder='Example: ABC'
                  value={values.password}
                  onChange={handleSignUpFieldChange}
                  type="password"
                  name="password"
                  errorMessage={errors.password}
                  inputProps="yes"
                  tooltip={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CommanInputCode
                  classes={classes}
                  FormLabelText="Confirm password"
                  error={Boolean(errors.confirm_password) && (!!values.confirm_password || touched)}
                  id="confirm_password"
                  placeholder='Example: ABC'
                  value={values.confirm_password}
                  onChange={handleSignUpFieldChange}
                  type="password"
                  name="confirm_password"
                  errorMessage={errors.confirm_password}
                  inputProps="yes"
                  tooltip={true}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6} className={classes.CheckboxSection}>
                <Box>
                  <div className={classes.termsWrapper}>
                  <FormControlLabel id="terms_of_service" control={<Checkbox
                        name="terms_of_service"
                        id="terms_of_service_checkbox"
                        checked={values.terms_of_service}
                        onChange={handleTermsOfServiceChange}
                        color="primary"
                        required={true}
                        value={values.terms_of_service}
                        className={classes.checkboxIcon}
                      />
                    }
                    label={
                     <></>
                    }
                  />
                  <p className={classes.policy}>I hereby consent to the <span data-test-id='terms-btn' onClick={handleOpenPrivacyPolicyDialog} className={classes.privacy}>privacy policy</span>  and <span  data-test-id='privacy-btn' onClick={handleOpenTermsAndConditionDialog} className={classes.privacy}>terms of use</span>.</p>
                  </div>

                  {
                    (errors.terms_of_service && touched) &&
                    <div className={classes.errorMessage}>
                      <img src={ErrorIcon} alt="Logo" style={{ width: '24px', height: '28px', verticalAlign: "middle" }} />
                      <Typography style={{ fontSize: '16px', color: '#202020', marginLeft: '10px', fontWeight: 500 }}>{errors.terms_of_service}</Typography>
                    </div>
                  }
                </Box>
              </Grid>
            </Grid>
          )}
        </Formik>
        <SignUpSuccessDialog
          visible={signUpConfirmaionDialogVisible}
          onContinue={handleContinueToVerifyEmail}
          content={configJSON.signUpSuccessMessage}
          header={configJSON.signUpSuccessHeader}
        />
        <div className={classes.spacer} />
         <Box className={classes.buttonBox}>
            <button onClick={onSignUpClick} className={classes.btn}>
              Signup
            </button>
          </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(Signup);
// Customizable Area End
