import React, { FunctionComponent, useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import QuestionCard from './QuestionCard.web';
import AddOptionDialog from './AddOptionDialog.web';
import Input from './CustomInput.web';
import RadioOption from './RadioOption.web';
import { Box, makeStyles } from '@material-ui/core';

interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey: boolean;
}
const useStyles = makeStyles({
  mainBox: {
    fontFamily: 'aeonik-regular',
    "& ::placeholder":{
        fontFamily: 'aeonik-regular',
    },
}
});
const MCQ: FunctionComponent<Props> = ({ onChange, data, onDeleteClick, isLiveorPastSurvey }) => {
  const classes = useStyles()
  const [visible, setVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [type, setType] = useState("");
  const [selectedValue, setSelectedValue] = useState<string | null>(null); 

  const handleSaveClick = (options: any) => {
    setSelectedOptions(options);
    onChange({
      ...data,
      options
    });
    setVisible(false);
  };

  const handleDialogClose = () => {
    setVisible(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    setSelectedOptions(data?.options || []);
  }, [data?.options]);

  return (
    <Box className={classes.mainBox}>
    <QuestionCard
      isLiveorPastSurvey={isLiveorPastSurvey}
      title='Radio question'
      data={data}
      required
      onDeleteClick={() => onDeleteClick(data?.ind)}
      onRequiredCheck={(val) => onChange({
        ...data,
        required: val
      })}
    >
      <Input
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        placeholder='Add question text'
        maxLength={128}
        style={{ marginBottom: '16px' }}
        value={data?.question_title}
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event?.target.value,
          })
        }
      />
      {selectedOptions?.map((option: any, ind: number) => (
        <RadioOption
          key={ind}
          name="surveyOptions"
          option={option}
          disabled={isLiveorPastSurvey}
          checked={selectedValue === option}
          onChange={handleChange}
        />
      ))}
      {selectedOptions?.length < 2 && (
        <div>
          <p style={{ color: '#f54f5f', fontFamily: 'aeonik-regular', marginTop: '0px' }}>
            Please select at least 2 options
          </p>
        </div>
      )}
      <button
        disabled={isLiveorPastSurvey}
        onClick={() => setVisible(true)}
        style={{
          display: 'flex',
          padding: '6px 32px',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #1C386D',
          borderRadius: '10px',
          maxWidth: 'fit-content',
          background: 'none',
          fontFamily: 'aeonik-regular',
          cursor: 'pointer',
          borderColor: isLiveorPastSurvey ? 'grey' : '#375080',
        }}
      >
        <AddIcon htmlColor={isLiveorPastSurvey ? 'grey' : '#375080'} />
        Add option
      </button>
      <AddOptionDialog savedOptions={data?.options} visible={visible} onClose={handleDialogClose} onSave={handleSaveClick} type={type} />
    </QuestionCard>
    </Box>
  )
};

export default MCQ;