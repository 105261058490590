Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Invalid email address";
exports.errorInvalidPasswordMessage = "Incorrect password. Please enter valid password";
exports.errorPasswordNotValid = "Password must contain 10 characters, 1 special character, 1 uppercase letter, 1 lowercase letter and one number";
exports.errorFieldRequired = "Error : Missing required field(s)";
exports.errorPrivacyPolicy = "Error : Please accept the privacy policy and terms of use";
exports.errorPasswordNotMatch = "Error : Passwords do not match";
exports.signUpSuccessMessage = "Your account has been created but need to be verified over email. Check your email to verify your account.";
exports.signUpSuccessHeader = "Congratulations!";
exports.verifyAccountHeader = "Success!";
exports.verifyAccounBodyMessage = "Your email has been verified. Please login with the password you just created to complete your signup.!";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.token = "token";

const urlConfig = require("../../../framework/src/config");
exports.APIBaseURL = urlConfig.baseURL;
// admin routes
exports.loginAdminUserEndpoint = "bx_block_user/users/admin_login";
exports.signUpAdminUserEndpoint = "bx_block_user/users/admin_signup";
exports.verifyAdminUserEndpoint = "bx_block_user/users/confirm";
exports.forgotPasswordEndpoint = "bx_block_user/users/forgot_password";
exports.resetPasswordEndpoint = "bx_block_user/users/reset_password";
exports.resendCodeEndpoint = "bx_block_user/users/resend_code";
exports.resendCodeForResetPasswordEndpoint = "bx_block_user/users/resend_code_for_reset_token";
exports.industrySectorsEndpoint = "bx_block_user/industry_sectors/default_list"
exports.getTermsConditions = "bx_block_terms_and_conditions/terms_and_conditions";
exports.getPrivacyPolicy = "bx_block_privacy_policy/privacy_policies";

// user routes
exports.loginUserEndpoint = "bx_block_user/users/user_login";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End