import React, { useState, FunctionComponent, useEffect } from 'react';

import {
  Box,
  Checkbox,
  Typography,
  makeStyles,
} from "@material-ui/core";

import QuestionCard from './QuestionCard.web';
import AddOptionDialog from './AddOptionDialog.web';
import Input from './CustomInput.web';
import AddIcon from '@material-ui/icons/Add';
interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey:boolean;
}
const useStyles = makeStyles({
  checkbox: {
    marginRight: '6px',
    width: '24px',
    height: '24px',
    padding: '0px',
    '&.Mui-checked': {
      color: '#3f51b5',
    },
  },
  mainBox: {
    fontFamily: 'aeonik-regular',
    "& ::placeholder":{
        fontFamily: 'aeonik-regular',
    },
}
});
const plusSvg = require('./plus.svg');

const CheckboxQuestion: FunctionComponent<Props> = ({ onChange, onDeleteClick, data,isLiveorPastSurvey }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [type, setType] = useState("");

  const handleSaveClick = (options: any) => {
    setSelectedOptions(options);
    onChange({
      ...data,
      options
    });
    setVisible(false);
  }

  const handleDialogClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setSelectedOptions(data?.options || []);
  }, [data?.options]);

  return (
    <Box className={classes.mainBox}>
    <QuestionCard
      isLiveorPastSurvey={isLiveorPastSurvey}
      title='Checkbox question'
      data={data}
      required
      onDeleteClick={() => onDeleteClick(data?.ind)}
      onRequiredCheck={(val) => onChange({
        ...data,
        required: val
      })}
    >
      <Input
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        maxLength={128}
        placeholder='Add question text'
        style={styles.input}
        value={data?.question_title}
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event.target.value,
          })
        }
      />
      {
        selectedOptions?.map((option: any, ind: number) => (
          <div key={ind} style={styles.checkContainer}>
            <Checkbox disabled={isLiveorPastSurvey} className={classes.checkbox} />
            <Typography style={styles.opt1}>{option}</Typography>
          </div>
        ))
      }
      {
        selectedOptions?.length < 2 && (
          <div>
            <p style={styles.warning}>Please select at least 2 options</p>
          </div>
        )
      }
      <button
        disabled={isLiveorPastSurvey}
        onClick={() => setVisible(true)}
        style={{
          ...styles.btn,
          borderColor: isLiveorPastSurvey ? 'grey' : '#375080',
        }}
      >
        <AddIcon htmlColor={isLiveorPastSurvey ? 'grey' : '#375080'} />
        Add option
      </button>
      <AddOptionDialog savedOptions={data?.options} visible={visible} onClose={handleDialogClose} onSave={handleSaveClick} type={type} />
    </QuestionCard>
    </Box>
  )
};

const styles = {
  input: {
    marginBottom: '16px'
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: '21px',
  },
  opt1: {
    color: '#202020',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    wordWrap: 'break-word' as const,
  },
  btn: {
    display: 'flex',
    padding: '6px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #1C386D',
    borderRadius: '10px',
    maxWidth: 'fit-content',
    background: 'none',
    fontFamily: 'aeonik-regular',
    cursor: 'pointer'
  },
  warning: {
    marginTop: '0px',
    color: '#f54f5f',
    fontFamily: 'aeonik-regular'
  },
  plusIcon: {
    marginRight: '10px'
  }
}

export default CheckboxQuestion;
