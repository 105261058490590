import * as React from 'react';
import { styled } from "@material-ui/core/styles"

import {
  Dialog,
} from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-rounded': {
    borderRadius: '16px',
    overflowX: 'hidden'
  }
}));

interface CustomizedDialogsProps {
  visible: boolean;
  onClose?: () => void;
  children: React.ReactNode
  style?: any
}

export default function Modal({ visible = true, onClose, children, style = '' }: CustomizedDialogsProps) {
  return (
    <div>
      <BootstrapDialog
        style={style}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={visible}
      >
        {children}
      </BootstrapDialog>
    </div>
  );
}