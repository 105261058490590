import React, { FunctionComponent, ReactNode, useState } from 'react';

const dragIcon = require('./drag_line.png');
const deleteIcon = require('./delete.svg');
const copyIcon = require('./copy.svg');

import {
    Typography,
    Switch,
    Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';


interface Props {
    children: ReactNode;
    title?: string;
    required?: boolean;
    onRequiredCheck?: (val: any) => void;
    onDeleteClick?: (val: any) => void;
    data?: any
    isLiveorPastSurvey:boolean,
}

interface S {
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'warning' | 'info' | undefined;
}

const QuestionCard: FunctionComponent<Props> = ({ title, children, onRequiredCheck, onDeleteClick, data, required,isLiveorPastSurvey }) => {
    const [snackbar, setSnackbar] = useState<S>({ open: false, message: 'info', severity: 'success' });

    const handleRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onRequiredCheck) {
            onRequiredCheck(event.target.checked);
        }
    };

    const handleCopyToClipBoard = () => {
        navigator.clipboard.writeText((data?.question_title));
        setSnackbar({ open: true, message: 'Question copied to clipboard', severity: 'success' });
    };
    
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div style={styles.container}>
            <div style={styles.imgContainer}>
                <img style={styles.img} src={dragIcon} alt="drag" />
            </div>
            <Typography style={styles.title}>
                {title}
            </Typography>
            {children}
            <div style={styles.action}>
                <button disabled={isLiveorPastSurvey} onClick={onDeleteClick} style={styles.btn}><img src={deleteIcon} alt="delete" /></button>
                <button 
                disabled={isLiveorPastSurvey}   // added the disable button if the survey is not in draft mode. 
                onClick={handleCopyToClipBoard} style={styles.btn}><img src={copyIcon} alt="copy" /></button>
                {
                    required && (
                        <div style={styles.required}>
                            <div style={styles.spacer} />
                            <Typography style={styles.requireText}>Required</Typography>
                            <Switch  color="primary" disabled={isLiveorPastSurvey} checked={data?.required} onChange={handleRequiredChange} />
                        </div>
                    )
                }
            </div>
            <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={3000}
                open={snackbar.open}
                onClose={handleCloseSnackbar}
            >
                <Alert severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as const,
        borderRadius: '0px 8px 8px',
        border: '1px solid #E7E7E7',
        padding: '4px 24px 24px 24px',
        marginTop: '30px',
        backgroundColor:'white',
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    img: {
        width: '36px',
        height: '36px'
    },
    title: {
        color: '#333',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '12px',
        fontFamily: 'aeonik-bold'
    },
    action: {
        marginTop: '18px',
        display: 'flex'
    },
    btn: {
        border: 'none',
        background: 'none',
        padding: '0px',
        cursor: 'pointer',
        marginRight: '18px',
    },
    required: {
        display: 'flex',
        alignItems: 'center',
    },
    spacer: {
        background: '#E0E0E0',
        width: '1px',
        height: '100%'
    },
    requireText: {
        color: '#333',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        margin: '0px 16px',
        fontFamily: 'aeonik-bold'
    }
};

export default QuestionCard;