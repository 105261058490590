import React from "react";

// Customizable Area Start

import {
    Box,
    Grid,
    Typography,
    Snackbar,
    Button
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import CommanButton from "./CommanButton";
import CommanInputCode from './CommanInputCode';

const initialValues = {
    email: "",
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Error: Please enter your email address."),
});

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.main}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {({ values, isSubmitting, errors, setFieldValue }) => (
                        <>
                            <Box className={classes.mainBox}>
                                <Box className={classes.cardHeader}>
                                    <Typography variant="h4" align="center" gutterBottom className={classes.cardTitle}>
                                        Forgot password
                                    </Typography>
                                </Box>
                                <Box className={classes.cardBody}>
                                    <Grid container className={classes.loginFormWrap}>
                                        <form>
                                            <Grid item xs={12} md={9}>
                                                <CommanInputCode
                                                    classes={classes}
                                                    FormLabelText="Email address"
                                                    error={!!errors.email}
                                                    id="email"
                                                    placeholder='Example: user@gmail.com'
                                                    value={values.email}
                                                    onChange={(events: any) => {
                                                        const emailRegex = /^[a-zA-Z0-9.@+-_~]*$/;
                                                        if (emailRegex.test(events.target.value)) {
                                                            setFieldValue("email", events.target.value)}
                                                        }
                                                    }
                                                    type="text"
                                                    name="email"
                                                    errorMessage={errors.email}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Typography className={classes.resendLink}>Please enter your email address. You’ll receive a code to create a new password via email.</Typography>

                                            </Grid>
                                        </form>
                                    </Grid>
                                </Box>
                                <Button onClick={() => this.handleSubmit(values)} type="submit" id="forgotPasswordBtn" variant="contained" disabled={false} color="primary" className={classes.submitBtn}>
                                    Submit
                                </Button>
                            </Box>
                        </>)}
                </Formik>
                <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3100}
                    open={this.state.snackbar.open}
                    onClose={this.handleCloseSnackbar}
                >
                    <Alert severity={this.state.snackbar.severity}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    main: {
        display: "flex",
        flex: '1 1 0px',
        flexDirection: "column" as const,
    },
    cardHeader: {
        borderBottom: '1px solid #E7E7E7',
        padding: '24px 40px',
    },
    loginFormWrap: {
        padding: '40px'
    },
    inputGroup: {
        marginBottom: "24px"
    },
    cstInput: {
        borderRadius: "20px"
    },
    FormLabelStyle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#333333',
        marginBottom: '12px',
        display: "block",
    },
    cardBody: {
        display: 'flex',
        flex: '1 1 0px'
    },
    mainBox: {
        border: '1px solid #ddd',
        margin: '50px 50px 40px 50px',
        boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        borderRadius: '20px',
        background: "#FFFFFF",
        minHeight: "65%",
        display: "flex",
        flexDirection: "column" as const,
        flex: '1 1 0px',
        '@media (max-width:500px)':{
            margin:'40px 25px',
        }
    },
    cardTitle: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: ' 23px',
        textAlign: 'left',
        marginBottom: 0,
        fontFamily: 'aeonik-bold',
    },
    resendLink: {
        fontFamily: 'aeonik-regular',
        fontStyle: "normal",
        fontSize: "12px !important",
        fontWeight: 600,
        color: 'var(--text, #202020)'

    },
    buttonBoxContinue: {
        display: 'flex', justifyContent: 'flex-end',
        padding: '0 40px 40px',
        "&.MuiButton-root": {
            padding: "12px 70px"
        }
    },
    submitBtn: {
        alignSelf: 'end',
        padding: "18px 24px",
        borderRadius: "10px",
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        alignItems: "center",
        display: "flex",
        margin: '0px 40px',
        marginBottom: '30px',
        minWidth: '200px',
        color: "white",
        right: "12px",
        fontSize: "16px",
        justifyContent: 'center',
        fontWeight: 700,
        border: 'none',
        textTransform: "unset !important",
        cursor: 'pointer',
        userSelect: 'none',
        fontFamily: 'aeonik-bold',
        '@media (max-width:500px)':{
            right:'0px',
            margin:'auto',
            marginBottom:'60px',
            scale:'0.9',
        }
    }
};
// Customizable Area End

//@ts-ignore
export default withStyles(styles)(ForgotPassword);