import React, { FunctionComponent, ReactNode } from "react";
import {
  Typography,
} from "@material-ui/core";

interface Props {
    children: ReactNode;
    title?: string;
}
const Card: FunctionComponent<Props> = ({ title, children }) => {
    return (
        <div style={styles.card}>
            <Typography style={styles.header}>{title}</Typography>
            <div style={styles.body}>
                {children}
            </div>
        </div>
    );
}

export default Card;

const styles = {
    card: {
      margin: '46px 40px 40px 40px',
      background: "#fff",
      flex: 1,
      // height: 'calc(100% - 162px)',
      borderRadius: '12px',
      // overflow: 'auto'
    },
    header: {
      padding: '20px 20px 30px 30px',
      color: 'var(--text, #202020)',
      fontSize: '24px',
      fontStyle: 'normal',
      fontFamily: 'aeonik-bold',
      fontWeight: 700,
      lineHeight: 'normal',
      borderBottom: '1px solid #F3F3F3'
    },
    body: {
      padding: '36px 30px 30px 30px'
    }
  };

