import { Route, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import "react-router-modal/css/react-router-modal.css";

function Wrapper({ element, history, match, routeMap, closeModal, isLandPage = false }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div style={{ marginTop: isLandPage ? "0px" : "72px", display: "flex", flex: "1" }}>
      {React.cloneElement(element, {
        navigation: { navigate, getParam, goBack },
        closeModal,
      })}
    </div>
  );
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMapAdmin: PropTypes.object,
  routeMapUser: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
  isLandPage: PropTypes.bool
};

const WebRoutesGenerator = ({ routeMapAdmin, routeMapUser, commonRoutes }) => {
  // separeate routes as admin routes and user routes

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (token && token !== "undefined" && token !== "null") {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const authPages = [
    '', // landing page
    'AuthBlock', 
    'ForgotPassword', 
    'ResetPassword',
    'SignInUserBlock',
    'AdminAuth'
  ];

  const page = window?.location?.pathname?.split("/")[1]
  const isAuthPage = authPages.includes(page);

  if (!isAuthPage && !isAuthenticated()) {
    return (
      <Route
        render={props => (
          <Redirect
            to={{
              pathname: "/AuthBlock",
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  }

  let user = localStorage.getItem("user");
  user = (user && user !== "undefined") ? JSON.parse(user) : null;
  
  let routeMap = { ...routeMapAdmin };
  if (user && user !== "undefined") {
    if (user?.type === "user") {
      routeMap = routeMapUser;
    } else {
      routeMap = routeMapAdmin;
    }
  }

  routeMap = { ...routeMap, ...commonRoutes };

  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    const isLandPage = currentRoute.path === "/"
    

    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap}/>
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}   
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} isLandPage={isLandPage}/>
          )}
        />
      );
    }  
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
