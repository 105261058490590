Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMultiPartFormData = 'multipart/form-data';
exports.validationApiGetMethodType = "GET"; 
exports.validationApiPostMethodType = "POST"; 
exports.validationApiPutMethodType = "PUT"; 
exports.getSurveyQuestionsApiEndPoint="/bx_block_surveys/surveys"
exports.postSubmitSurveyResponseApiEndPoint="/bx_block_surveys/submissions"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
