import React from "react";

// Customizable Area Start

import {
    Box,
    Grid,
    Typography,
    Snackbar,
    Link,
    Button
} from "@material-ui/core";

import Alert from '@material-ui/lab/Alert';
import { Formik } from "formik";
import * as Yup from "yup";

import { withStyles } from "@material-ui/core/styles";

import CommanButton from "./CommanButton";
import CommanInputCode from './CommanInputCode';

const initialValues = {
    code: "",
    password: "",
    confirm_password: "",
};

const passwordRegex = /^(?!^\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s$).{10,}$/;
const validationSchema = Yup.object().shape({
    password: Yup.string().required("Error : Missing required field(s)").matches(passwordRegex, 'Error: Password must contain 10 characters, 1 special character, 1 uppercase letter, 1 lowercase letter and one number'),
    code: Yup.string().required("Error : Missing required field(s)"),
    confirm_password: Yup.string().required("Error: Missing required field(s)").test('passwords-match', 'Passwords do not match', function (value) {
        return this.parent.password === value;
    }),
});

// Customizable Area End

import ResetPasswordController, { Props } from "./ResetPasswordController";

export class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { touched } = this.state;
        // Customizable Area End
        //@ts-ignore
        return (
            // Customizable Area Start
            <div className={classes.container}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {({ values, isSubmitting, errors, setFieldValue }) => (
                        <>
                            <Box className={classes.mainBox}>
                                <Box className={classes.cardHeader}>
                                    <Typography variant="h4" align="center" gutterBottom className={classes.cardTitle}>
                                        Reset password
                                    </Typography>
                                </Box>
                                <Box className={classes.loginFormWrap}>
                                    <Grid className={classes.gridcontainer} item xs={12} md={6}>
                                        <CommanInputCode
                                            classes={classes}
                                            FormLabelText="Code"
                                            error={!!errors.code && !values.code}
                                            placeholder='Example: 12345'
                                            value={values.code}
                                            onChange={(events: any) => setFieldValue("code", events.target.value)}
                                            type="number"
                                            id="code"
                                            name="code"
                                            errorMessage={errors.code}
                                        />
                                        <div>
                                            <Typography className={classes.resendCodeSection}>
                                                <span className={classes.resendCodeSection} onClick={this.handleResendCodeClick} style={{ cursor: 'pointer' ,color:'#3f51b5'}}>
                                                    Resend code
                                                </span>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid className={classes.gridcontainer} item xs={12} md={6}>
                                        <CommanInputCode
                                            classes={classes}
                                            FormLabelText="New password"
                                            error={Boolean(errors.password) && !!values.password}
                                            id="password"
                                            placeholder='Example: ABC'
                                            value={values.password}
                                            onChange={(events: any) => setFieldValue("password", events.target.value)}
                                            type="password"
                                            name="password"
                                            errorMessage={errors.password}
                                            inputProps="yes"
                                            tooltip={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommanInputCode
                                            classes={classes}
                                            FormLabelText="Confirm new password"
                                            error={!!errors.confirm_password && !!values.confirm_password}
                                            id="confirm_password"
                                            placeholder='Example: ABC'
                                            value={values.confirm_password}
                                            onChange={(events: any) => setFieldValue("confirm_password", events.target.value)}
                                            type="password"
                                            name="confirm_password"
                                            errorMessage={errors.confirm_password}
                                            inputProps="yes"
                                            tooltip={true}
                                        />
                                    </Grid>
                                </Box >
                                <Button className={classes.button} onClick={() => this.handleSubmit(values, errors)} id="resetPasswordBtn" >
                                    Reset password
                                </Button>
                            </Box>
                        </>
                    )}
                </Formik>
                <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    open={this.state.snackbar.open}
                    onClose={this.handleCloseSnackbar}
                >
                    <Alert severity={this.state.snackbar.severity}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    button: {
        borderRadius: "10px",
        display: "flex",
        padding: "18px 24px",
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        alignItems: "center",
        color: "white",
        minWidth: '200px',
        textTransform: "unset !important",
        justifyContent: 'center',
        userSelect: 'none',
        fontSize: "16px",
        cursor: 'pointer',
        border: 'none',
        fontWeight: 700,
        maxWidth: '200px',
        alignSelf: 'end',
        margin: '20px 50px',
        fontFamily: 'aeonik-bold',
        '@media (max-width:768px)':{
            margin:'auto',
            marginBottom:'20px',
            scale:'0.8'
        }
    },
    gridcontainer:{
        '@media (max-width:768px)':{
            flexBasis:'auto',
        }
    },
    container: {
        display: "flex",
        flexDirection: "column" as const,
        flex: '1 1 0px',
        '@media (max-width:768px)':{
            width:'100%',
        }
    },
    
    cardHeader: {
        borderBottom: '1px solid #E7E7E7',
        padding: '40px',
        '@media (max-width:768px)':{
            padding:"20px",
        }
    },
    mainBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        flex: '1 1 0px',
        border: '1px solid #ddd',
        margin: '50px',
        boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        borderRadius: '20px',
        background: "#FFFFFF",
        minHeigth: "70%",
        '@media (max-width:768px)':{
            margin:'25px 20px', 
            borderRadius:'10px',
        }
    },
    cardTitle: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: ' 23px',
        textAlign: 'left',
        marginBottom: 0,
        fontFamily: 'aeonik-bold',
        '@media (max-width:768px)':{
            textAlign:'center',
        }
    },
    loginFormWrap: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0px',
        '@media (max-width:768px)':{
            padding:"20px",
        }
    },

    cstInput: {
        borderRadius: "20px"
    },
    resendLink: {
        fontFamily: 'Raleway',
        fontStyle: "normal",
        fontSize: "12px !important",

    },
    buttonBoxContinue: {
        display: 'flex', justifyContent: 'flex-end',
        padding: '0 40px 40px',
        "&.MuiButton-root": {
            padding: "12px 70px"
        }
    },
    inputGroup: {
        marginBottom: "24px"
    },
    resendCodeSection: {
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "14px",
        color: "linear - gradient(180deg, #1C386D - 0.91 %, #BFCAE4 279.09 %)",
        justifyContent: 'flex-end',
        display: 'flex',
        fontFamily: 'aeonik-bold',
    },
    FormLabelStyle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#333333',
        marginBottom: '12px',
        display: "block",
    },

};
// Customizable Area End

//@ts-ignore
export default withStyles(styles)(ResetPassword);