import React from "react";

import {
  Box,
  Typography,
  Tabs,
  Tab,
  // Customizable Area Start
  Snackbar,
  CircularProgress,
  styled,
  // Customizable Area End
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
// Customizable Area Start
import AnalyticsController, { Props } from "./AnalyticsController.web";
import { sureyIcon, ColoredSureyIcon } from "./assets";
import SurveyOverviewWeb from "../../../components/src/SurveyOverview.web"
import QuestionAnalysisWeb from "../../../components/src/QuestionAnalysis.web";  
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Alert from "@material-ui/lab/Alert";
import Print  from "../../../components/src/Print.web"
// Customizable Area End

export class AnalyticsWeb extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      surveyList,
      surveyTabValue,
      questionTabValue,
      questionData,
      surveyDetails,
      isFetchingSurveyDetails,
      isFetchingSurveyList,
      snackbar,
    } = this.state;
    const { classes } = this.props;
    const dataPresent =
      questionTabValue === 0 ? (
         <SurveyOverviewWeb overviewData={surveyDetails?.overview} 
         surveyNumber={surveyTabValue+1}
         />      
      ) : (
        <QuestionAnalysisWeb
          key={questionTabValue}
          surveyId={surveyList[surveyTabValue]?.id}
          questionData={questionData[questionTabValue - 1]} 
          questionIndex={questionTabValue}
          header={this.getHeaderForQuestion(questionTabValue)}
        />
      );

    const notLoading = surveyDetails?.overview ? (
      dataPresent
    ) : (
      <Box style={styles.spinnerContainer}>No users filled this survey yet.      
        </Box>
    );
    // Customizable Area End

    function a11yProps(index: number) {
      // Customizable Area Start
      return {
        id: `simple-survey-tab-${index}`,
        "aria-controls": `simple-survey-tabpanel-${index}`,
      };
      // Customizable Area End
    }

    return (
      // Customizable Area Start
      <div className={classes.maincontainer}>
        <Box style={{height:'100vh',overflow:"hidden"}} className={classes.container}>
          <Box className={classes.header}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <RouterLink to="/DashboardWeb" style={{ marginRight: "6px" }}>
                <BlackChevronLeftIcon fontSize="medium" />
              </RouterLink>
              <Typography style={{ fontFamily: 'aeonik-bold' }} variant="h6">Dashboard</Typography>
            </Box>
            <Print
                Data={this.state.surveyDetails.sections_list}
                questionData={this.state.questionData}
                surveyNumber={this.state.surveyTabValue + 1}
                overviewData={this.state.surveyDetails?.overview}
              />
          </Box>
          <Box className={classes.mainBox}>
            {isFetchingSurveyList ? (
              <Box style={styles.spinnerContainer}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <Box className={classes.surveyTabsContainer}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={surveyTabValue}
                    scrollButtons="off"
                    data-test-id="surveyTabs"
                    onChange={this.handleChangeSurveyTab}
                    aria-label="survey tab"
                    TabIndicatorProps={{
                      children: (
                        <span
                          style={{
                            background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                            height: "50px",
                            width: "4px",
                            borderRadius: "5px 0 0 5px",
                          }}
                        />
                      ),
                      style: {
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        width: "4px",
                      },
                    }}
                    style={{
                      width: "250px",
                      height: "100%",
                    }}
                  >
                    {surveyList.map((unitSurvey: any, ind: number) => (
                      <Tab
                        key={`${unitSurvey?.id}-${ind}`}
                        className={classes.tabSurvey}
                        style={surveyList.length -1 === ind? { borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow:'2px' } : {}}
                        label={
                          <Box className={classes.tabBox}>
                            <div className={classes.imgContainer}>
                              <img
                                className={classes.img}
                                src={surveyTabValue === ind ? sureyIcon : ColoredSureyIcon}
                                alt="header"
                              />
                            </div>
                            <Typography className={classes.surveyNameText}>
                              {unitSurvey?.attributes?.name}
                            </Typography>
                          </Box>
                        }
                        {...a11yProps(ind)}
                      />
                    ))}
                  </Tabs>
                </Box>
                <Box className={classes.surveyDetailsContainer}>
                <div
                  onScroll={this.handleScroll}
                  style={{
                    maxHeight: '90%',
                    overflowY: 'auto', 
                    overflowX: 'hidden', 
                    borderRadius: '0px 0px 5px 5px',
                    width: '180px',
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    scrollbarWidth: 'none'
                  }}
                >
                        <style>
                            {`
                              div::-webkit-scrollbar {
                                display: none;
                              }
                            `}
                          </style>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={questionTabValue}
                      scrollButtons="off"
                      data-test-id="questionTabs"
                      onChange={this.handleChangeQuestionTab}
                      aria-label="questions tab"
                      TabIndicatorProps={{
                        children: (
                          <span
                            style={{
                              background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                              height: "50px",
                              width: "4px",
                              borderRadius: "5px 0 0 5px",
                            }}
                          />
                        ),
                        style: {
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "transparent",
                          width: "4px",
                        },
                      }}
                      style={{ 
                      width: "145px", 
                      maxHeight:'90%',
                      borderRadius:'0px 0px 20px 20px',
                      boxShadow: " 5px 4px 10px rgba(0, 0, 0, 0.06), 10px 8px 20px rgba(0, 0, 0, 0.04), 15px 12px 30px rgba(0, 0, 0, 0.02)" , 
                      borderBottomLeftRadius: '20px',
                      borderBottomRightRadius: '20px',
                    }}
                    >
                          
                      <Tab
                        className={classes.tabQuestion}
                        label="Overview"
                        {...a11yProps(0)}
                        data-test-id="survey-overview"
                      />
                      {questionData.map((question: any, ind: number) => ( 
                        <Tab
                          data-test-id={`question-tab-${ind}`}
                          key={`${question}-${ind}`}
                          className={classes.tabQuestion}
                          style={
                            questionData.length -1 === ind? { 
                            borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px',boxShadow:'2px' } : {}
                          }
                          label={`Question ${ind + 1}`}
                          {...a11yProps(ind + 1)}
                        />
                      ))}
                    </Tabs>
                  </div>
                  <Box
                    mb={2}
                    style={{
                      width: "100%",                    
                      height:"100%",
                    }}
                  >
         
                    {isFetchingSurveyDetails ? (
                      <Box style={styles.spinnerContainer}>
                        <CircularProgress color="primary" />
                        <> ...Loading</>
                      </Box>
                    ) : (
                      <>                       
                          {notLoading}
                      </>
                      
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          autoHideDuration={3000}
          open={snackbar.open}
          onClose={this.handleCloseSnackbar}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BlackChevronLeftIcon = styled(ChevronLeftIcon)({
  color: 'black',
  '&:hover': {
    color: 'black',
  },
});
const styles = {
  maincontainer: {
    flex: 1,
    padding:'5px',
    paddingBottom:'10px',
    paddingright:'5px',
    // overflow:"auto",
    height:'98%',
  },
  container: {
    border: "1px solid #ddd",
    margin: "40px",
    marginBottom: "6px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    flex: 1,
    "@media (max-width: 500px)": {
      margin: "20px",
    },
    Height:"100vh",
    overflow:"auto"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #dedede",
    padding: "17px 20px",
  },
  exportButton: {
    borderRadius: "8px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#304a7c",
    fontWeight: 600,
    padding: "6px 10px",
    textDecoration: "none",
  },
  mainBox: {
    display: "flex",
    height: "80%",
    width: "100%",
    Height:"100vh",
    overflow:"auto"
  },
  surveyTabsContainer: {
    height: "100%",
    boxShadow: "5px 4px 10px rgba(0, 0, 0, 0.06), 10px 8px 20px rgba(0, 0, 0, 0.04), 15px 12px 30px rgba(0, 0, 0, 0.02)",
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  tabSurvey: {
    "&.MuiTab-root": {
      minHeight: "104px",
      opacity: 1,
      color: "#797979",
      textTransform: "none",
      borderBottom: "1px solid #DEDEDE",
    },
    "&.MuiTab-root.Mui-selected": {
      color: "#1C386D",
    },

    "&.MuiTab-root.Mui-selected .MuiBox-root div": {
      background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    },
  },

  tabBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  surveyNameText: {
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    fontSize: "16px",
    textAlign:'start',
  },

  imgContainer: {
    background: "rgba(191, 202, 228, 0.3)",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    marginRight:"20px",
    padding:'15px'
  },
  img: {
    width: "24px",
    height: "24px",
  },
 
  tabQuestion: {
    "&.MuiTab-root": {
      minHeight: "62px",
      marginTop:"-2px",
      borderBottom: "1px solid #DEDEDE",
      padding:"20px",
      opacity: 1,
      color: "#797979",
      textTransform: "none",
      fontWeight: 600,
      fontFamily: 'aeonik-regular',
      fontSize: "16px",
      // overflow:"auto",
    },
    "&.MuiTab-root.Mui-selected": {
      color: "#1C386D",
    },
    "&.MuiTab-root .MuiTab-wrapper": {
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },

  surveyDetailsContainer: {
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    Height:'85%',
  },
  content: {
    marginTop: "30px",
    flex: 1,
  },
  mcqPercent: {
    width: "40%",
  },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "160px",
    fontSize: "42px",
    fontWeight: 800
  },
};
// Customizable Area End

// Customizable Area Start
//@ts-ignore
export default withRouter(withStyles(styles)(AnalyticsWeb));
// Customizable Area End
