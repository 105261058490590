import React, {
  FunctionComponent,
} from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Slider,
  Typography,
  styled,
} from "@material-ui/core";

interface Props {
  data: any;
  classes: any;
}
const SliderType: FunctionComponent<Props> = ({
  data,
  classes,
}) => {
  const valueLabelFormat = () => {
    return data?.avg_value ? <strong> Score: {data?.avg_value.toFixed(2)} </strong> : <strong> Score: 0 </strong>;
  };
  return (
    <div style={{ backgroundColor: '#e4e8f3', padding: '10px', borderRadius: '6px' }}>
      <SliderWrapper>
        <PrettoSlider
          value={data?.avgPercent ? Number(data?.avgPercent) : 0}
          data-test-id="slider"
          min={data.min_number}
          max={data.max_number}
          defaultValue={data.avgPercent}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
        />
      </SliderWrapper>
      <LabelWrapper >
        <div >
          <SliderLabel style={{ textAlign: 'left' }}>{data.min_label}</SliderLabel>
          <Typography style={{ textAlign: 'center', fontWeight: 600, marginTop: '5px' }}>{data.min_value}</Typography>
        </div>
        <div >
          <SliderLabel style={{ textAlign: 'right' }}>{data.max_label}</SliderLabel>
          <Typography style={{ textAlign: 'center', fontWeight: 600, marginTop: '5px' }}>{data.max_value}</Typography>
        </div>
      </LabelWrapper>
    </div>
  );
};

const SliderLabel = styled('h2')({
  fontSize: '15px',
  fontStyle: 'normal',
  marginTop: '0px',
  fontWeight: 100,
  lineHeight: 'normal',
  fontFamily: 'aeonik-regular',
  wordWrap: 'break-word',
  '@media screen and (max-width:500px)': {
    fontSize: '11.5px',
    maxWidth: '90px',
    marginRight: '11px'
  }
})

const PrettoSlider = withStyles({
  root: {
    color: '#374f81',
    height: 8,
    marginTop: '40px',
    cursor:'default',
    width: '92%', 
    '@media (max-width: 1440px)': {
      width:'90%',
     }, 
  },
  thumb: {
    height: 24,
    width: 24,
    cursor:"default",
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: '-30px',
    '& *': {
      background: 'white',
      color: '#000',
      transform: 'none',
      width: '80px',
      borderRadius: '4px',
      textAlign: 'center'
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
    cursor:"default",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: 'white',
    opacity: '1',
    cursor:"default",
  },
  
})(Slider);

const SliderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 0px 10px 0px',
  backgroundColor: '#e4e8f3'
})

const LabelWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '-10px',
  marginLeft: '4%',
  marginRight: '4%',
  width: '92%',
  '@media (max-width: 1440px)': {
    width:'90%',
    marginLeft: '5%',
    marginRight: '5%', 
   },
})

const styles = {

  title: {
    color: '#333',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '16px',
    fontFamily: 'aeonik-regular',
    wordWrap: 'break-word' as const,
  },
};

export default withStyles(styles)(SliderType);
