import React from 'react';

interface CustomCheckboxProps {
  id: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ id, checked, onChange }) => {
  const checkboxStyle = {
    borderRadius: '2px',
    width: checked ? '21px' : '19px',
    height: checked ? '21px' : '19px',
    border: checked ? 'none' : '1px solid grey',
    display: 'inline-block' as const,
    position: 'relative' as const,
    cursor: 'pointer' as const,
    background: checked ? 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)' : 'transparent',
  };

  const checkmarkStyle = {
    content: '""',
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    width: '6px',
    height: '12px',
    border: 'solid white',
    borderWidth: '0 2.5px 2.5px 0',
    transform: 'translate(-50%, -60%) rotate(45deg)',
    display: checked ? 'block' : 'none',
  };

  return (
    <div style={{ scale: '0.9' }}>
      <input
        type="checkbox"
        id={id}
        style={{ display: 'none' }}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} style={checkboxStyle}>
        {checked && <span style={checkmarkStyle}></span>}
      </label>
    </div>
  );
};

export default CustomCheckbox;
