import React, { useState, useEffect } from 'react';

import {
    Typography,
    Button,
    styled,
} from "@material-ui/core";

import TextArea from './CustomTextArea.web';
import Dialog from '../../components/src/Dialog.web';

interface Props {
    visible: boolean;
    onClose?: () => void;
    onSave?: (data: any) => void;
    savedOptions?: any[];
    type?: string; 
}

export default function AddOptionDialog({ visible, savedOptions, onClose, onSave,type  }: Props) {
    const [newOptions, setNewOptions] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleNewOptionChange = (event: any) => {
        setNewOptions(event.target.value);
        setError('');
    }

    const handleSaveClick = () => {
        let optionsArray = newOptions.split("\n");
        console.log(savedOptions?.map((option) => option).join("\n") || '');

        // Remove any leading/trailing whitespace from each option
        optionsArray = optionsArray.map((option) => option.trim()).filter((option) => option !== "");

        const invalidOptions = optionsArray.filter(option => option.length > 128);
        if (invalidOptions.length > 0) {
            setError('Each option should be maximum 128 characters long');
            return;
        }

        if (optionsArray.length > 7 && type !=="") {
            setError('The maximum number of statements is 7');
            return;
        }

        if (onSave) {
            onSave(optionsArray);
        }

        onClose && onClose();
    }

    const handleClose = () => {
        setError('');
        setNewOptions(savedOptions?.map((option) => option).join("\n") || '');
        onClose && onClose();
    };

    useEffect(() => {
        const options = savedOptions?.map((option) => option).join("\n") || '';
        setNewOptions(options);
    }, [savedOptions]);

    return (
        <Dialog style={styles.dialog} visible={visible}>
            <DialogBody>
                <div style={styles.headContainer}>
                    <Typography style={styles.dialogHeader}>
                        Adding options
                    </Typography>
                </div>
                <DialogContent>
                    <div style={styles.addColumn}>
                        <TextArea
                            label='Separate your options by a new line'
                            value={newOptions}
                            onChange={handleNewOptionChange}
                            error={error}
                            style={styles.opt}
                        />
                    </div>
                    <div style={styles.buttonContainer}>
                        <Button onClick={handleSaveClick} style={styles.btn2}>Save</Button>
                        <Button onClick={() => handleClose()} style={styles.btn3}>Cancel</Button>
                    </div>
                </DialogContent>
            </DialogBody>
        </Dialog>
    )
}

const DialogBody = styled('div')({
    width: '540px',
    borderRadius: '16px',
    background: '#FFF',
    '@media (max-width:768px)': {
        width: '100%',
    }
})
const DialogContent = styled('div')({
    padding: '16px 50px 40px 50px',
    '@media (max-width:500px)': {
        padding: '15px 15px 40px'
    }
})
const styles = {
    dialog: {
        borderRadius: '16px',
    },
    btn2: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        fontSize: '16px',
        fontWeight: 700,
        color: 'rgba(255, 255, 255, 1)',
        lineHeight: 'normal',
        fontStyle: 'normal',
        flex: '1 1 0px',
        marginTop: '30px',
        textTransform: 'none' as const,
        fontFamily: "aeonik-regular"
    },
    btn3: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        background: 'none',
        fontSize: '16px',
        fontWeight: 700,
        color: '#333',
        lineHeight: 'normal',
        fontStyle: 'normal',
        flex: '1 1 0px',
        textTransform: 'none' as const,
        fontFamily: "aeonik-regular"
    },
    dialogBody: {
        width: '540px',
        borderRadius: '16px',
        background: '#FFF',
        '@media (max-width:768px)': {
            width: '100%',
        }

    },
    opt: {
        marginBottom: '16px'
    },
    headContainer: {
        borderBottom: '1px solid #E7E7E7',
        padding: '32px 0px 24px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogHeader: {
        color: ' var(--text, #202020)',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        fontFamily: "aeonik-bold"
    },
    dialogContent: {
        padding: '16px 50px 40px 50px',
        '@media (max-width:500px)': {
            padding: '15px 15px 40px'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    addColumn: {
        display: 'flex',
        flex: '1 1 0px',
    }
}
