import React, { Component } from "react";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Input from "./CustomInputcode.web";          // Replace with new Input code

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardHeader: {
        borderBottom: '1px solid #E7E7E7',
        padding: '26px 40px',
    },
    cardTitle: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: ' 23px',
        textAlign: 'left',
        marginBottom: 0,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0px',
        padding: '30px 40px',
    },
    FormTypographyStyle: {
        color: '#202020',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        marginBottom: '18px',
    },
    resendLink: {
        display: 'flex',
        marginTop: '24px',
        color: '#202020',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px !important',
        maxWidth: '80px',
        marginRight: '10px',
    },
    signUpLink: {
        color: 'var(--medium-blue, #4B6EB0)',
        fontWeight: 700,
    },
    textCenter: {
        textAlign: 'center',
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    gridContainer:{
        '@media (max-width:545px)':{
            width:'10px',
            flexWrap:'nowrap',
            scale:'0.7',
        }
    }
};

interface Props {
    classes: any;
    code: string[];
    handleVerificationCodeChange: (code: any) => void;
}

// Customizable Area End

export class CodeVerification extends Component<Props> {
    private inputRefs: React.RefObject<HTMLInputElement>[];
    constructor(props: any) {
        super(props);
        this.inputRefs = Array.from({ length: 4 }, () => React.createRef<HTMLInputElement>());
    }

    handlePasteClick = (e: any) => {
        const data = e.clipboardData.getData('Text').trim();
        const code = data.split('');

        if (code.length === 4) {
            this.props.handleVerificationCodeChange(code);
            this.inputRefs[3].current?.focus();
        }
    };

    handleChange = (data: any, index: any) => {
        const value = data.key;

        if (!isNaN(value)) {
            if (value.length <= 1) {
                let newCode = this.props.code;
                newCode[index] = value;
                this.props.handleVerificationCodeChange(newCode);
            }

            if (index === 0) {
                this.inputRefs[1].current?.focus();
            } else if (index === 1) {
                this.inputRefs[2].current?.focus();
            } else if (index === 2) {
                this.inputRefs[3].current?.focus();
            } else if (index === 3) {
                console.log('code', this.props.code.join(''))
            }
        }

        else if (data.key === 'Backspace') {
            let newCode = this.props.code;
            newCode[index] = '';
            this.props.handleVerificationCodeChange(newCode);

            if (index === 1) {
                this.inputRefs[0].current?.focus();
            }
            else if (index === 2) {
                this.inputRefs[1].current?.focus();
            }
            else if (index === 3) {
                this.inputRefs[2].current?.focus();
            }
        }
    };

    render() {
        const { classes, code } = this.props;
        return (
            <Grid container className={classes.gridContainer}>
                {this.inputRefs.map((ref, index) => {
                    return (
                        <Grid md={2} className={classes.inputContainer} item key={index}>
                            <Input
                                type='number'
                                onKeyDown={e => this.handleChange(e, index)}
                                onPaste={this.handlePasteClick}
                                ref={ref}
                                maxLength={1}
                                value={code[index]}
                                name={index.toString()}
                                inputStyle={{
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    fontWeight: 700,
                                    color: '#1C386D',           // Replace with correct color
                                    border: '1px solid #BFCAE4'
                                }}
                                style={{ width: '80px', height: '80px', marginRight: '10px', textAlign: 'center' }}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        );
    }
}

//@ts-ignore
export default withStyles(styles)(CodeVerification);