import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React, { ChangeEvent, FormEvent } from 'react';
import {
    TextField,
    Radio,
    FormControlLabel
} from "@material-ui/core";

// type interface
export interface ISurveyListsResponse {
    data: ISurveyLists[];
  }
  
  
  export interface ISurveyLists {
    id: string | number;
    type: string;
    attributes: {
      id: number | string;
      name: string;
      description: string;
      is_activated: boolean;
    };
  }
  
  export interface ISurveyData {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      questions: IQuestion[];
    };
  }
  
  export interface IQuestion {
    id: number;
    question_type: string;
    question_title: string;
    survey_id: number;
    rating?: number | string;
    options?: IQuestionOption[];
  }
  
  export interface IQuestionOption {
    id: number;
    name: string;
  }
  
  
  export interface Answers {
    [key: number]: string | IQuestionOption[] | undefined | object
  }
  
  export interface IAnswerLists {
    question_id: number;
    answer: string | number | IQuestionOption | IQuestionOption[] | undefined | object;
  }
  
  export interface IValidationErrors {
    [key: number]: string
  }

  export interface Options {
    id: number
  }
  
  
  
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
  surveyList: any[];
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SurveysController extends BlockComponent<
    Props,
    S,
    SS
> {
  getUserSurveyCallId:  string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      surveyList: [],
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

        this.state = {
            // Customizable Area Start
            surveyList: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      // Customizable Area Start
    if (!apiRequestCallId || !responseJson) {
        return;
    }
    if (apiRequestCallId === this.getUserSurveyCallId && responseJson) {
      this.getUserSurveyDetailsReceive(responseJson)
    }
      // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    await Promise.all([this.getUserSurveyList()])
  }

  getUserSurveyList = () => {
    const authToken = localStorage.getItem('token')

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserSurvaysEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    this.getUserSurveyCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserSurveyDetailsReceive = (responseJson: any) => {
    if (responseJson?.data) {
        this.setState({ surveyList: responseJson.data });
    }
    else if(responseJson?.errors.message === "Authentication token invalid")
    {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationAuthBlockMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }

  fillSurveyClick = (surveyId: number) => {
    this.props.navigation.navigate("UserFillSurvey", { surveyId })
  }

  renderInitials = (firstName: string, lastName: string) => {
    const initials = (firstName.charAt(0).toUpperCase() || "-") + (lastName.charAt(0).toUpperCase() || "-");
    return initials;
  }

  // Customizable Area End
}

// Customizable Area End