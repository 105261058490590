// Customizable Area Start
import React from "react";

import {
    Grid,
    Box,
    Button,
    Typography,
    Link,
    Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import Input from "../../../components/src/CustomInput.web";

import SignInUserController, {
    Props,
} from "./SignInUserController.web";

export class SignInUser extends SignInUserController {
    constructor(props: Props) {
        super(props);
    }



    render() {
        const { emailError, passwordError } = this.state;

        const { classes } = this.props;
        return (
            <div className={classes.wrapper}>
                <Box className={classes.mainBox}>
                    <div className={classes.header}>
                        <div className={classes.headerContent}>
                            <Typography className={classes.loginText}>Login</Typography>
                            <div className={classes.underline} />
                        </div>
                    </div>
                    <Grid container spacing={2} className={classes.loginFormWrap}>
                        <Grid item xs={12} md={5}>
                            <Input
                                data-test-id="emailInput"
                                error={emailError}
                                value={this.state.email}
                                onChange={this.handleChangeEmail}
                                placeholder='Example: user@gmail.com'
                                label="Email address"
                                // type="email"
                                style={{ marginBottom: '24px' }}
                            />
                            <Input
                                data-test-id="passwordInput"
                                error={passwordError}
                                value={this.state.password}
                                onChange={this.handleChangePassword}
                                onKeyPress={this.handleEnterPressed}
                                placeholder='Example: ABC'
                                label="Password"
                                type="password"
                                style={{ marginBottom: '12px' }}
                            />
                            <div  className={classes.forgotPasswordLink}>
                                <Typography id="navigationToForgotPassword" onClick={this.handleUserNavigation} style={{ fontFamily: 'aeonik-regular',cursor:'pointer',display:'inline-block',color:'#3f51b5' }}>Forgot password?</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.btnContainer}>
                    <Button
                        data-test-id='login-button'
                        onClick={this.handleClickLoginButton}
                        className={classes.loginButton}
                    >
                        Login
                    </Button>
                </div>
                </Box>
                <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3200}
                    open={this.state.snackbar.open}
                    onClose={this.handleSnackbarClose}
                >
                    <Alert severity={this.state.snackbar.severity}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

const styles = {
    wrapper: {
        flex: '1 1 0px',
        flexDirection: 'column' as const,
        display: 'flex',
    },
    mainBox: {
        border: '1px solid #ddd',
        margin: '45px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        borderRadius: '20px',
        background: "#FFFFFF",
        display: 'flex',
        flexDirection: 'column' as const,
        flex: '1 1 0px',
        '@media (max-width:500px)':{
            margin:'40px 25px',
        }
    },
    header: {
        borderBottom: '1px solid #E7E7E7',
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        maxWidth: 'fit-content',
        alignItems: 'center',
    },
    loginText: {
        padding: '25px 40px 22px 40px',
        color: 'var(--text, #202020)',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        fontFamily: 'aeonik-bold',
    },
    underline: {
        borderRadius: '5px 5px 0px 0px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        width: '60px',
        height: '5px'
    },
    loginFormWrap: {
        padding: '40px' as const,
        height: 'calc(100% - 92px)' as const,
        '@media (max-width:500px)':{
            padding:'30px',
            
        }
    },
    forgotPasswordLink: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
        textAlign: 'right' as const,
        display: 'block'
    },
    signUpLink: {
        fontWeight: 600,
        fontSize: ' 14px',
        lineHeight: '24px',
        color: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
    },
    cstInput: {
        borderRadius: "20px"
    },
    spacer: {
        flexGrow: 1,
    },
    loginButton: {
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "unset" as const,
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        borderRadius: "10px",
        padding: "18px 24px",
        display: "flex",
        alignItems: "center",
        color: "white",
        minWidth: '200px',
        alignSelf: 'end',
        fontFamily: 'aeonik-bold'
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'end',
        margin: '0px 45px 45px 0px',
        '@media (max-width:500px)':{

            margin:"0px",
            marginBottom:'60px',
            justifyContent:'center',
            flexDirection:'row',
        }
    }
};

export default withStyles(styles)(SignInUser);

// Customizable Area End
