import React, { FunctionComponent } from 'react'
import ReactHtmlParser from 'react-html-parser'; 

import {
    Button,
    Checkbox,
    styled,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText
} from "@material-ui/core";

interface Data {
    id: number | null;
    description: string;
    is_accepted: boolean;
}

interface Props {
    title?: string;
    data: any;
    visible: boolean;
    is_checked?: boolean;
    onClose: () => void;
    onAcceptClick?: () => void;
    acceptedChanged?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsAndPrivacyDialog: FunctionComponent<Props> = ({ title = '', is_checked = false, data, visible, onClose, acceptedChanged, onAcceptClick }) => {
  return (
    <CustomDialog open={visible}>
      <Title>{title}</Title>
      <DialogContent style={{ maxHeight: '70vh',padding:"0px" }}>
        <DialogContentText component="div">
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ position: 'sticky', bottom: 0, background: '#fff', zIndex: 1,padding:'12px 16px' }}>
        <CustomButton onClick={onClose}>Close</CustomButton>
      </DialogActions>
    </CustomDialog>
  )
}
const CustomDialog = styled(Dialog)({
    '& .MuiDialogTitle-root':{
      borderBottom:'1px solid rgb(231, 231, 231)'
    },
    '& .MuiDialog-paper':{
      borderRadius:'10px'
    }
})
const CustomButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  color: 'white',
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  padding: '8px 22px',
  fontFamily: 'aeonik-bold',
  fontSize: '1rem',
})
  const Title = styled(DialogTitle)({
    '& .MuiTypography-h6':{
      fontFamily:'aeonik-bold'
    }
  })
  const MainSecWrap = styled('div')({
    '@media screen and (max-width:500px)':{
      marginLeft:'-11px',
      borderTop:'1px solid rgb(231, 231, 231)',
      borderBottom:'1px solid rgb(231, 231, 231)',
    }
})
  const MainSec = styled('div')({
    border: '1px solid rgb(231, 231, 231)',
    overflow: 'scroll',
    height: '270px',
    padding: '24px',
    '@media screen and (max-width:500px)':{
      paddingTop:'0px',
      marginLeft:'-11px',
      width:'82%',
      border:'none',
    }
  })
  const Btn = styled('button')({
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    borderRadius: '10px',
    fontFamily: 'aeonik-bold',
    padding: '18px 32px',
    lineHeight: 'normal',
    fontStyle: 'normal',
    color: 'rgba(255, 255, 255, 1)',
    background: '#FF0000',
    fontWeight: 700,
    border: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    '@media screen and (max-width: 500px)': {
      padding: '14px 30px',
      fontSize: '14px',
      marginRight: '35px',
    },
    '@media screen and (min-width: 501px) and (max-width: 700px)': {
      padding: '18px 32px', // Reset padding for this range
      fontSize: '16px', // Reset fontSize for this range
      marginRight: '0px', // Reset marginRight for this range
    },
  });
  
const webStyle = {
    termsModal: {
        borderRadius: '16px',
    },
    termsCheck: {
        display: 'flex'
    },
    mainsec: {
        border: '1px solid rgb(231, 231, 231)',
        overflow: 'scroll',
        height: '270px',
        padding: '24px'
    },
    container: {
        padding: '40px',
        width: '540px',
        borderRadius: '16px',
        background: '#FFF',
    },
    header: {
        fontSize: '18px',
        color: 'black',
        fontWeight: 700,
        lineHeight: '20px',
        fontFamily: 'aeonik-bold',
        textAlign: 'center' as const,
    },
    actions: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '21px',
        justifyContent: 'flex-end'
    },
    buttonPrimary: {
        alignItems: 'center',
        padding: '18px 32px',
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        justifyContent: 'center',
        borderRadius: '10px',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: 'normal',
        fontFamily: 'aeonik-bold',
        color: 'rgba(255, 255, 255, 1)',
        border: 'none',
        fontWeight: 700,
        marginLeft: '12px',
        textTransform: 'none' as const
    },
    btn: {
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        borderRadius: '10px',
        fontFamily: 'aeonik-bold',
        padding: '18px 32px',
        lineHeight: 'normal',
        fontStyle: 'normal',
        color: 'rgba(255, 255, 255, 1)',
        background: '#FF0000',
        fontWeight: 700,
        border: 'none',
        cursor: 'pointer',
        textTransform: 'none' as const
    },
    text: {
        fontFamily: 'aeonik-regular',
        fontStyle: 'normal',
        color: '#202020',
        lineHeight: '20px',
        fontWeight: 600,
        textAlign: 'center' as const,
        fontSize: '14px',
    },
};

export default TermsAndPrivacyDialog;
