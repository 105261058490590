import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface NavigationProps {
  navigate: (route: string, params?: SS) => void;
  getParam: (param: string, defaultValue?: string) => string | undefined;
}

export interface User {
  email: string;
  first_name: string | null;
  id: string;
  last_name: string | null;
  industry_sector: string | null;
  password: string;
  type: string;
  confirm_password: string;
  company: string | null;
  local_authority: string | null;
  district: string | null;
  terms_of_service: boolean;
  activated: boolean;
  group_ids: number[];
  name:string;
}

interface APIResponse {
  users: User[];
  total_count: number;
}

interface SubmitUserListResponse {
  password: string;
  message: string;
  email: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationProps;
  id: string;
  // Customizable Area Start
  classes: {
      mainContainer: string,
      root: string,
      cardHeader: string,
      mainBox: string,
      tabsurvey: string,
      lable: string,
      edit: string,
      modalbtn: string,
      close: string,
      surveyTital: string,
      rectangleBody: string,
      circle: string,
      plusIcon: string,
      surveyText: string,
      btnEle: string,
      card: string,
      container: string,
      imgEle: string,
      cardBox: string,
      Respondents: string,
      createUser: string,
      table: string,
      tableheader: string,
      cardHeaderName: string,
  }
  // Customizable Area End
}

export interface GroupObj {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  group_name: null;
  user_count: string | number

}

export interface Response {
  groups: GroupObj[];
  total_count: number;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: number;
  error: boolean;
  createGroup: boolean;
  createGroupAdd: {
    email: string;
    description: string;
  },
  createUserAdd: {
    email: string;
    name: string;
  },
  groupList: GroupObj[];
  groupError: boolean;
  currentPage: number;
  itemsPerPage: number;
  userlistPerPage: number;
  totalCount: number;
  userList: User[];
  cancelModal: boolean;
  userError: boolean;
  nameError: boolean,
  emailError: boolean,
  useListcurrentPage: number,
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupDetailCallId: string = ""
  createGroupCallId: string = ""
  onCreateCallId: string = ""
  getUserListCallId: string = ""
  onCreateUserListCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      createGroup: false,
      error: false,
      createGroupAdd: {
        email: '',
        description: '',
      },
      createUserAdd: {
        email: '',
        name: '',
      },
      groupList: [],
      groupError: false,
      currentPage: 1,
      itemsPerPage: 7,
      totalCount: 0,
      userList: [],
      userlistPerPage: 20,
      cancelModal: false,
      userError: false,
      nameError: false,
      emailError: false,
      useListcurrentPage: 1
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(_from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.getGroupDetailCallId && responseJson) {
      this.getGroupDetailReceive(responseJson)
    }
    else if (apiRequestCallId === this.onCreateCallId && responseJson) {
      this.submitReceiver(responseJson)
    }
    else if (apiRequestCallId === this.getUserListCallId && responseJson) {
      this.getUserListDetailReceive(responseJson)
    }
    else if (apiRequestCallId === this.onCreateUserListCallId && responseJson) {
      this.submitUserListReceiver(responseJson)
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End

  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const perPage = 7;
    const page = 1;
    const perPageUserList = 20;
    const pageUserList = 1;
    await Promise.all([this.getGroupDetails(perPage, page), this.getUserListDetails(perPageUserList, pageUserList)]);
  }

  getGroupDetails = async (perPage: number, page: number) => {
    const queryString = `bx_block_groups/groups?per_page=${perPage}&page=${page}`;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      queryString
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getGroupDetailCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getGroupDetailReceive = (responseJson: Response) => {
    if (responseJson?.groups) {
      this.setState({
        groupList: responseJson.groups,
        totalCount: responseJson.total_count,
      });

    }
  }

  getUserListDetails = async (perPageUserList: number, pageUserList: number): Promise<void> => {

    const queryString = `bx_block_user/users/users_list?per_page=${perPageUserList}&page=${pageUserList}`;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };
    const requestMessageDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      queryString
    );
    requestMessageDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getUserListCallId = requestMessageDetails.messageId;
    runEngine.sendMessage(requestMessageDetails.id, requestMessageDetails);
  }

  getUserListDetailReceive = (responseJson: APIResponse) => {
    if (responseJson?.users) {
      this.setState({ userList: responseJson.users });

    }
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page }, () => {
      const { itemsPerPage } = this.state;
      this.getGroupDetails(itemsPerPage, page);
    });
  };


  handlePageChangeUserList = (event: React.ChangeEvent<unknown>, pageUserList: number) => {
    this.setState({ useListcurrentPage: pageUserList }, () => {
      const { userlistPerPage } = this.state;
      this.getUserListDetails(userlistPerPage, pageUserList);
    });
  };

  handleCreateGroup = () => {
    this.setState({ createGroup: true });
  };

  handleCloseGroup = () => {
    this.setState({ createGroup: false });
  };

  handleSnackbarClose = () => {
    this.setState({ emailError: false, nameError: false, groupError: false });
  };

  handleChangeTab = (event: React.ChangeEvent<unknown>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  handleGroupSubmit = async () => {

    const { email } = this.state.createGroupAdd;

    if (!email) {
      this.setState({ groupError: true });
    } else {
      this.setState({ groupError: false });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: await getStorageData("token")
      };

      let formData = {
        name: this.state.createGroupAdd.email,
        description: this.state.createGroupAdd.description,
      };

      const requestMessageData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageData.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageData.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createGroupApiEndPoint
      );
      requestMessageData.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessageData.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formData)
      );
      this.onCreateCallId = requestMessageData.messageId;

      runEngine.sendMessage(requestMessageData.id, requestMessageData);
    }
  };

  submitReceiver = (responseJson: SubmitUserListResponse) => {
    if (responseJson) {
      this.setState({
        createGroupAdd: {
          email: "",
          description: ""
        },

      });

      const { itemsPerPage, currentPage } = this.state;
      this.getGroupDetails(itemsPerPage, currentPage);
      this.handleCloseGroup();
    }
  }

  handleUserListSubmit = async (event: React.FormEvent<unknown>) => {

    const { name, email } = this.state.createUserAdd;

    if (!name) {
      this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false });
    }
    if (!email) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: await getStorageData("token")
      };

      let formData = {
        email: this.state.createUserAdd.email,
        name: this.state.createUserAdd.name,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createUserApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formData)
      );
      this.onCreateUserListCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  };

  submitUserListReceiver = (responseJson: APIResponse) => {
    if (responseJson) {
      this.setState({
        createUserAdd: {
          email: "",
          name: ""
        },
      }, () => {
        const { userlistPerPage, useListcurrentPage } = this.state;
        this.getUserListDetails(userlistPerPage, useListcurrentPage);
      });
      this.handleClose();
    }
  }

  handleChangeCreateGroup = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      createGroupAdd: {
        ...prevState.createGroupAdd,
        [name]: value,
      },
    }));
  };

  handleChangeCreateUser = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState((prevState) => ({
      createUserAdd: {
        ...prevState.createUserAdd,
        [name]: value,
      },
    }));
  };

  handleUserList = (data: number): void => {
    this.props.navigation.navigate("UserLists", { id: data });
  }

  handleClickOpen = () => {
    this.setState({ cancelModal: true });
  };

  handleClose = () => {
    this.setState({ cancelModal: false });
  };


  // Customizable Area End
}
