import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
  InputLabel,
  Slide,
  Select,
  TextField,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  MenuItem,
  Checkbox,
  Dialog,
  ListItemText,
  DialogContent,
  DialogActions,
  Snackbar,
  Breadcrumbs,
  Link,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Alert } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import GroupUsersController, { Props } from "./GroupUsersController.web";
import { uploadIcon } from "./assets";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import { Link as RouterLink } from "react-router-dom";
// Customizable Area End

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export class GroupUsers extends GroupUsersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
  // Customizable Area Start
  const { userList, groupList, userCount, page, rowsPerPage, adminDetails } = this.state;
  const { classes } = this.props;
  // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box className={classes.mainBox}>
          <Box className={classes.cardHeader}>
            <Breadcrumbs
              separator={<ArrowIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <RouterLink
                to="/AccountCreation/user"
                data-test-id="group-breadcrumb"
                className={classes.groupLink}
              >
                <Typography style={{ fontFamily: 'aeonik-regular',fontWeight:900 }} color="inherit">Groups</Typography>
              </RouterLink>
              <Typography style={{ color: "#1C386D", fontFamily: 'aeonik-regular',fontWeight:900 }}>User list</Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              md={4}
              sm={12}
              style={{ justifyContent: "flex-start" }}
            >
              <Button
                size="large"
                data-test-id="create-user"
                className={classes.createUser}
                onClick={this.handleClickCreateUser}
              >
                Create user
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <a
                download="User Upload Template.xlsx" 
                target="_blank" 
                href={this.userTemplateFile}
                data-test-id="view-upload-template"
                className={classes.link}
                style={{ padding: "10px" }}
              >
                Download template
              </a>
              <Button
                size="large"
                data-test-id="Bulk-upload-user"
                className={classes.uploadUsers}
                onClick={this.handleUploadUserClick}
                endIcon={<img src={uploadIcon} alt="Icon" />}
              >
                Bulk upload users
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead className={classes.tableheader}>
                    <TableRow>
                      <TableCell className={classes.tabelCellHead}>
                        First Name
                      </TableCell>
                      <TableCell className={classes.tabelCellHead}>
                        Last Name
                      </TableCell>
                      <TableCell className={classes.tabelCellHead}>
                        Email
                      </TableCell>
                      {adminDetails?.attributes?.has_districts && (
                        <TableCell className={classes.tabelCellHead}>
                          District/Local authority
                        </TableCell>                        
                      )}
                      {adminDetails?.attributes?.has_industry_sector && (
                        <TableCell className={classes.tabelCellHead}>
                          Industry/Sector
                        </TableCell>
                      )}
                      {adminDetails?.attributes?.has_company && (
                        <TableCell className={classes.tabelCellHead}>
                          Company name
                        </TableCell>
                      )}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList.map((row: any) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.first_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.email}
                        </TableCell>
                        {adminDetails?.attributes?.has_districts && (
                          <TableCell component="th" scope="row">
                            {row.district_name || "-"}
                          </TableCell>
                        )}
                        {adminDetails?.attributes?.has_industry_sector && (
                          <TableCell component="th" scope="row">
                            {row.industry_name || "-"}
                          </TableCell>                        
                        )}
                        {adminDetails?.attributes?.has_company && (
                          <TableCell component="th" scope="row">
                            {row.company || "-"}
                          </TableCell>                        
                        )}
                        <TableCell component="th" scope="row">
                          {row.group_ids.includes(
                            Number(this.props.navigation.getParam("groupId"))
                          ) ? (
                            <Button
                              variant="outlined"
                              className={classes.btn}
                              data-test-id={`Remove-button-${row.user_id}`}
                              onClick={() =>
                                this.removeUserFromGroup(row.user_id)
                              }
                            >
                              Added
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              data-test-id={`Select-button-${row.user_id}`}
                              className={classes.EditButton}
                              onClick={() =>
                                this.selectUserForGroup(row.user_id)
                              }
                            >
                              Select
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  
                </Table>
              </TableContainer>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  border: "1px solid #BBBBBB",
                }}
              >
                <span className={classes.pagination}>
                  {rowsPerPage * (page - 1) + 1} to{" "}
                  {rowsPerPage * page > userCount
                    ? userCount
                    : rowsPerPage * page}{" "}
                  of {userCount} Items
                </span>
                <Pagination
                  className={classes.root}
                  count={Math.ceil(userCount / rowsPerPage)}
                  page={page}
                  onChange={this.handleUserPagination}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.createUserModal}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
              top: 100,
              position: "absolute",
            },
          }}
        >
          <Box className={classes.surveyTital}>Create User</Box>
          <DialogContent
            style={{ height: "auto", padding: "9px 35px 20px 35px" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>
                  User Email Address
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  variant="outlined"
                  data-test-id="add-email"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  error={this.state.error}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>
                  Select the group
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  data-test-id="select-group"
                  multiple
                  value={this.state.userGroups}
                  onChange={this.handleCheck}
                  renderValue={() =>
                    groupList
                      .filter((group: any) =>
                        this.state.userGroups.includes(group.id)
                      )
                      .map((group: any) => group.name)
                      .join(", ")
                  }
                  MenuProps={MenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                >
                  {this.state.groupList.map((group: any) => (
                    <MenuItem key={group.id} value={group.id}>
                      <ListItemText
                        primary={group.name}
                        style={{ overflow: "hidden" }}
                      />
                      <Checkbox
                        style={{ color: "#3c5584" }}
                        checked={this.state.userGroups.includes(group.id)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Snackbar
              open={this.state.error}
              autoHideDuration={4000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: "top center" },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}
            >
              <Alert
                severity="warning"
                color="error"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #DEDEDE",
                }}
              >
                {this.state.errorMessage}
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Create-btn"
                  className={classes.edit}
                  onClick={this.handleCreateUserSubmit}
                >
                  Create
                </Button>
                <Button
                  data-test-id="cancel-btn"
                  className={classes.close}
                  onClick={this.handleCancelCreateUser}
                  style={{ marginBottom: "30px" }}
                >
                  cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.uploadModal}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "15px",
              top: 100,
              position: "absolute",
            },
          }}
        >
          <DialogContent>
            <Typography className={classes.Error} gutterBottom>
              Bulk upload
            </Typography>
            <Typography className={classes.confrim} gutterBottom>
              Use this upload feature to add multiple users at
              <br /> once to the user list.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <InputLabel
                  htmlFor="bulk-user-file-upload"
                  className={classes.edit}
                >
                  <input
                    disabled={this.state.uploadCancelState}
                    type="file"
                    accept=".xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={this.handleFileChange}
                    id="bulk-user-file-upload"
                    style={{ display: "none" }}
                  />
                  {this.state.uploadCancelState ? <CircularProgress size={30}/> : 'Upload'}
                </InputLabel>
                <Button
                  disabled={this.state.uploadCancelState}
                  data-test-id="modal-Cancel-btn"
                  className={classes.close}
                  onClick={this.handleUpoadUserCancel}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.uploadSuccessModal}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "300px",
              // padding: "20px",
              top: 100,
              position: "absolute",
            },
          }}
        >
          <DialogContent>
            <Typography
              className={classes.Error}
              style={{ marginTop: "30px" }}
              gutterBottom
            >
              Upload successful
            </Typography>
            <Typography style={{textAlign:'center',margin:'15px 0px',color:'#359577'}}>
              {this.state.successMessage}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Continue-btn"
                  className={classes.edit}
                  style={{ marginBottom: "25px",padding:'14px 45px' }}
                  onClick={this.handleClickUploadSuccessContinue}
                >
                  Close
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.uploadFailModal}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "20px",
              top: 100,
              position: "absolute",
            },
          }}
        >
          <DialogContent>
            <Typography className={classes.Error} gutterBottom>
              Upload failed
            </Typography>
            <div>
              {this.state.bulkUploadResponseArray.map((message, index) => (
                <Typography
                  key={index}
                  style={{ color:'#ef536c' ,textAlign:'center',fontSize:'18px',fontFamily:'aeonik-regular'}}
                  gutterBottom
                >
                  {message}
                </Typography>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Tryagian-btn1"
                  className={classes.edit}
                  onClick={this.handleClickUploadUserFailTryAgain}
                >
                  Try Again
                </Button>
                <Button
                  data-test-id="modal-cancel1-btn"
                  className={classes.close}
                  onClick={this.handleClickUploadUserFailCancel}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog aria-labelledby="customized-dialog-title" open={this.state.particalUploadModal} fullWidth
          //Partical success modal
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} style={{ fontFamily: 'aeonik-bold' }} gutterBottom>
              Upload partially successful
            </Typography>
            <Typography className={classes.Error} style={{ textAlign: 'center', fontFamily: 'aeonik-regular' }} gutterBottom>
              Some issues were encountered during the upload:
            </Typography>
            <div>
              {this.state.bulkUploadResponseArray.map((message, index) => (
                <Typography
                  key={index}
                  style={{ color: index === 0 ? '#359577' : '#ef536c', textAlign: 'center', fontSize: '18px', fontFamily: 'aeonik-regular' }}
                  gutterBottom
                >
                  {message}
                </Typography>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Tryagian-btn2"
                  className={classes.edit}
                  onClick={this.handleClickUploadUserFailTryAgain}>
                  Try Again
                </Button>
                <Button
                  data-test-id="modal-cancel1-btn2"
                  className={classes.close}
                  onClick={this.handleClickUploadUserFailCancel}>
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog aria-labelledby="customized-dialog-title" open={this.state.fileSizeErrorModal} fullWidth
          //File Size Error Modal
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "415px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} style={{ fontFamily: 'aeonik-bold',fontSize:'20px'}} gutterBottom>
              Upload fail
            </Typography>
            <Typography style={{ textAlign: 'center', fontFamily: 'aeonik-regular',color:'#ef536c',fontSize:'18px' }} gutterBottom>
              Reason: The file size has exceed the limit. File size should be less than 15 MB
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Tryagian-btn"
                  className={classes.edit}
                  onClick={this.handleClickUploadUserFailTryAgain}>
                  Try Again
                </Button>
                <Button
                  data-test-id="modal-cancel1-btn3"
                  className={classes.close}
                  onClick={this.handleClickUploadUserFailCancel}>
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>


        <Snackbar
          open={Boolean(this.state.error)}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={Slide}
          TransitionProps={{
            style: { transformOrigin: "top center" },
            timeout: 500,
          }}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            severity="warning"
            color="error"
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              border: "1px solid #DEDEDE",
            }}
          >
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.createUserSuccess} autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={Slide}
          TransitionProps={{
            style: { transformOrigin: 'top center' },
            timeout: 500,
          }}
          onClose={this.handleSnackbarClose}>
          <Alert severity="success"
            color="success"
            style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
            User created successfully
          </Alert>
        </Snackbar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = {
  root: {
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: "#BFCAE4",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#BFCAE4",
    },
    "& .MuiPaginationItem-outlined": {
      border: "2px solid #F1F1F1",
    },
    "& .MuiPaginationItem-rounded": {
      borderRadius: "8px solid #F1F1F1",
    },
  },
  tabelCellHead: {
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  pagination: {
    marginTop: "3px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
  },
  EditButton: {
    borderRadius: "5px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#304a7c",
    fontWeight: 600,
    width: "65%",
  },
  cardHeader: {
    borderBottom: "1px solid #E7E7E7",
    padding: "0 40px",
    minHeight: "72px",
    display: "flex",
    alignItems: "center",
  },
  mainBox: {
    flex: "1 1 0px",
    border: "1px solid #ddd",
    margin: "50px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    height: "auto",
    "@media (max-width: 500px)": {
      margin: "20px",
    },
  },
  tabsurvey: {
    "&.MuiTab-root": {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: " 23px",
      color: " #202020",
      opacity: " 0.4",
      textTransform: "capitalize" as const,
      minHeight: "100px",
    },
    "&.MuiTab-root.Mui-selected": {
      color: " #202020",
      opacity: 1,
    },
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    marginBottom: "10px",
  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  edit: {
    padding: "18px 55px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    fontSize: "16px",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-regular',
    fontSize: "20px",
    fontWeight: 700,
    textTransform:"none" as const
  },
  confrim: {
    textAlign: "center" as const,
    marginTop: "20px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
  },
  modalbtn: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "10px",
    // marginTop: "15px",
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px",
  },
  createUser: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  link: {
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  uploadUsers: {
    width: "auto",
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  table: {
    border: "1px solid #B2B2B2",
  },
  tableheader: {
    backgroundColor: "#ECF2FF",
  },
  newSurvey: {
    position: "relative" as const,
    height: "280px",
    cursor: "pointer",
  },
  rectangle: {
    height: "100%",
    width: "100%",
  },
  dialogBody: {
    width: "540px",
    height: "370px",
    borderRadius: "16px",
    background: "#FFF",
  },
  headContainer: {
    borderBottom: "1px solid #E7E7E7",
    padding: "32px 0px 24px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogHeader: {
    color: " var(--text, #202020)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  dialogContent: {
    padding: "16px 50px 40px 50px",
  },
  formTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginBottom: "12px",
    color: "rgba(51, 51, 51, 1)",
  },
  formInput: {
    padding: "16px",
    borderRadius: "10px",
    border: "1px solid #E0E0E0",
    background: "#FFF",
    width: "100%",
    marginBottom: "40px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  rectangleBody: {
    // position: 'absolute' as const,
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    borderRadius: "50%",
    width: "58px",
    height: "58px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "24px",
  },
  plusIcon: {
    width: "43.5px",
    height: "43.5px",
  },
  surveyText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    color: "rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)",
  },
  text: {
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "36px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  btn: {
    borderRadius: "5px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "rgba(255, 255, 255, 1)",
    fontWeight: 600,
    width: "65%",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
  },
  btn2: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    color: "rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)",
    background: "none",
    marginTop: "20px",
  },
  card: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    minHeight: "240px",
    padding: "24px 16px",
    borderRadius: "28px" as const,
    border: "0.5px solid #DEDEDE",
    background: "#FCFCFC",
  },
  container: {
    display: "flex",
    flexDirection: "column" as const,
    color: "var(--text, #202020)",
  },
  img: {
    width: "50px",
    height: "50px",
    marginBottom: "24px",
  },
  subHead: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "8px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  cardBox: {
    display: "flex",
    justifyContent: "center",
    border: "1px dashed #A7A7A7",
    height: "280px",
    borderRadius: "28px" as const,
    background: "#FCFCFC",
  },
  Respondents: {
    // width: "150px",
    height: "22px",
    borderRedius: "5px",
    gap: "10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
    padding: "5px",
    color: "#304a7c",
  },
  ErrorMessage: {
    fontSize: "15px",
    fontWeight: 400,
    marginBottom: "10px",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    color: "#ff0404",
  },
  groupLink: {
    color: "#9FA0A1",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "normal",
    textDecoration: "none",
    cursor: "pointer",
    display: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default withStyles(styles)(GroupUsers);
// Customizable Area End
