// Customizable Area Start
import React, { useState } from 'react';
import { Box, TextField, FormLabel, Tooltip, InputAdornment, IconButton, Typography } from '@material-ui/core';
import { ErrorIcon, ToggleIcon } from './assets';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

interface LoginFormFieldsProps {
    // Customizable Area Start
    classes: any,
    error: boolean,
    id: string,
    placeholder: string,
    value: string,
    onChange: any,
    type: string,
    name: string,
    errorMessage: string | undefined,
    FormLabelText: string,
    inputProps?: any,
    tooltip?: boolean,
    maxLength?:number
    // Customizable Area End
}

export const CommanInputCode = (props: LoginFormFieldsProps) => {
    // Customizable Area Start
    const {
        classes,
        FormLabelText,
        error,
        id,
        placeholder,
        value,
        onChange,
        type,
        name,
        errorMessage,
        inputProps,
        tooltip,
        maxLength,
    } = props;
    const [showPassword, setShowPassword] = useState(false);
    // Customizable Area End
    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
            <style>
                {`
            input::-ms-reveal,
            input::-ms-clear {
              display: none;
            }
          `}
            </style>
            <Box className={classes.inputGroup}>
                {tooltip ? (
                    <Box className={classes.PasswordTooltip}>
                        <FormLabel htmlFor="outlined-basic" className={classes.FormLabelStyle}>{FormLabelText}</FormLabel>
                        <Tooltip
                            className={classes.cstTooltip}
                            title={
                                <ul style={{ paddingLeft: '10px' }}>
                                    {[
                                        'At least one capital letter',
                                        'At least one lowercase letter',
                                        'At least one number',
                                        'At least one special character',
                                        'Minimum length of 10 characters',
                                    ].map((item, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                color: '#202020',
                                                fontSize: '12px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            }
                            placement="right">
                            <img src={ToggleIcon} alt="Logo" style={{
                                width: '16px', height: '16px', marginTop: '-10px',
                                marginLeft: '10px',
                                filter: 'opacity(50%)',
                            }} />
                        </Tooltip>
                    </Box>
                ) : <FormLabel htmlFor="outlined-basic" className={classes.FormLabelStyle}>{FormLabelText}</FormLabel>}
                <TextField
                    inputProps={{ maxLength: maxLength }}
                    error={error}
                    className={classes.cstInput}
                    id={id}
                    variant="outlined"
                    placeholder={placeholder}
                    value={value}
                    type={showPassword ? 'text' : type}
                    name={name}
                    onChange={onChange}
                    InputProps={inputProps && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    style={{ color: "#4B6EB0" }}
                                    id={`${id}Toggle`} // Make the id attribute unique
                                    onClick={(e) => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    fullWidth
                />
                {error &&
                    <div className={classes.errorMessage} style={{ color: "Red" }}>
                        <img src={ErrorIcon} alt="Logo" style={{ width: '24px', height: '28px', verticalAlign: "middle" }} />
                        <Typography style={{ fontSize: '16px', color: '#202020', fontWeight: 500, marginLeft: '10px', fontFamily: "aeonik-regular" }}>{errorMessage}</Typography>
                    </div>
                }
            </Box>
        </ThemeProvider >
        // Customizable Area End
    );
};
// Customizable Area Start
const theme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                backgroundColor: '#fff',
                color: '#202020',
                fontSize: '12px',
                lineHeight: '24px',
                padding: '15px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                border: '1px solid #E0E0E0',
                borderRadius: '10px'
            },
        },
    },
});

const styles = {
    PasswordTooltip: {
        display: "flex",
        alignItems: "center",
    },
    errorMessage: {
        display: "flex",
        alignItems: "center",
        '@media (max-width:768px)':{
            alignItems:'start',
        }
    },
    FormLabelStyle: {
        fontFamily: 'aeonik-bold',
    }
}
// Customizable Area End
//@ts-ignore
export default withStyles(styles)(CommanInputCode);