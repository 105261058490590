import React, { FunctionComponent } from 'react';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import QuestionCard from './QuestionCard.web';
import Input from './CustomInput.web';
import DateFnsUtils from '@date-io/date-fns';
import {
  InputAdornment,
  IconButton,
  TextField,
  Box,
} from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles(() => ({
  datepicker: {
    marginTop: '16px',
    background: '#FFF',
    width: '270px',
    color: '#202020',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    outline: 'none',
    "& .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #E0E0E0',
      borderRadius: '10px',
    },
    
  },
  mainBox:{
    fontFamily: 'aeonik-regular',
    "& ::placeholder":{
        fontFamily: 'aeonik-regular',
    },
  }
}));

interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey:boolean
}

const DateQuestion: FunctionComponent<Props> = ({ onChange, data, onDeleteClick,isLiveorPastSurvey }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
    <QuestionCard
    isLiveorPastSurvey={isLiveorPastSurvey}
      title='Date Question'
      data={data}
      required
      onDeleteClick={() => onDeleteClick(data?.ind)}
      onRequiredCheck={(val) => onChange({
        ...data,
        required: val
      })}
    >
      <Input 
        disabled={isLiveorPastSurvey}
        readOnly={isLiveorPastSurvey}
        maxLength={128}
        value={data?.question_title}
        placeholder='Add question text'
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event.target.value,
          })
        }
      />
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
            fullWidth
            inputVariant="outlined"
            format="dd/MM/yy"
            placeholder="DD/MM/YY"
            data-test-id="endDate"
            value={data?.question_label || null}
            onChange={(date: any) => {
              const formattedDate = date.toISOString().substring(0, 10);
              onChange({
                ...data,
                question_label: formattedDate
              });
            }}
            TextFieldComponent={(props: any) => (
                <TextField
                    {...props}
                    className={classes.datepicker}
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <CalendarTodayIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    </MuiPickersUtilsProvider> */}

    </QuestionCard>
    </Box>
  )
};

export default DateQuestion;
