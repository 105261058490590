Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.apiErrorMessage = "Something went wrong";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfSurveyQuestions2";
exports.labelBodyText = "CfSurveyQuestions2 Body";

exports.token = "token";

const urlConfig = require("../../../framework/src/config");
exports.APIBaseURL = urlConfig.baseURL;
exports.getQuestionsEndPoint = "bx_block_surveys/questions";
exports.createSurveyQuestionsEndpoint = "bx_block_surveys/questions/create_question";
exports.scheduleSurveyEndPoint = "bx_block_surveys/surveys/schedule_survey";
exports.getSurveyGroupsEndPoint ="bx_block_groups/groups"
exports.getSurveyUsersEndPoint = "bx_block_user/users/users_email_list_for_groups"
exports.removeUserGroupApiEndPoint = "/bx_block_groups/groups/remove_user"
exports.selectUserGroupApiEndPoint = "/bx_block_groups/groups/add_user"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End