import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  Typography,
  Slide,
  Tabs,
  Tab,
  Grid,
  TextField,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/core/styles";
interface TabPanelData {
  value: number;
  index: number;
  children: React.ReactNode;
}

interface User {
  email: string;
  first_name: string | null;
  last_name: string | null;
  id: string;
  confirm_password: string;
  password: string;
  activated: boolean;
  industry_sector: string | null;
  company: string | null;
  local_authority: string | null;
  district: string | null;
  terms_of_service: boolean;
  type: string;
  group_ids: number[];
  isUserIngroup: boolean
}
// Customizable Area End

import UserListController, {
  Props,
} from "./UserListsController";

export class UserLists extends UserListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {

    const { tabValue, totalCount, itemsPerPage, currentPage } = this.state;
    const { classes } = this.props;
    const totalPages = Math.ceil(totalCount / itemsPerPage);
 
    const TabPanelSurvey = (props: TabPanelData) => {
      const { children, value, index } = props;

      return (
        <div
          id={`simple-tabpanel-${index}`}
          hidden={value !== index}
          aria-labelledby={`simple-tab-${index}`}
          role="tabpanel"
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    };


    function a11yProps(index: number) {
      return {
        id_tab: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

  
    return (
      // Customizable Area Start
      <Box className={classes.mainBox}>
        {
          <Box sx={{ width: '100%' }} className={classes.tabs} >
            <Box className={classes.cardHeader} >
              <Tabs value={tabValue} data-test-id="tabValue" onChange={this.handleChangeTab} aria-label="basic tabs example" id="tabChangeSection"
                TabIndicatorProps={{ style: { background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)", height: "10px", borderRadius: "15px 15px 0 0" } }
                }>
                <Tab className={classes.tabsurvey} data-test-id="Groups" label="Groups ->" onClick={() => this.handleRedirect()} />
                <Tab className={classes.tabsurvey} label="UserList" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {tabValue === 1 && (
              <TabPanelSurvey value={tabValue} index={1} data-test-id='tab-panel'>
                <Grid container spacing={2} style={{ padding: "20px" }}>
                  <Grid item xs={6} style={{ justifyContent: 'flex-start' }}>
                    <Button size="large" data-test-id="create-user" className={classes.createUser} onClick={() => this.handleClickOpen()}>
                      Create User
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer >
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead className={classes.tableheader}>
                          <TableRow>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(this.state.userList || []).map((userList, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {userList?.name ?? "-"}
                              </TableCell>
                              <TableCell align="center">
                                {userList?.email ?? "-"}
                              </TableCell>
                              <TableCell align="center">
                                {userList && userList.isUserIngroup ? (
                                  <Button data-test-id="Edit-button" className={classes.buttonEle} style={{ color: "#FBFBFB" }} onClick={() => this.removeUserToGroup(userList.user_id)}>
                                    Added
                                  </Button>
                                ) : (
                                  <Button variant="outlined" data-test-id="Edit-button" className={classes.EditButton} onClick={() => this.addUserToGroup(userList.user_id)}>
                                    Select
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}

                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box style={{ display: "flex", justifyContent: "center", padding: "20px", border: "1px solid #BBBBBB", alignItems: "center", gap: "10px" }}>
                      {`Showing ${((currentPage - 1) * itemsPerPage) + 1} to ${Math.min(currentPage * itemsPerPage, totalCount)} of ${totalCount} Items`}
                      <Pagination
                        className={classes.root}
                        id="page"
                        variant="outlined"
                        shape="rounded"
                        count={totalPages}
                        page={currentPage}
                        onChange={this.handlePageChange}
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanelSurvey>
            )}
          </Box>
        }

        <Dialog aria-labelledby="customized-dialog-title" open={this.state.cancelModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <Box className={classes.surveyTital}>
            Create User
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 20px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>User Name</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="user-add-email" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.createUserAdd.name}
                  onChange={(event) => this.handleChangeCreateuser(event)}
                  error={this.state.nameError}
                  name="name"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>User Email Address</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="add-email" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.createUserAdd.email}
                  onChange={(event) => this.handleChangeCreateuser(event)}
                  error={this.state.emailError}
                  name="email"
                />
              </Grid>
            </Grid>
            <Snackbar open={Boolean(this.state.emailError)} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              data-test-id="SnackbarClose"
              onClose={this.handleSnackbarClose} style={{marginTop:"55px"}}>
              
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                Email is not a valid email address
              </Alert>
            </Snackbar>
            <Snackbar open={Boolean(this.state.nameError)} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              data-test-id="Snackbar-Close"
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                Missing required field.
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Create-btn"
                  className={classes.edit}
                  onClick={this.handleUserListSubmit}
                >
                  Create
                </Button>
                <Button data-test-id="cancel-btn" className={classes.close} onClick={() => this.handleClose()} style={{ marginBottom: "30px" }}>cancel</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>

      // Customizable Area End
    );

  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  root: {
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: "#BFCAE4"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#BFCAE4"
    },
    "& .MuiPaginationItem-outlined": {
      border: '2px solid #F1F1F1',
    },
    "& .MuiPaginationItem-rounded": {
      borderRadius: '8px'
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "#FBFBFB"
    }
  },
  buttonEle: {
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    flex: '1 1 0px',
    textTransform: "capitalize" as const,
    width: "65%",
  },
  link: {
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    fontWeight: 700,
  },
  pagination: {
    marginTop: "3px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
  },
  uploadUsers: {
    width: "auto",
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    fontWeight: 700
  },
  EditButton: {
    borderRadius: "5px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#304a7c",
    fontWeight: 600,
    width: "65%"
  },
  table: {
    border: "1px solid #B2B2B2",
  },
  tableheader: {
    backgroundColor: "#ECF2FF"
  },
  createUser: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    fontWeight: 700,
    width: "20%"
  },
  
  mainBox: {
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    height: "auto",
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },
  cardHeader: {
    borderBottom: '1px solid #E7E7E7',
    padding: '0 40px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
  },
  
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    marginBottom: "10px",

  },
  edit: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "15px"
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  tabsurvey: {
    '&.MuiTab-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: ' 23px',
      color: ' #202020',
      opacity: ' 0.4',
      textTransform: 'capitalize' as const,
      minHeight: "100px",
      fontFamily: 'aeonik-regular',

    },
    '&.MuiTab-root.Mui-selected': {
      color: ' #202020',
      opacity: 1
    },
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px"
  },
  rectangleBody: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    borderRadius: '50%',
    width: '58px',
    height: '58px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px'
  },
  plusIcon: {
    width: '43.5px',
    height: '43.5px'
  },
  surveyText: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    color: 'rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)'
  },
  card: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: "space-between",
    minHeight: '240px',
    padding: '24px 16px',
    borderRadius: '28px' as const,
    border: '0.5px solid #DEDEDE',
    background: '#FCFCFC',
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    color: 'var(--text, #202020)',
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'center',
    border: "1px dashed #A7A7A7",
    height: '280px',
    borderRadius: '28px' as const,
    background: '#FCFCFC',
  },
  Respondents: {
    height: "22px",
    borderRedius: "5px",
    gap: "10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
    padding: "5px",
    color: "#304a7c"
  },
  cardHeaderName: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '16px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    color: "#121212",
  },
};

export default withStyles(styles)(UserLists);
// Customizable Area End
