Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.getSurveyListApiEndpoint = "/bx_block_surveys/surveys"
exports.getSurveyListApiMethod = "GET"
exports.getShortResponsesApiEndpoint = "/bx_block_surveys/surveys/short_text_questions"
exports.getLongResponsesApiEndpoint = "/bx_block_surveys/surveys/long_text_questions"
exports.getResponsesApiMethod = "GET"
exports.getSurveyDetailsApiEndpoint = "/bx_block_surveys/surveys/"
exports.getSurveyDetailsApiMethod = "GET"
exports.datetypeDetaile = "/bx_block_surveys/surveys/date_questions"

exports.postExportSurveyDetailsApiEndpoint = "/bx_block_surveys/surveys/export_dashboard"
exports.postExportSurveyDetailsApiMethod = "POST"
// Customizable Area End