import React, { FunctionComponent } from 'react';

import {
  Typography, styled,
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';

interface Item {
  id: number;
  question_type?: string;
  type?: string;
  title?: string;
  question?: string;
  answer?: string;
  required?: boolean;
  options?: any[];
  icon?: any;
}

interface SelectedItem {
  id: number | null,
  question_type?: string,
  question_title?: string,
  options?: any
  answer?: any
  required?: boolean
  minumum?: number
  maximum?: number
  ind?: number
}

interface Props {
  renderItems: Item[][];
  navigation:any;
  activeItem: number | null | undefined;
  selectedItem: SelectedItem | undefined;

  onItemClick: (data: any) => void;
  logOut:() => void;
}

const AdminSettingsSidebar: FunctionComponent<Props> = ({ renderItems, navigation, activeItem, selectedItem, onItemClick ,logOut}) => {

  const titles: any = {
    'header': 'Header',
    'short-text': 'Short answer',
    'long-text': 'Paragraph',
    'radio': 'MCQ',
    'checkbox': 'Checkbox',
    'slider': 'Slider',
  };

  return (
    <Sidebar>
      {renderItems?.map((group, i) => {
        return (
          <div key={i} style={{ ...webStyle.divider, ...(i === renderItems.length - 1 ? { border: 'none' } : {}) }}>
            {group.map((item: any, ind: number) => {
              const active = activeItem === item.id;
              const isSelected = selectedItem?.id === item.id;
              return (
                <div
                  style={webStyle.item}
                  key={`${ind}-${i}`}

                  onClick={() => {
                    if(item.id === 3){
                      logOut()
                    }else{
                      onItemClick(item)
                    }

                  }}
                >
                  <div
                    style={{
                      ...webStyle.imgContainer,
                      ...((active || isSelected )? { background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))' } : {})
                    }}>
                    <img style={{ ...webStyle.img, ...(active || isSelected ? { filter: 'brightness(0) invert(1)'} : {}) }} src={item.icon} alt="header" />
                  </div>
                  <Typography style={{
                    ...webStyle.text, ...((active || isSelected) ? {
                      fontWeight: 700,
                      color: 'black'
                    } : {})
                  }}>{titles[item?.question_type]}</Typography>
                    <Typography style={{
                    ...webStyle.text, ...((active || isSelected) ? {
                      fontWeight: 700,
                      color: '#202020'
                    } : {})
                  }}>{item.title}</Typography>
                  <div style={webStyle.spacer} />
                  <div
                    style={{
                      ...webStyle.active,
                      ...((active || isSelected) ?
                        { background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))' } : {}
                      )
                    }}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </Sidebar>

  )
};
const Sidebar = styled('div')({
  display: 'flex',
  flexDirection: 'column' as const,
  borderRadius: '12px',
  background: 'var(--basic-white, #FFF)',
  boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
  width: '17%',
  height: '100%',
  '@media (min-width: 100px) and (max-width:1000px)': {
    flexDirection:'row',
    width:'100%',
  },
  
})
const webStyle = {
  sidebar: {
    display: 'flex',
    flexDirection: 'column' as const,
    borderRadius: '12px',
    background: 'var(--basic-white, #FFF)',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    width: '17%',
    height: '100%',
  },
  divider: {
    padding: '16px 0px 16px 8px',
    borderBottom: '1px solid #DEDEDE'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    cursor: 'pointer'
  },
  imgContainer: {
    borderRadius: '8px',
    background: 'rgba(191, 202, 228, 0.30)',
    display: 'flex',
    width: '56px',
    height: '56px',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px'
  },
  img: {
    width: '24px',
    height: '24px'
  },
  text: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    color: 'rgba(121, 121, 121, 1)',
    fontFamily: 'aeonik-bold'
  },
  spacer: {
    display: 'flex',
    flex: '1 1 0px'
  },
  active: {
    width: '4px',
    borderRadius: '5px 0px 0px 5px',
    height: '56px',
  },
};

export default AdminSettingsSidebar;
