import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
// import { runEngine } from "../../framework/src/RunEngine";

// const method = {
//   GET: "GET",
//   POST: "POST",
//   PUT: "PUT",
//   DELETE: "DELETE",
//   PATCH: "PATCH",
// }

const getSurveyHeaderMessage = (configJSON: any) => {
  const headerSurvey = {
    "Content-Type": configJSON.validationApiContentType,
    token: window.localStorage.getItem(configJSON.token),
  };

  return JSON.stringify(headerSurvey);
}

export const buildRequestMessage = (method: any, configJSON: any) => {
  const requestMessageForSurvey = new Message(getName(MessageEnum.RestAPIRequestMessage));

  requestMessageForSurvey.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    getSurveyHeaderMessage(configJSON)
  );
  requestMessageForSurvey.addData(
    getName(MessageEnum.RestAPIRequestMessage),
    configJSON.APIBaseURL
  );
  requestMessageForSurvey.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method.toString()
  );

  return requestMessageForSurvey;
};

// const RequestMessage = (configJSON: any) => {
//   return ({
//     postRequest: buildRequestMessage(method.POST, configJSON),
//     getRequest: buildRequestMessage(method.GET, configJSON),
//     Null: undefined as any,
//   })
// };

// export const getRequest = (apiEndPoint: any, configJSON: any) => {
//   RequestMessage(configJSON).getRequest.addData(
//     getName(MessageEnum.RestAPIResponceEndPointMessage),
//     apiEndPoint
//   );

//   runEngine.sendMessage(RequestMessage(configJSON).getRequest.id, RequestMessage(configJSON).getRequest);
// };
