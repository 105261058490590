
const colorsArray = [
    { barColor: "#1C386D", bgColor: "#C6D4ED" },
    { barColor: "#20A84D", bgColor: "#CBFFDE" },
    { barColor: "#F6C328", bgColor: "#F8ECCA", textColor: "#000" },
    { barColor: "#C02F19", bgColor: "#FFE4E0" },
    { barColor: "#03C9D6", bgColor: "#BAF8FD", textColor: "#000" },
    { barColor: "#FF7A00", bgColor: "#FFD8B5" },
    { barColor: "#3E3BE4", bgColor: "#D6D6FF" },
    { barColor: "#EA3AD8", bgColor: "#FFC2F9" },
    { barColor: "#FD395C", bgColor: "#FFC5CF" },
    { barColor: "#800080", bgColor: "#FFD1FF" },
    { barColor: "#008080", bgColor: "#B0E2E2" },
    { barColor: "#00FF00", bgColor: "#BFFFBF", textColor: "#000" },
    { barColor: "#4B0082", bgColor: "#CBB5DB" },
    { barColor: "#800000", bgColor: "#EFB5B5" },
    { barColor: "#000080", bgColor: "#B1B1EB" },
    { barColor: "#808000", bgColor: "#E2E2AE" },
    { barColor: "#B6B6B6", bgColor: "#E2E2E2", textColor: "#000" },
    { barColor: "#FFD700", bgColor: "#F9F4DB", textColor: "#000" },
    { barColor: "#87CEEB", bgColor: "#D3E8F0", textColor: "#000" },
    { barColor: "#BABAFF", bgColor: "#E6E6FA", textColor: "#000" },
    { barColor: "#556B2F", bgColor: "#C3C6A1", textColor: "#000" },
    { barColor: "#DC143C", bgColor: "#F4B4B4" },
    { barColor: "#FF4500", bgColor: "#FFD3B6", textColor: "#000" },
    { barColor: "#4682B4", bgColor: "#C1DFF0" },
  ];
  
  function cryptoRandom() {
    const typedArray = new Uint8Array(1);
    const randomValue = window.crypto.getRandomValues(typedArray)[0];
    const randomFloat = randomValue / Math.pow(2, 8);
    return randomFloat;
  }
  
  const randomBetween = (min: number, max: number) => min + Math.floor(cryptoRandom() * (max - min + 1));
  
  const randomColor = () => {
    const r = randomBetween(0, 255);
    const g = randomBetween(0, 255);
    const b = randomBetween(0, 255);
    return { rgb: `rgb(${r},${g},${b})`, rgba: `rgb(${r},${g},${b},0.25)` };
  };
  
  export const getColors = (n: number) => {
    const colorsToSend = colorsArray.slice(0, n);
  
    if (n <= 20) {
      return colorsToSend;
    }
    for (let i = 21; i <= n; i++) {
      const { rgb, rgba } = randomColor();
      colorsToSend.push({ barColor: rgb, bgColor: rgba, textColor: "#000" });
    }
    return colorsToSend;
  };