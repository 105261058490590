import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Slide,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  MenuItem,
  Select,
  Dialog,
  TableBody,
  TableContainer,
  Table,
  DialogActions,
  DialogContent,
  TableRow,
  TableCell,
  Divider,
  Snackbar,
  IconButton,
  styled,
  withStyles,
  Slider,
  DialogTitle,
  Tab,
  Tabs,
  List, 
  ListItem, 
  ListItemText 
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AvatarEditor from 'react-avatar-editor';
import Alert from '@material-ui/lab/Alert';
import MyCheckbox from '../../../../packages/components/src/GradientCheckbox';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import {
  Edit
} from "./assets";
import TermsAndConditionDialog from "../../../components/src/TermsAndPrivacyDialog.web";
// Customizable Area End
import BasicSettingsController, {
  Props,
} from "./BasicSettingsController.web";

export default class BasicSettings extends BasicSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showButtonText = () => {
    if(this.state.Edit.id)
    {
      return 'Rename'
    }
    return 'Create'
  }
  getTitle = (selectedGroups: number, editId: null): string => {
    const titles = {
      1: editId ? "Edit industry sector" : "Add new industry sector",
      2: editId ? "Edit local authority" : "Add new local authority",
    } as { [key: number]: string };
  
    return titles[selectedGroups] || "";
  };
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Box style={{ width: "100%" }}>
        <Box style={webStyle.subBox}>
          <div style={{padding:'20px'}}>
          <Typography style={webStyle.Profile}>Edit profile</Typography>
          <div>
            <label htmlFor="image-upload" style={{ display: 'inline-block' }}>
              {this.state.profile.selectedImage ? (
                 <>
                <img
                  data-test-id="profile-image"
                  src={this.state.profile.selectedImage}
                  onClick={this.showProfileEditor}
                  alt="profile"
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    border: this.state.isImageValid ? 'none' : '1px solid red',
                  }}
                />
                 <button
                  style={{
                    display: 'block',
                    margin: '10px auto',
                    background: 'none',
                    border: 'none',
                    color: '#4B6EB0',
                    cursor: 'pointer',
                  }}
                  data-test-id="onClick"
                  onClick={(ev) => this.removeProfilePicture(ev)}
                >
                  Remove profile icon
                </button>
              </>
              ) : (
                <div
                  style={{
                    marginTop: "15px",
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    backgroundColor: '#ECEFF7',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '75px',
                    color: '#A2AFCD',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    fontWeight: 700,
                    fontFamily: 'aeonik-regular',
                    border: this.state.isImageValid ? 'none' : '1px solid red',
                  }}
                >
                  {this.state.initials}
                </div>
              )}
            </label>
            {!this.state.profile.selectedImage && (
              <input
                type="file"
                accept="image/*"
                id="image-upload"
                onChange={this.handleImageChange}
                style={{ display: 'none' }}
              />
            )}
          </div>
          <span style={{ color: "red", fontFamily: 'aeonik-regular', fontSize: "13px" }}>{this.state.errorMessage}</span>
          <Typography style={webStyle.supported} >5mb size or less, PNG/JPEG/GIF files only supported.</Typography>
          </div>
          <Box>
            <div style={{padding:'0px 20px'}}>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={6} >
                <InputLabel style={webStyle.lable} >First name</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="First-Name"
                  InputProps={{
                    style: { borderRadius: "10px", border: "1px solid #E7E7E7" }
                  }}
                  inputProps={{maxLength:64}}
                  value={this.state.profile.FirstName}
                  onChange={this.handleChange}
                  name="FirstName"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel style={webStyle.lable} >Last name</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="Last-Name"
                  InputProps={{
                    style: { borderRadius: "10px", border: "1px solid #E7E7E7" }
                  }}
                  inputProps={{maxLength:64}}
                  value={this.state.profile.LastName}
                  onChange={this.handleChange}
                  name="LastName"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={6}>
                <InputLabel style={webStyle.lable} >Email address</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="Email-Address"
                  InputProps={{
                    style: { borderRadius: "10px", border: "1px solid #E7E7E7",  backgroundImage: `linear-gradient(0deg, #F2F2F2, #F2F2F2),
                    linear-gradient(0deg, #FAFAFA, #FAFAFA)`, color: "rgba(130, 130, 130, 1)"},
                    readOnly: true,
                  }}
                  name="EmailAddress"
                  value={this.state.profile.EmailAddress}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            </div>
            <Divider style={{ marginTop: "30px" }} />
            <Grid container spacing={2} style={{ marginTop: "15px",padding:'0px 20px' }}>
              <Grid item xs={12}>
                <Box component="div" style={webStyle.lable}>Other details</Box>
              </Grid>
              <Grid item xs={12} sm={6} >
                <InputLabel style={webStyle.lable} >Company name</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="Company-Name"
                  InputProps={{
                    style: { borderRadius: "10px", border: "1px solid #E7E7E7" }
                  }}
                  inputProps={{maxLength:160}}
                  name="CompanyName"
                  value={this.state.profile.CompanyName}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <InputLabel style={webStyle.lable} >Industry sector</InputLabel>
                </Grid>
                <Select
                  fullWidth
                  variant="outlined"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={webStyle.select}
                  name="District"
                  data-test-id="District"
                  value={this.state.profile.District}
                  onChange={(event) => this.handleChange(event)}
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                {this.state.ShowCompaniesList.map((item:any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
             </Select>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "30px" }} /> 
            <Grid container spacing={2} style={{ marginTop: "15px",padding:'0px 20px' }}>
              <Grid item xs={12} style={{ display: "flex", columnGap:'1.5%',alignItems:'center' }}>
                <Typography style={{...webStyle.lable,marginBottom:'0px'}}>Additional user fields</Typography>
                <Button variant="outlined"
                  data-test-id="Edit1"
                  onClick={() => this.handleClickOpen()}
                  style={{ borderRadius: "10px", border: "1px solid #BFCAE4", color: "rgb(59 84 132)", fontFamily: 'aeonik-bold', fontSize: "14px",textTransform:'none' }}>Edit</Button>
              </Grid>
              <IndustrySector >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={this.state.districtlocal}
                    style={{ color: "#4B6EB0" }}
                    data-test-id="districtlocal"
                    onChange={(event) => this.handleChangeCheckbox(event, 'districtlocal')}
                  />
                  <Typography style={webStyle.additionalFields}>District/Local authority</Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center"}}>
                  <Checkbox
                    checked={this.state.industrySector}
                    style={{ color: "#4B6EB0" }}
                    data-test-id="industrySector"
                    onChange={(event) => this.handleChangeCheckbox(event, 'industrySector')}
                  />
                  <Typography style={webStyle.additionalFields}>Industry sector</Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center"}}>
                  <Checkbox
                    checked={this.state.companyCheck}
                    style={{ color: "#4B6EB0" }}
                    data-test-id="companyCheck"
                    onChange={(event) => this.handleChangeCheckbox(event, 'companyCheck')}
                  />
                  <Typography style={webStyle.additionalFields}>Company name</Typography>
                </Box>
              </IndustrySector>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "20px", display: "block" ,padding:'0px 20px 20px 20px'}}>
              <Grid item xs={6}>
                <Box component="div">
                <div data-test-id='like' onClick={this.handleOpenTermsAndConditionDialog} style={webStyle.Link}>Terms and conditions</div>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box component="div">
                  <div data-test-id='like1' onClick={this.handleOpenPrivacyPolicyDialog} style={webStyle.Link}>Privacy policy</div>
                </Box>
              </Grid>
            </Grid>
          </Box>            
            <Snackbar open={this.state.errorOccurred} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
              }}
              onClose={this.handleSnackbarClose}>
              <Alert
                severity="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleSnackbarClose}
                    style={{ zIndex: 10 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {this.state.errorMessages.map((errorMessage: any, index: any) => (
                  <div key={index}>{errorMessage}</div>
                ))}
              </Alert>
            </Snackbar>
          <Dialog
            open={this.state.openList}
            fullWidth
            PaperProps={{
              style: {
                borderRadius: '15px'
              }
            }}
          >
            <CustomTitle style={{textAlign: 'center'}}>Default list</CustomTitle>
            <Tabs
              data-test-id="tab-local-indus"
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <StyledTab label="Local authority" style={{ flexGrow: 1,maxWidth:'100%',fontFamily:'aeonik-regular',fontWeight:700 }} />
              <StyledTab label="Industry sector" style={{ flexGrow: 1,maxWidth:'100%',fontFamily:'aeonik-regular',fontWeight:700}} />
            </Tabs>
            <DialogContent dividers style={{padding:'0px'}}>
              <Box sx={{ padding: 2 }}>
                {this.state.tabValue === 0 && (
                  <div>
                    <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white',padding:'12px 24px',borderBottom:'1px solid #E7E7E7' }}>
                      <InputLabel style={webStyle.lable}>Select county</InputLabel>
                      <Select
                        data-test-id="country-select"
                        style={{ width: '100%', borderRadius: '10px' }}
                        variant="outlined"
                        multiple
                        onChange={this.handleCountryChange}
                        value={this.state.selectedCountries}
                        renderValue={(selected) => this.state.selectedCountries.join(', ')}
                        MenuProps={{
                          autoFocus:false,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: "50%",
                              marginTop: "5px",
                              borderRadius: "4px",
                              border: "1px solid #E6E6E6",
                            }
                          }
                        }}
                      >
                        {this.state.countriesList.map((country:any) => (
                          <StyledMenuItems key={country} value={country}>
                            <ListItemText primary={country} />
                            <Checkbox style={{color:'#4B6EB0'}} checked={this.state.selectedCountries.includes(country)} />
                          </StyledMenuItems>
                        ))}
                      </Select>
                    </div>
                    <List style={{ padding: '0px 24px' }}>
                      {this.state.districtsList.map((item: any, index: number) => (
                        <ListItem key={item.id}>
                          <ListItemText style={{maxWidth:'80%'}} primary={item.name} />
                          <div style={{ display: 'flex', columnGap: '18px' }}>
                            <MyCheckbox
                              id={`gradient-checkbox-dist-${index}`}
                              checked={this.state.districtCheckedItems.includes(item?.id)}
                              onChange={() => this.handleCheck(item.id)}
                            />
                            <img src={Edit} data-test-id={`Edit_data${index}`} onClick={() => this.handleEdit(item,'local')} style={{ cursor: 'pointer' }} />
                            <DeleteIcon onClick={() => this.deleteLocalAuthority(item.id)} htmlColor="#A6A6A6" style={{ cursor: 'pointer' }} />
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
                {this.state.tabValue === 1 && (
                  <List style={{padding:"24px"}}>
                    {this.state.ShowCompaniesList.map((item: any,index:number) => (
                      <ListItem key={item.id}>
                        <ListItemText style={{maxWidth:'80%'}} primary={item.name} />
                        <div style={{ display: 'flex', columnGap: '18px' }}>
                          <MyCheckbox 
                            id={`gradient-checkbox-indus-${index}`}
                            checked={this.state.industrycheckedItems.includes(item.id)}
                            onChange={() => {this.handleCheck(item.id)}}
                          />
                          <img src={Edit} data-test-id={`Edit_data${index}`} onClick={() => this.handleEdit(item,'industry')} style={{ cursor: 'pointer' }} />
                          <DeleteIcon onClick={() => this.deleteIndustrySector(item.id)} htmlColor="#A6A6A6" style={{ cursor: 'pointer' }} />
                        </div>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </DialogContent>
            <div style={{padding:'12px 24px'}}>
              <Button
                variant="outlined"
                data-test-id="Add-New"
                onClick={() => this.handleAddNew()}
                style={webStyle.addBtn}
              >Add new</Button>
            </div>
            <DialogActions style={{flexDirection:'column'}}>
              <Button
                data-test-id="save-btn"
                style={webStyle.edit}
                onClick={() => this.handleSave()}
              >
                Done
              </Button>
              <Button data-test-id="close-btn" style={webStyle.close} onClick={() => this.handleClose()}>Cancel</Button>
            </DialogActions>
          </Dialog>

          <AddNewModal aria-labelledby="customized-dialog-title" open={this.state.AddNew} 
            PaperProps={{
              style: {
                borderRadius: "16px",
                boxShadow: "none",
                position: 'absolute',
                top: "0px"
              },
            }}>
              <Box style={webStyle.surveyTital}>
                {this.getTitle(this.state.selectedGroups, this.state.Edit.id)} 
            </Box>
            <DialogContent>
              
              {this.state.selectedGroups === 1 ? (
                <>
                  {this.state.showDisabledFields && (
                    <>
                      <InputLabel style={webStyle.lable}>Old name</InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={this.state.oldIndustryName}
                        InputProps={{
                          style: { borderRadius: "10px", border: "1px solid #E7E7E7", marginBottom: '20px' }
                        }}
                      />
                    </>
                  )}
                  <InputLabel style={webStyle.lable}>Industry sector</InputLabel>
                  <TextField
                    name="newItemName"
                    onChange={this.handleNewItemNameChange}
                    variant="outlined"
                    fullWidth
                    value={this.state.Edit.newItemName}
                    InputProps={{
                      style: { borderRadius: "10px", border: "1px solid #E7E7E7" }
                    }}
                  />
                </>
              ) :
                (<>
                  {this.state.showDisabledFields && (
                    <>
                      <InputLabel style={webStyle.lable}>Old name</InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={this.state.oldDistrictName}
                        disabled={true}
                        InputProps={{
                          style: { borderRadius: "10px", border: "1px solid #E7E7E7", marginBottom: '20px' }
                        }}
                      />
                    </>
                  )}
                  <InputLabel style={webStyle.lable}>Local authority</InputLabel>
                  <TextField
                    data-test-id="Local-Authority"
                    name="newItemName"
                    onChange={this.handleNewItemNameChange}
                    variant="outlined"
                    fullWidth
                    value={this.state.Edit.newItemName}
                    InputProps={{
                      style: { borderRadius: "10px", border: "1px solid #E7E7E7" }
                    }}
                  />
                </>)
              }
            </DialogContent>
            <DialogActions>
              <Grid item xs={12}>
                <div style={webStyle.modalbtn}>
                  <Button
                    data-test-id="create-btn"
                    style={webStyle.edit}
                    onClick={() => this.createList()}
                  >
                    {this.showButtonText()}
                  </Button>
                  <Button data-test-id="cancel-btn" style={webStyle.close1} onClick={() => this.handleCloseAddNew()}>Cancel</Button>
                </div>
              </Grid>
            </DialogActions>
            <Snackbar open={this.state.errorOccurred} autoHideDuration={4000}
              TransitionComponent={Slide}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={this.handleSnackbarClose}>
              <Alert
                severity="error"
                style={{
                  borderRadius: '8px',
                  border: '1px solid #DEDEDE',
                  backgroundColor: 'white',
                  color: 'black',
                  width: 'auto', 
                  maxWidth: '400px', 
                  padding: '8px 16px', 
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap', 
                }}
                action={
                  <IconButton
                    size="small"
                    onClick={this.handleSnackbarClose}
                    style={{ zIndex: 10 }}
                    aria-label="close"
                    color="inherit"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {this.state.errorMessages.map((errorMessage: any, index: any) => (
                  <div key={index}>{errorMessage}</div>
                ))}
              </Alert>
            </Snackbar>
          </AddNewModal>
          <TermsAndConditionDialog
            title={this.renderTermsAndPrivacyData().title}
            data={this.renderTermsAndPrivacyData().data}
            visible={this.state.termsAndConditionDialogVisible}
            onClose={this.handleCloseTermsAndCondtionDioalg}
          />
        </Box >
        <BtnContainer>
          <div>
            <SaveChangesButton disabled={!this.state.isChanged}                         
            style={{   background: (this.state.isChanged) ? "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)" : "grey",color:'white'}}
                data-test-id="Save_Button" onClick={() => this.handleSaveChanges()} >Save changes</SaveChangesButton>
          </div>
        </BtnContainer>
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={this.state.snackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <Alert severity={this.state.severity} onClose={this.closeSnackbar}>
            {this.state.errors}
          </Alert>
        </Snackbar>
        <ProfileDialog open={this.state.profilEditor}>
          <ProfileTitle>Adjust position of photo</ProfileTitle>
          <DialogContent style={{ padding: '0px', background: '#eeeeee',height:"350px" }}>
            <AvatarWrapper>
              <div>
                <AvatarEditor
                  
                  crossOrigin="anonymous"
                  ref={this.state.editorRef}
                  image={this.state.fullProfileImage}
                  width={200}
                  height={200}
                  border={50}
                  borderRadius={125}
                  scale={this.state.zoomLevel}
                  color={[236, 236, 236, 0.6]}
                  rotate={0}
                  style={styles.editor}
                />
              </div>
            </AvatarWrapper>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#eeeeee', zIndex: -99, marginTop: '-15px' }}>
              <div style={{ width: '40%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <p style={{fontFamily:'aeonik-regular'}}>Zoom</p>
                  <p style={{fontFamily:'aeonik-regular'}}>{this.state.zoomLevel.toFixed(2)}</p>
                </div>
                <PrettoSlider
                  data-test-id="photo-zoom"
                  style={{ marginTop: '-20px' }}
                  value={this.state.zoomLevel}
                  min={1}
                  max={3}
                  step={0.01}
                  onChange={this.handleZoomChange}
                  aria-labelledby="zoom-slider"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', alignItems: 'center',padding:'12px 0px'}}>
            <MyButton data-test-id="profile-dialog-apply" variant="outlined" onClick={this.handleApplyProfileEditor}>
              Apply
            </MyButton>
            <MyButton data-test-id="profile-dialog-close" style={{background:'none',color:'#374f81'}} variant="outlined" onClick={this.handleProfileEditorClose}>
              Cancel
            </MyButton>
          </DialogActions>
        </ProfileDialog>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  editor: {
    margin: '0 auto',
  },
};
const StyledMenuItems = styled(MenuItem)({
  "&.Mui-selected": {
    backgroundColor: "transparent", 
    "&.Mui-focusVisible": {
      background: "transparent" 
    }
  },
  '&:last-child': {
    borderBottom: "none" 
  },
  paddingTop: '0px', 
  paddingBottom: '0px', 
});
const AvatarWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#eeeeee',
});
const ProfileDialog = styled(Dialog)({
  '& .MuiPaper-rounded': {
    borderRadius: '10px',
    width: 'calc(45% - 100px)',
  },
});

const ProfileTitle = styled(Typography)({
  padding: '20px',
  fontFamily: 'aeonik-bold',
});

const PrettoSlider = withStyles({
  root: {
    color: '#374f81',
    height: 4,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -4,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: '-30px',
    '& *': {
      background: 'white',
      color: '#000',
      transform: 'none',
      width: '80px',
      borderRadius: '4px',
      textAlign: 'center',
    },
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
    opacity: 1,
    backgroundColor: 'white',
  },
})(Slider);
const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    minWidth: 72,
    '&:hover': {
      color: '##3f51b5',
      opacity: 1,
    },
    '&$selected': {
      color: '##3f51b5',
      backgroundColor: '#ECF2FF',
    },
    '&:focus': {
      color: '##3f51b5',
    },
  },
  selected: {},
})(Tab);

const CustomTitle = styled(DialogTitle)({
  '& .MuiTypography-h6':{
    fontFamily:'aeonik-bold'
  }
})
const SaveChangesButton = styled(Button)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  borderRadius: "10px",
  padding: "18px 24px",
  display: "flex",
  alignItems: "center",
  color: "white",
  minWidth: '200px',
  fontWeight: 700,
  fontSize: "16px",
  border: 'none',
  userSelect: 'none' as const,
  cursor: 'pointer',
  justifyContent: 'center',
  fontFamily: 'aeonik-bold',
  textTransform:'none',
  '@media screen and (max-width:768px)':{
    padding:'12px 18px',
    fontSize:'14px',
    width:'60%',
  }
})
const MyButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  color: 'white',
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  padding: '6px 18px',
  fontFamily: 'aeonik-regular',
  fontWeight:600,
  fontSize: '1rem'
})
const IndustrySector = styled('div')({
  display:'flex',
  columnGap:'8px',
  // padding: "0px 10px",
  '@media (min-width: 100px) and (max-width:600px)': {
     display:'block',
  },
})
const BtnContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: "20px",
  '@media (min-width: 100px) and (max-width:600px)': {
    scale:'0.8',
    justifyContent:'center',
    margin:'auto',
    marginBottom:'20px'
 },
})
const AddNewModal = styled(Dialog)({
  '& .MuiPaper-root': {
    '@media (max-width: 1920px)': {
      width:'40%',
    },
    '@media (max-width: 1000px)': {
      width:'60%',
    },
    '@media (max-width: 600px)': {
      width:'70%',
    },
  },
  '& .MuiDialog-paper': {
  },
})
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  additionalFields: {
    fontFamily: 'aeonik-regular',
  },
  initials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor: '#ECEFF7',
    borderRadius: '50%',
    color: '#A2AFCD',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textAlign: 'center',
    padding: '8px',
    marginRight: '40px'
  },
  primaryBtn: {
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    padding: '18px 32px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: "none" as const,
    fontFamily: 'aeonik-bold',
  },
  addBtn: {
    borderRadius: "10px",
    border: "1px solid #BFCAE4",
    color: "rgb(59 84 132)",
    fontFamily: 'aeonik-regular',
    fontSize: "14px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: "flex-start",
    marginLeft: "15px",
    textTransform:'none' as const,
  },
  subBox: {
    border: '1px solid #E7E7E7',
    margin: '20px',
    height: "auto",
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    // width: "95%",
    // padding: "20px",
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },
  Profile: {
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    fontSize: "16px",
  },
  close: {
    padding: '15px 60px',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    color: "#1C386D",
    marginLeft:'0px',
    background:"linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    "-webkit-background-clip": "text",
  },
  edit: {
    padding: '15px 60px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  close1: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    background:"linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    "-webkit-background-clip": "text",
    color: "transparent",
  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-regular',
    fontSize: "20px",
    fontWeight: 700
  },
  
  Link: {
    display:'inline-block',
    color: "#4B6EB0", fontFamily: 'aeonik-regular', fontSize: "16px", fontWeight: 700,
    cursor: "pointer" as const,
    textDecoration:'underline'
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "15px"
  },
  supported: {
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    fontSize: "16px",
    marginTop: "10px"
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    marginBottom: "10px"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  Tital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "15px",
    justifyContent: "center",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: "20px"
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px"
  },
};
// Customizable Area End
