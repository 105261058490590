// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  scrollVisible: boolean;
  termsAndConditionDialogVisible: boolean;
  isTermsAndCondition: boolean;
  termsAndConditon: string;
  privacyPolicy: string;
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  public RequestMessage = {
    getTermsConditions: this.buildRequestMessage(configJSON.getApiMethodType),
    getPrivacyPolicy: this.buildRequestMessage(configJSON.getApiMethodType),
    Null: undefined as any,
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      scrollVisible: false,
      termsAndConditionDialogVisible: false,
      isTermsAndCondition: false,
      termsAndConditon: "",
      privacyPolicy: ""
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (callID) {
      case this.RequestMessage.getTermsConditions.messageId:
        this.handleTermsAndConditionResponse(response);
        break;
      case this.RequestMessage.getPrivacyPolicy.messageId:
        this.handlePrivacyPolicyResponse(response);
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start


  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  private buildRequestMessage(method: any): Message {
    const requestMessageForSurvey = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessageForSurvey.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );

    requestMessageForSurvey.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessageForSurvey;
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
    await Promise.all([
      this.getTermsConditons(),
      this.getPrivacyPolicy(),
    ])
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
}

  renderTitle = () => {
    return 'Finally, an in-app survey tool both you and your users will love';
  }

  renderDescription = () => {
    return 'You’ll love it for the advanced features to understand, and build for your users. They’ll love it for the simple and delightful experience.';
  }

  renderBtntext = () => {
    return 'Get started';
  }

  navigateToAuth = () => {
    this.props.navigation.navigate("AuthBlock");
  }

  handleScroll = () => {
    // Check if the user has scrolled down at least 100 pixels to display the button.
    if (window.scrollY > 100) {
      this.setState({ scrollVisible: true });
    } else {
      this.setState({ scrollVisible: false });
    }
  };

  scrollToTop = () => {
    // Scroll to the top of the page when the button is clicked.
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleGetStarted = () => {
    this.props.navigation.navigate('AuthBlock');
  }

  renderTermsAndPrivacyData = () => {
    if(this.state.isTermsAndCondition) {
      return {
        title: 'Terms and conditions',
        data: { description: this.state.termsAndConditon }
      };
    } else {
      return {
        title: 'Privacy policy',
        data: { description: this.state.privacyPolicy }
      };
    }
  }

  getPrivacyPolicy = () => {
    this.RequestMessage.getPrivacyPolicy.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );

    runEngine.sendMessage(this.RequestMessage.getPrivacyPolicy.id, this.RequestMessage.getPrivacyPolicy);
  };

  getTermsConditons = () => {
    this.RequestMessage.getTermsConditions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsConditions
    );

    runEngine.sendMessage(this.RequestMessage.getTermsConditions.id, this.RequestMessage.getTermsConditions);
  };

  handlePrivacyPolicyResponse = (response: any) => {
    if(response?.description) {
      this.setState({ privacyPolicy: response.description });
    }
  }

  handleTermsAndConditionResponse = (response: any) => {
    if(response?.description) {
      this.setState({ termsAndConditon: response.description });
    }
  }

  handleOpenPrivacyPolicyDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndCondition: false });
  }

  handleOpenTermsAndConditionDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndCondition: true });
  }

  handleCloseTermsAndCondtionDioalg = () => {
    this.setState({ termsAndConditionDialogVisible: false });
  }
  
  // Customizable Area End
}

// Customizable Area End