import React, { FunctionComponent } from 'react'
import Dialog from './Dialog.web';
import Input from './CustomInput.web';

import {
    Button, styled,
} from "@material-ui/core";

interface Props {
    title?: string;
    description?: string;
    visible: boolean;
    password: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClose: () => void;
    onDeleteClick: () => void;
}

const DeleteUserAccountDialog: FunctionComponent<Props> = ({ visible, password, onChange, handleClose, onDeleteClick }) => {
  return (
    <StyledDialog visible={visible}>
        <StyledContainer>
            <div>
                <StyledModalHeader >Delete account</StyledModalHeader>
            </div>
            <div>
                <StyledText>
                    Are you sure you want to delete your account? This action cannot be undone.
                </StyledText    >
            </div>
            <InputWrapper>
                <StyledInput 
                    autoComplete='new-password'
                    label='Enter your password'
                    type="password"
                    placeholder="Enter your password"
                    style={{ marginTop: '20px' }}
                    value={password}
                    onChange={onChange}
                />
            </InputWrapper>
            <StyledActions >
                <StyledButtonPrimary  onClick={onDeleteClick} >Delete account</StyledButtonPrimary >
                <StyledButton  onClick={() => handleClose()} >Close</StyledButton >
            </StyledActions>
        </StyledContainer>
    </StyledDialog>
  )
}

const webStyle = {
    deleteAccountmodal: {
        borderRadius: '16px',
    },
    container: {
        width: '540px',
        padding: '40px',
        borderRadius: '16px',
        background: '#FFF',
    },
    modalHeader: {
        fontSize: '18px',
        color: 'black',
        fontWeight: 700,
        lineHeight: '20px',
        fontFamily: 'aeonik-regular',
        textAlign: 'center' as const,
    },
    actions: {
        flexDirection: 'column' as const,
        alignItems: 'center',
        display: 'flex',
    },
    buttonPrimary: {
        alignItems: 'center',
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        padding: '18px 32px',
        fontStyle: 'normal',
        justifyContent: 'center',
        fontSize: '16px',
        borderRadius: '10px',
        color: 'rgba(255, 255, 255, 1)',
        lineHeight: 'normal',
        flex: '1 1 0px',
        fontWeight: 700,
        textTransform: 'none' as const,
        marginTop: '30px',
    },
    btn: {
        fontSize: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '18px 32px',
        border: 'none',
        background: 'none',
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: 'normal',
        flex: '1 1 0px',
        color: '#333',
        textTransform: 'none' as const
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#202020',
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: 'aeonik-regular',
    },
};
const InputWrapper = styled('div')({
    '@media screen and (max-width:500px)':{
        
        marginRight:'40px',
    }
})
const StyledInput = styled(Input)({
    '@media screen and (max-width:500px)':{
        scale:"0.9",
        // marginRight:'40px',
        
    }
    
})
const StyledDialog = styled(Dialog)({
    borderRadius:'16px',
    '@media screen and (max-width:500px)':{

    }
})
const StyledContainer = styled('div')({
    width: '540px',
    padding: '40px',
    borderRadius: '16px',
    background: '#FFF',
    '@media screen and (max-width:500px)': {
        width: '100%', // Full width
        maxWidth: '540px', // Limit maximum width for readability
        padding: '20px', // Reduce padding for smaller screens
    }
  });
  
  const StyledModalHeader = styled('p')({
    fontSize: '18px',
    color: 'black',
    fontWeight: 700,
    lineHeight: '20px',
    fontFamily: 'aeonik-regular',
    textAlign: 'center',
    textTransform:'none',
    '@media screen and (max-width:500px)':{
        marginRight:'40px',
    }
  });
  
  const StyledText = styled('p')({
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#202020',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'aeonik-regular',
    '@media screen and (max-width:500px)':{
        width:'90%',
    }
  });
  
  const StyledActions = styled('div')({
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  });
  
  const StyledButtonPrimary = styled(Button)({
    alignItems: 'center',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    padding: '18px 32px',
    fontStyle: 'normal',
    justifyContent: 'center',
    fontSize: '16px',
    borderRadius: '10px',
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    flex: '1 1 0px',
    fontWeight: 700,
    textTransform: 'none',
    marginTop: '30px',
    '@media screen and (max-width:500px)':{
        scale:'0.8',
        marginRight:'40px'
    }
  });
  
  const StyledButton = styled(Button)({
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '18px 32px',
    border: 'none',
    background: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 'normal',
    flex: '1 1 0px',
    color: '#333',
    textTransform: 'none',
    '@media screen and (max-width:500px)':{
        scale:'0.8',
        marginRight:'40px'
    }
  });

export default DeleteUserAccountDialog;
