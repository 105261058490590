// Customizable Area Start
import React from "react";
export const configJSON = require("./config");

import LandingPageController, {
    Props,
} from "./LandingPageController.web";

import { withStyles } from "@material-ui/core/styles";
import {
    landingImage1,
    platformImage,
    analyticsImage,
    applestoreImage,
    computerImage,
    googleplayImage,
    keyboardImage,
    tabletImage,
    glassesImage,
    card1Image,
    card2Image,
    lampImage,
    lamp2Svg,
    appLogo,
    upArrow
} from "./assets";
import TermsAndConditionDialog from "../../../components/src/TermsAndPrivacyDialog.web";

export class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        const listItems = [
            {
                header: <div>Give your users a clean, <br /> on-brand interface</div>,
                text: <div>Allow your users to tell you what they think-in <br /> away that feels native to your app experience.</div>
            },
            {
                header: <div>Use data logic to design the <br /> user flow</div>,
                text: <div>Save answers as custom user properties, and set <br /> logic jumps to different questions</div>
            },
            {
                header: <div>Control when and where <br /> to ask</div>,
                text: <div>Automate feedback collection in key moments <br /> of the user journey by setting up event triggers <br /> or time on page rules.</div>
            },
            {
                header: <div>Target specific user <br /> cohorts</div>,
                text: <div>Set audience rules to dynamically select who <br /> should see your survey based on their attributes.</div>
            },
            {
                header: <div>Start and stop as <br /> you wish</div>,
                text: <div>Preset the frequency and timing for each survey <br /> so you can sit back and relax while insights are <br /> collected at the pace you want.</div>
            },
            {
                header: <div>Dig deeper at the click of <br /> a button</div>,
                text: <div>With our data reports, you can view real-time <br /> stats without writing queries. One-click filtering <br /> allows you to take a deep dive into any user .</div>
            },
            {
                header: <div>Works across web and <br /> mobile</div>,
                text: <div>Seamlessly capture user insights across all <br /> platforms your app is on</div>
            }
        ];

        return (
            <div className={classes.pageContainer}>
                <div className={classes.header}>
                    <img className={classes.logo} src={appLogo} alt="appLogo" />
                    <div>
                        <button data-test-id='auth-btn2' onClick={this.navigateToAuth} className={`${classes.authBtn} ${classes.authBtnGap}`}>Sign up</button>
                        <button data-test-id='auth-btn1' onClick={this.navigateToAuth}  className={classes.authBtn}>Log in</button>
                    </div>
                </div>

                <div className={classes.bannerSection}>
                    <div className={classes.bannerText}>
                        <p className={`${classes.textXXL} ${classes.textGap} ${classes.responsive}`}>{this.renderTitle()}</p>
                        <p className={classes.textS}>{this.renderDescription()}</p>
                    </div>
                    <img className={classes.bannerImage} src={landingImage1} />
                </div>

                <div>
                    <div className={classes.section2Header}>
                        <p className={`${classes.textXXL} ${classes.textGap}`}>Make smarter product and <br /> marketing decisions</p>
                        <p className={classes.textS}>We make user insights easy. And fast. But don't just take our word <br /> for it. We'll let the product do the talking.</p>
                    </div>
                    <div className={classes.itemContainer}>
                        {
                            listItems.map((item, i) => {
                                return (
                                    <div key={i} className={classes.item}>
                                        <p className={`${classes.textL} ${classes.textGapS }`}>{item.header}</p>
                                        <p className={classes.textXS}>{item.text}</p>
                                    </div>
                                )
                            })
                        }
                        <div>
                            <img className={classes.lamp2} src={lamp2Svg} alt="lamp2Svg" />
                        </div>
                    </div>
                </div>
                <div className={classes.section3}>
                    <img className={classes.section3Image} src={platformImage} />
                    <p className={`${classes.textXXL} ${classes.textGapL} ${classes.textCenter}`}>Available on your favourite platforms</p>
                    <div className={classes.market}>
                        <a className={classes.btn} href="https://play.google.com/store/apps/details?id=com.EMCsurveyappBC10&pli=1" rel="noopener noreferrer" target="_blank"><img src={googleplayImage} alt="googleplayImage" /></a>
                        <a className={classes.btn} href="https://apps.apple.com/us/app/panelme/id6472812305" rel="noopener noreferrer" target="_blank"><img src={applestoreImage} alt="applestoreImage" /></a>                    
                    </div>
                </div>
                <div className={classes.section4}>
                    <div className={classes.secHeader4}>
                        <p className={`${classes.textXL} ${classes.textGapS}`}>Set up in 15 minutes or less</p>
                        <p className={classes.textS}>
                            Understanding your users shouldn't feel like an uphill battle. Set up PanelMe in your app, and your <br />
                            users will thank you for caring enough to ask. It's the user insight tool both you and your users will love.
                        </p>
                    </div>
                    <div className={classes.imageContainer}>
                        <img className={classes.imgItem} src={computerImage} alt="computerImage" />
                        <img className={classes.imgItem} src={glassesImage} alt="glassesImage" />
                        <img className={classes.imgItem} src={tabletImage} alt="tabletImage" />
                        <img className={classes.imgItem} src={analyticsImage} alt="analyticsImage" />
                        <img className={classes.imgItem} src={keyboardImage} alt="keyboardImage" />
                    </div>
                </div>
                <div className={classes.section5}>
                    <div className={classes.section5LeftBlock}>
                        <img className={classes.card2} src={card2Image} alt="card2" />
                        <img className={classes.card1} src={card1Image} alt="card1" />
                    </div>
                    <div className={classes.section5RightBlock}>
                        <div className={classes.section5TextBlock}>
                            <p className={`${classes.section5text} ${classes.textGap}`}>Start collecting insights <br /> in a few minutes</p>
                            <button onClick={this.handleGetStarted} data-test-id='get-started-btn' className={classes.getStartedBtn}>{this.renderBtntext()}</button>
                        </div>
                        <div>
                            <img className={classes.lamb} src={lampImage} alt="lamb" />
                        </div>
                    </div>
                </div>
                {this.state.scrollVisible &&
                    <button data-test-id='scroll-btn' className={classes.scrollBtn} onClick={this.scrollToTop} id="myBtn">
                        <img style={{ height: '36px' }} src={upArrow} alt="up" />
                    </button>
                }
                <footer className={classes.footerContainer}>
                    <div className={classes.leftPanel}>
                        <img className={classes.logo} src={appLogo} alt="appLogo" />
                        <p>Friars Mill, 1 Bath Lane Mill, Bath Lane, Leicester LE3 5BJ</p>
                        <p><span style={{ marginRight: "16px" }}>Contact: 0333 320 0333</span> <span className={classes.email}>Email: policy@emc-dnl.co.uk</span></p>
                    </div>

                    <div className={classes.linkContainer}>
                        <a href="https://www.insight-unlocked.co.uk/about-us.html" rel="noopener noreferrer" target="_blank">About us</a>
                        <a href="https://www.insight-unlocked.co.uk/about-us.html#contact-us" rel="noopener noreferrer" target="_blank">Contact us</a>
                        <button onClick={this.handleOpenTermsAndConditionDialog}>Terms and conditions</button>
                        <button onClick={this.handleOpenPrivacyPolicyDialog}>Privacy policy</button>
                    </div>
                </footer>
                <TermsAndConditionDialog
                    title={this.renderTermsAndPrivacyData()?.title}
                    data={this.renderTermsAndPrivacyData()?.data}
                    visible={this.state.termsAndConditionDialogVisible}
                    onClose={this.handleCloseTermsAndCondtionDioalg}
                />
            </div>
        );
    }
}

// Customizable Area Start
const styles = {
    pageContainer: {
        background: '#FFFFFF',
        width: '100%',
        // backgroundColor:'pink',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '18px 40px',
        position: 'absolute' as const,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1
    },
    logo: {
        height: '34px'
    },
    authBtn: {
        width: '108px',
        height: '45px',
        borderRadius: '8px',
        border: '1px solid #1C386D',
        fontFamily: 'aeonik-regular',
        background: 'none',
        padding: '16px 20px',
        fontSize: '14px',
        fontWeight: 600,
        letterspacing: '-0.28px',
        cursor: 'pointer',
        lineHeight: 0,
        color: '#1C386D',
    },
    authBtnGap: {
        marginRight: '16px'
    },
    bannerSection: {
        display: 'flex',
        minHeight: '600px',
        padding: '20px 20px 20px 80px',
        "@media (max-width: 1220px)": {
            minHeight: '400px',
            // backgroundColor:'red',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            
        },
        '@media (max-width:500px)':{
            padding:'0px',
        }

    },
    bannerText: {
        maxWidth: '620px',
        zIndex: 1,
        marginTop: '80px',
        "@media (max-width: 1220px)": {
            maxWidth: '570px',
            margin: '20px',
            marginTop: '80px',
          },
          "@media (max-width: 600px)": {
            maxWidth: '570px',
            margin: '20px',
            marginTop: '380px',
            display:'flex',
            justifyContent:'center',
            flexDirection:'column',
            // backgroundColor:'pink'
          },
          '@media (min-width:600px) and (max-width:800px)':{
            marginTop:'305px',
          },
          '@media (min-width:806) and (max-width:900px)':{
            marginTop:'305px',
          }
          
    },
    textGap: {
        marginBottom: '36px',

    },
    textGapL: {
        marginBottom: '24px'
    },
    textGapS: {
        marginBottom: '16px'
    },
    responsive:{
        '@media (min-width: 800px) and (max-width: 902px)':{
            fontSize:'38px',
            width:'240px',
        },
        '@media (min-width: 901px) and (max-width: 1001px)':{
            fontSize:'40px',
            width:'290px',
            
        },
        '@media (min-width: 1001px) and (max-width: 1150px)':{
            fontSize:'40px',
            width:'420px',
        }
    },
    textXXL: {
        fontFamily: 'aeonik-regular',
        fontSize: '56px',
        fontWeight: 600,
        lineHeight: '70px',
        letterSpacing: '-0.04em',
        background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        "@media (max-width: 1220px)": {
            fontSize: '40px',
            lineHeight: '48px',
            
        },
        "@media (max-width: 500px)": {
            fontSize: '40px',
            lineHeight: '48px',
            textAlign:'center',
        }
    },
    textXL: {
        fontSize: '56px',
        fontWeight: 600,
        lineHeight: '90px',
        letterSpacing: '-2.24px',
        background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontFamily: 'aeonik-regular',
        '@media (max-width:500px)':{
            lineHeight: '50px',
            textAlign:'center',
            fontSize:'45px',
        }
    },
    textL: {
        fontSize: '24px',
        fontFamily: 'aeonik-regular',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '-0.48px',
        background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textAlign: 'center' as const
    },
    textS: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '-0.02em',
        color: '#202020',
        fontFamily: 'aeonik-regular',
        '@media (max-width:500px)':{
            textAlign:'center',
        }
    },
    textXS: {
        color: 'var(--text, #202020)',
        textAlign: 'center' as const,
        fontSize: '16px',
        fontFamily: 'aeonik-regular',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.32px',
        alignItems: 'center'
        
    },
    bannerImage: {
        height: '526px',
        position: 'absolute' as const,
        right: '0px',
        top: '0px',
        zIndex: 0,
        "@media (max-width: 1220px)": {
            height: '400px',
        },
    },
    section2Header: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        textAlign: 'center' as const,
        marginBottom: '60px',
        "@media (max-width: 1220px)": {
            marginBottom: '24px',
        },
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center',
        position: 'relative' as const
    },
    item: {
        width: 'calc(33.33% - 160px)',
        margin: '32px',
        "@media (max-width: 1220px)": {
            width: 'calc(50% - 135px)',
            margin: '24px',
        },
        "@media (max-width: 540px)": {
            // width: 'calc(100% - 192px)',
            margin: '0px',
            width:'100%',
        },
    },
    section3: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center'
    },
    market: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width:500px)':{
            scale:'0.8'
        }
    },
    section4: {
        margin: '80px',
        '@media (max-width:500px)':{
            margin:'15px',
        }
    },
    secHeader4: {
        marginBottom: '60px',
        display: 'flex',
        flexDirection: 'column' as const
    },
    btn: {
        outline: 'none',
        border: 'none',
        background: 'none',
        padding: '0px',
        margin: '12px',
        cursor: 'pointer'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media (max-width:500px)':{
            justifyContent:'space-around',
            alignItems:'end'
        }
    },
    imgItem: {
        margin: '12px',
        height: 'auto',
        width: 'calc(16.6% - 21px)',
        heigth: 'auto',

        '&:(:last-child)': {
            marginRight: '0px',
        },
        '&:nth-child(3)': {
            width: 'calc(32% - 22px)'
        },
        '@media (max-width:500px)': {
            margin:'0px 5px',
            width:'45px',
            '&:(:last-child)': {
                marginRight: '0px',
            },
            '&:nth-child(3)': {
                width: '60px',
            },
        }
    },
    section5: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '710px',
        opacity: '0.8999999761581421',
        background: 'radial-gradient(235.01% 57.31% at 13.47% 117.79%, #274275 41.67%, #11134B 100%)',
        '@media (max-width:500px)':{
            flexDirection:'column',
        },
        '@media (min-width: 601px) and (max-width: 930px)':{
            flexDirection:'column',

        },
        '@media (min-width: 501px) and (max-width: 600px)':{
            flexDirection:'column',

        }
    },
    section5LeftBlock: {
        display: 'flex',
        width: '40%',
        '@media (max-width:500px)':{
            width:'100%',
            justifyContent:'space-between',
            marginTop:'40px'
        },
        '@media (min-width: 601px) and (max-width: 930px)':{
            width:'100%',
            justifyContent:'space-between',
        },
        '@media (min-width: 501px) and (max-width: 600px)':{
            width:'100%',
            justifyContent:'space-between',
            
        }
    },
    section5RightBlock: {
        display: 'flex',
        flexDirection: 'column' as const,
        flex: '1 1 0px',
        position: 'relative' as const,
        height: '100%',
        justifyContent: 'center',
        '@media (max-width:500px)':{

        },
        '@media (min-width: 601px) and (max-width: 930px)':{
            alignItems:'center',
            marginTop:'-600px',
            flexDirection: 'column' as const,
            justifyContent: 'center',
            marginRight:'100px',
        }
    },
    section5TextBlock: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-end',
        marginRight: '80px',
        '@media (max-width:500px)':{
            marginRight:'0px',
        },
        '@media (min-width: 760px) and (max-width: 930px)':{
            alignItems: 'center',
            justifyContent:'center',
            marginRight:'0px',
            width:'300px',
        },
        '@media (min-width: 600px) and (max-width: 760px)':{
            alignItems: 'center',
            justifyContent:'center',
            marginRight:'0px',
            width:'128px',
        },
        '@media (min-width: 501px) and (max-width: 630px)':{
            flexDirection:'row',
            alignItems:'flex-end',
            marginTop:'-45px',
            marginRight:'0px',
        }

    },
    section5text: {
        color: 'var(--background, #FBFBFB)',
        fontSize: '56px',
        fontWeight: 500,
        lineHeight: '90px',
        letterSpacing: '-2.24px',
        fontFamily: 'aeonik-regular',
        "@media (max-width: 1220px)": {
            fontSize: '40px',
            lineHeight: '55px',
        },
        "@media (max-width: 500px)": {
            // fontSize: '30px',
            // lineHeight: '28px',
            // width:'146%',
            // marginTop:'530px',
            padding:'5px',
            textAlign:'center',
            margin:'auto',
        }
    },
    getStartedBtn: {
        borderRadius: '10px',
        border: '1px solid var(--frame, #FFF)',
        fontFamily: 'aeonik-regular',
        fontSize: '16px',
        fontWeight: 700,
        color: '#FFFFFF',
        background: 'transparent',
        padding: '16px 20px',
        width: 'fit-content',
        cursor: 'pointer',
        "@media (max-width: 500px)": {
            // position:'relative',
            // right:'50px',
            margin:'auto',
        },
        "@media (max-width: 1150px)": {
            // position:'relative',
            // right:'50px',
            margin:'auto',
            zIndex:'2',
        },

    },
    lamb: {
        opacity: 0.1,
        position: 'absolute' as const,
        bottom: '0px',
        '@media (max-width:500px)':{
            left:'-100px',
            scale:'0.5',
            bottom:'28px',
            zIndex:'-1',
        },
        '@media (min-width: 500px) and (max-width: 599px)':{
            scale:'0.6',
            left:'-170px',
            zIndex:'-1',
        },
        '@media (min-width: 600px) and (max-width: 800px)':{
            scale:'0.8',
            left:'-150px',
            zIndex:'-1',
        },
        '@media (min-width: 801px) and (max-width: 950px)':{
            scale:'0.8',
            left:'-150px',
            zIndex:'-1',
        },
        // '@media (min-width: 1001px) and (max-width: 1150px)':{
        //     fontSize:'40px',
        //     width:'420px',
        // }
    },
    card2: {
        marginRight: '30px',
        "@media (max-width: 500px)": {
            width:'30%',
            height:'100%',
        }
    },
    card1: {
        "@media (max-width: 500px)": {
            width:'50%',
            height:'100%',
        }
    },
    email:{
        "@media (max-width: 1220px)": {
            display:'inline-block',
        }
    },
    lamp2: {
        position: 'absolute' as const,
        bottom: '0px',
        left: '50px',
        opacity: '0.1',
        maxWidth: '100%',
        '@media (max-width:600px)':{
            scale:'0.7',
            left:'0px',
        }
    },
    section3Image: {
        maxWidth: '80%'
    },
    textCenter: {
        textAlign: 'center' as const
    },
    scrollBtn: {
        position: 'fixed' as const,
        bottom: '20px',
        right: '30px',
        zIndex: 99,
        fontSize: '18px',
        border: 'none',
        outline: 'none',
        backgroundColor: '#BFCAE4',
        color: 'white',
        cursor: 'pointer',
        padding: '15px',
        borderRadius: '50%',
        "@media (max-width: 500px)": {
            position: 'fixed' as const,
            bottom: '20px',
            right: '20px',
            // left:'40%',
            zIndex: 99,
            fontSize: '18px',
            border: 'none',
            outline: 'none',
            backgroundColor: '#BFCAE4',
            color: 'white',
            cursor: 'pointer',
            padding: '15px',
            borderRadius: '50%',
            scale: '0.8',
        }
    },
    footerContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "40px 40px 80px 40px",
        "@media (max-width: 1070px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "unset"
        },
        fontFamily: "aeonik-regular",
        fontSize: "16px",
        fontWeight: 500,
        color: "black",
    },
    linkContainer: {
        display: "flex",
        alignItems: "center",
        "@media (max-width: 1070px)": {
            flexDirection: "column",
        },
        "& a, & button": {
            fontFamily: "aeonik-regular",
            fontSize: "16px",
            fontWeight: 500,
            color: "black",
            marginRight: "24px"
        },
        "& button": {
            background: "none",
            color: "inherit",
            border: "none",
            padding: 0,
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
        },
        "& a": {
            textDecoration: "none"
        },
    },
    leftPanel: {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "flex-start",
        "@media (max-width: 1070px)": {
            marginTop: "16px",
            alignItems: "center"
        },
        "& p": {
            margin: "16 0 0 0"
        }
    }
};

export default withStyles(styles)(LandingPage);
// Customizable Area End 