import React, { FunctionComponent } from "react";
import { Box, Typography, styled } from "@material-ui/core";
import SurveyChartWeb from "./SurveyChart.web";
const PrintLogo = require('./PrintLogo.png');

interface OverviewData {
  title: string;
  duration: number;
  respondents: number;
  group_names: string[];
  graph_details: any[];
}

interface Props {
  surveyNumber: number;
  overviewData: OverviewData;
}

const PrintOuter: FunctionComponent<Props> = ({
  surveyNumber,
  overviewData
}) => {


  const convertDuration = (durationInSeconds: number) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
  
    if (minutes === 0) {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  
    if (seconds === 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }
  
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
  };
  
  const formattedDuration = convertDuration(overviewData.duration);

  return (
    <>
        <OuterContainer1>
                <Header1>
                  <img
                    style={{ width: '80px', height: '80px', marginBottom: '24px' }}
                    src={PrintLogo}
                    alt="Print Logo"
                  />
                    <HeadingSurvey
                    variant="h5"
                    style={{
                      color: "#1C386D",
                      fontWeight: 1000,
                      fontFamily: "sans-serif",
                      fontSize: "1.9rem",
                      margin: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    {overviewData.title}
                  </HeadingSurvey>
                </Header1>
                <Content>
                 <Detail>Duration of Survey - {formattedDuration}</Detail>
                  <Detail>Total number of respondents - {overviewData.respondents} Respondents</Detail>
                  <Detail>Assigned Group - {overviewData.group_names.join(", ")}</Detail>
                </Content>
                <ChartContainer>
                  <div>
                    <Typography component="h4" style={{ fontSize: "18px" ,paddingLeft:'38px',fontWeight:600,paddingBottom:"20px"}}>
                      Number of Respondents by Date
                    </Typography>
                    <Typography component="h4"  display="inline"  style={{ fontSize: "14px" ,paddingLeft:'62px',textAlign:"left",fontWeight:600}}>
                      <Circle />
                      Survey {surveyNumber}
                    </Typography>
                    <SurveyChartWeb graphData={overviewData.graph_details} />
                  </div>
                  </ChartContainer>
              </OuterContainer1>
    </>
  );

};


const OuterContainer1 = styled(Box)({
  width: "100%",
  backgroundColor: "#FFFFFF",
});

const Header1 = styled(Box)({
  textAlign: "center",
  padding: "40px 0",
});

const HeadingSurvey = styled(Typography)({
  fontFamily: "aeonik-regular",
  fontWeight: 700,
  whiteSpace: 'nowrap' as const, 
  fontSize: "24px",
  color: "#1C386D",
  marginTop: "16px",
});

const Content = styled(Box)({
  padding: "0 32px 32px",
  textAlign: "center",
});

const Detail = styled(Typography)({
  fontFamily: "aeonik-regular",
  fontWeight: 600,
  color: "#202020",
  lineHeight: "32px",
  fontSize: "16px",
  display: "block",
});

const ChartContainer = styled(Box)({
  marginTop: "20px",
  border: '1px solid #eee4e4ed',
  borderRadius: '7px',
  padding: '16px',
  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  marginBottom: '20px',
  maxWidth: "70%",
  overflow: "auto",
  margin: "0 auto",
  display:"flex",
  justifyContent:"center",
  '@media print':{
    maxWidth:"100%",  
    margin: "0 20px",
  }
});

const Circle = styled('span')({
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: "#4B6EB0",
  marginRight: "5px",
});


export default PrintOuter;
