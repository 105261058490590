export const cardIcon = require("../assets/card-icon.png");
export const rectangleIcon = require("../assets/rectangle.png");
export const plusIcon = require("../assets/plus.png");
export const cardIcon2 = require("../assets/clarity_form-line.svg");
export const uploadIcon = require("../assets/upload.png")
export const speedPrev = require('../assets/speedPrev.png');
export const speedNext = require('../assets/speedNext.png');
export const theHindu = require('../assets/theHindu.png');
export const economy = require('../assets/economy.png');
export const previous = require('../assets/previous.png');
export const next = require('../assets/next.png');
export const speed = require('../assets/speed.png');
export const play = require('../assets/play.png');
export const bbcHindi = require('../assets/bbcHindi.png');
export const national = require('../assets/national.png');
export const home = require('../assets/home.png');
export const bellIcon = require('../assets/bellIcon.png');
export const navIcon = require('../assets/navIcon.png');
export const search = require('../assets/search.png');
export const logo = require('../assets/logo.png');
export const appLogo = require('../assets/appLogo.png');
export const share = require('../assets/share.png');
export const save = require('../assets/save.png');
export const addToPlaylist = require('../assets/addToPlaylist.png');
export const backArrow = require('../assets/backArrow.png');
export const backIcon = require('../assets/backIcon.png');
export const mediaImage = require('../assets/mediaImage.png');
