import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { buildRequestMessage } from "../../../components/src/buildRequestMessage.web";

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailValue: string;
  snackbar: {
    open: boolean;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    message: string;
  };
  isUser:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgatPassordAPICallId: any;

  public RequestMessage = {
    ForgotPassword: buildRequestMessage(Method.POST, configJSON),
    Null: undefined as any,
  };
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    this.state = {
      emailValue: "",
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      isUser:false,
    };
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
    //   return;
    // }

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (callID === this.RequestMessage.ForgotPassword.messageId) {
      this.handleForgotPasswordResponse(response);
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const userRole = message.getData(
        getName(MessageEnum.NavigationForgotData)
      );
      this.setState({ isUser: userRole })
    }

  }

  handleForgotPasswordResponse(response: any) {
    if (response.error) {
      this.setState({
        snackbar: {
          message: response.error,
          severity: 'error',
          open: true
        }
      });
    } else {
      this.setState({
        snackbar: {
          message: 'Password reset code sent to your email address',
          severity: 'success',
          open: true
        }
      });
      setTimeout(() => {
        let obj = {email:this.state.emailValue,isUser:this.state.isUser}
        const msg: Message = new Message(
          getName(MessageEnum.NavigationResetPassword)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationResetPasswordMessage), obj)
        this.send(msg);
      }, 2500);
    }
  }

  sendForgotPasswordRequest = (form: any) => {

    let data = {email:form.email,isUser:this.state.isUser}
    this.RequestMessage.ForgotPassword.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndpoint
    );

    this.RequestMessage.ForgotPassword.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(this.RequestMessage.ForgotPassword.id, this.RequestMessage.ForgotPassword);
  };

  handleSubmit = (values: any) => {
    if(values.email === '')
    {
      this.setState({
        snackbar: {
          message: 'Email address cannot be blank',
          severity: 'error',
          open: true
        }
      });
    }
    if (values.email) {
      this.setState({ emailValue: values.email });
      this.sendForgotPasswordRequest(values);
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        message: '',
        open: false,
        severity: 'info'
      }
    })
  }
  // Customizable Area End
}
