import React, {
  FunctionComponent,
  useMemo,
} from "react";

import { styled, withStyles } from "@material-ui/core/styles";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { getColors } from "./color";
import {
  Box,
} from "@material-ui/core";

interface Props {
  data: any;
  classes: any;
  response?:any;
}

const RadioType: FunctionComponent<Props> = ({
  data,
  classes,
  response
}) => {
  const formattedData = useMemo(() => {
    return data.map((unit: any) => ({
      ...unit,
      percentage: +unit.percentage || 0,
    }));
  }, [data]);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <p style={{backgroundColor:"white",padding:"6px",paddingLeft:"10px",paddingRight:'10px',boxShadow: "0 3px 8px rgba(0,0,0,0.4)",border: '1px solid #ccc',borderRadius:'3px',fontSize:'14px'}}>{dataPoint.number_of_respondents}</p>
      );
    }
    return null;
  };
  
  let show = data.some((item:any) => parseFloat(item.percentage) !== 0.0);
  const variousColors = useMemo(() => getColors(data.length), [data.length]);
  return (
    <OuterContainer data-test-id="radio-type">
      { show === false ? <Box style={{fontSize: "42px",}}>No responses</Box> : (
      <ResponsiveContainer height={450} width={"100%"} style={{ padding: 0 }}>
        <PieChart  className={classes.ChartStyle}>
          <Pie
            data={formattedData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
              if (percent === 0) return null;
              const RADIAN = Math.PI / 180;
              const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              return (
                <text
                  x={x}
                  y={y}
                  fill="white"
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
              );
            }}
            outerRadius={130}
            innerRadius={80}
            fill="#8884d8"
            dataKey="percentage"
            nameKey="optionName"
          >
            {formattedData.map((entry: any, ind: any) => (
              <Cell key={`cell-${ind}`} fill={variousColors[ind].barColor} />
            ))}
          </Pie>
          <Tooltip content={CustomTooltip} />
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="left"
            wrapperStyle={{
              marginTop: '30px',
              paddingLeft: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "102%",
              height: "100px",
            }}
            iconType="circle"
            iconSize={17}
            formatter={(value: string, entry: any) => (
              <span style={{ fontSize: "16px", color: "black", wordBreak: 'normal', marginRight: "25px" ,marginLeft: "12px" , fontFamily: 'aeonik-regular',}}>
                {value}
              </span>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'aeonik-regular',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const styles = {
  ChartStyle:{
    '& .recharts-legend-item':{
      padding:"16px 12px"
    }
  }
};

export default withStyles(styles)(RadioType);