export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Vector = require("../assets/Vector.svg");

export const VectorWhite = require("../assets/VectorWhite.svg");
export const VectorAdmin = require("../assets/VectorAdmin.svg");
export const VectorUser = require("../assets/VectorUser.svg");
export const ErrorIcon = require("../assets/Error.svg");
export const ToggleIcon = require("../assets/Toggle.svg");




