Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";

const urlConfig = require("../../../framework/src/config");
exports.APIBaseURL = urlConfig.baseURL; 
exports.createSurveyTemplateEndpoint = "bx_block_surveys/surveys/create_survey";
exports.getSurveysEndpoint = "bx_block_surveys/surveys";
exports.editSurveyTemplateEndpoint = "bx_block_surveys/surveys";
exports.deleteSurveyTemplateEndpoint = "bx_block_surveys/surveys/delete";
exports.token = "token";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End