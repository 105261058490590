import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  questionIndex: number;
  classes: any;
  // Customizable Area Start
  data: any;
  QuestionID: any;
  surveyId: string;
  // Customizable Area End
}

// Customizable Area Start

interface DataItem {
  x_axis_data: string;
  submission_count: number;
}

interface DateObject {
  month?: {
    number: number;
  };
  year: number;
}

type TimeUnit = "MONTHLY" | "DAILY";
// Customizable Area End

interface S {
  // Customizable Area Start
  dateList: any[];
  yearValue: Date | null;
  dateValue: Date | null;
  showMonth: boolean;
  activeBtn: "month" | "day";
  monthType: "monthly" | "yearly" | "daily" | "default";
  graphDataList: DataItem[];
  timeUnit: TimeUnit;
  year: string;
  month: string;
  timeInterval: string;

  rowsPerPage: number;
  page: number;
  dateCount: number;
  inputWidth: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DateTypeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDateListCallId: string = "";
  monthsInBetween: string[] = [];
  yearsInBetween: string[] = [];
  getDateTypeId: string = "";
  datePickerRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receiveResponse.bind(this);
    // Customizable Area Start
    this.state = {
      inputWidth: '129px',
      dateList: [],
      graphDataList: [],
      yearValue:null,
      dateValue: new Date(),
      monthType: "default",
      showMonth: false,
      activeBtn: "month",
      timeUnit: "MONTHLY",
      timeInterval: "",
      year: "",
      month: "",

      rowsPerPage: 40,
      page: 1,
      dateCount: 0
    };
    this.datePickerRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receiveResponse(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const ApiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const jsonResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!ApiRequestCallId || !jsonResponse) {
      return;
    }

    if (ApiRequestCallId === this.getDateTypeId && jsonResponse) {
      this.dateTypeReceive(jsonResponse);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    this.postDateList();
    // Customizable Area End
  }

  // Customizable Area Start
  dateTypeReceive = (jsonResponse: any) => {
    if (jsonResponse) {
      this.setState({ graphDataList: jsonResponse });
    }
  };

  handleYearChange = (value: DateObject) => {
    if (value) {
      const selectedDate = new Date(value.year, 0);
      this.setState({ yearValue: selectedDate, monthType: "monthly" , inputWidth: '72px'}, () => {
        this.postDateList();
      });
    } else {
      this.setState({ yearValue: null }, () => {
        this.postDateList();
      });
    }
  };

  handleDateChange = (value: DateObject) => {
    if (value) {
      const { month, year } = value;

      if (
        month &&
        typeof month.number === "number" &&
        typeof year === "number"
      ) {
        const selectedDate = new Date(year, month.number - 1);
        this.setState({ dateValue: selectedDate, monthType: "daily" }, () => {
          this.postDateList();
        });
      }
    } else {
      this.setState({ dateValue: null }, () => {
        this.postDateList();
      });
    }
  };

  handleMonthClick = () => {
    this.setState({ showMonth: false, activeBtn: "month" });
  };

  handleDayClick = () => {
    this.setState({ showMonth: true, activeBtn: "day" });
  };
  formatData = () => {
    const { graphDataList } = this.state;
    return graphDataList.map(data => ({
      name: data.x_axis_data,
      resp: data.submission_count
    }));
  };
  handleIconClick = () => {
    // Open the calendar when the icon is clicked
    if (this.datePickerRef.current) {
      this.datePickerRef.current.openCalendar();
    }
  };
  postDateList = async () => {
    const { yearValue, dateValue, monthType } = this.state;
    let monthValue = null;
    let yearToSend = null;

    if (monthType === "monthly" && yearValue) {
      yearToSend = yearValue.getFullYear();
    } else if (monthType === "daily" && dateValue) {
      monthValue = dateValue.getMonth() + 1;
      yearToSend = dateValue.getFullYear();
    }
    const body = {
      id: parseInt(this.props.surveyId),
      question_id: this.props.QuestionID,
      type: monthType,
      month: monthValue,
      year: yearToSend
    };

    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.datetypeDetaile}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.getDateTypeId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
