import React, { Component } from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Link,
  Typography,
  Button
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import CodeVerificationWeb from "../../../components/src/CodeVerification.web";
import SignUpSuccessDialog from '../../../components/src/SignUpSuccessDialog.web';
const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
const SendButtonBox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  '@media (max-width:678px)': {
    justifyContent:'center',
  }
})
const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    borderBottom: '1px solid #E7E7E7',
    padding: '26px 40px',
  },
  cardTitle: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: ' 23px',
    textAlign: 'left',
    marginBottom: 0,
    fontFamily: 'aeonik-bold',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0px',
    padding: '30px 40px',
  },
  FormTypographyStyle: {
    color: '#202020',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: '18px',
    fontFamily: 'aeonik-regular',
  },
  resendLink: {
    display: 'flex',
    marginTop: '24px',
    color: '#202020',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'aeonik-regular',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px !important',
    maxWidth: '80px',
    marginRight: '10px',
  },
  signUpLink: {
    color: 'var(--medium-blue, #4B6EB0)',
    fontWeight: 700,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: 'aeonik-bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonBoxContinue: {
    display: 'flex',
    justifyContent: 'flex-end',
},
btn: {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    color: "white",
    minWidth: '200px',
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "unset" as const,
    border: 'none',
    userSelect: 'none' as const,
    cursor: 'pointer',
    justifyContent: 'center',
    fontFamily: 'aeonik-bold',
      },
  spacer: {
    display: 'flex',
    flex: '1 1 0px'
  }
};

interface Props {
  classes: any;
  code: string[];
  verifyAccountDialogVisible: boolean;
  handleVerificationCodeChange: (code: any) => void;
  handleEmailVerified: () => void;
  handleResendCode: () => void;
  handleSendVerification: () => void;
}
// Customizable Area End

export class Verification extends Component<Props> {
  render() {
    const { classes, code, handleVerificationCodeChange, verifyAccountDialogVisible, handleEmailVerified, handleResendCode } = this.props;
    return (
      // Required for all blocks
      // Customizable Area Start
      <Box className={classes.container}>
        <Box className={classes.cardHeader}>
          <Typography variant="h4" align="center" gutterBottom className={classes.cardTitle}>
            Verification
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Typography className={classes.FormTypographyStyle}>
            Enter Verification Code to verify your email address
          </Typography>
          <CodeVerificationWeb
            code={code}
            handleVerificationCodeChange={handleVerificationCodeChange}
          />
          <Typography className={classes.resendLink}>
            If you didn’t receive a code, &nbsp;<Link onClick={handleResendCode} className={classes.signUpLink}>Resend</Link>
          </Typography>
          <div className={classes.spacer} />
            <SendButtonBox>
              <Button onClick={this.props.handleSendVerification} type="submit" variant="contained" disabled={false} color="primary" className={classes.btn}>
                Send
              </Button>
            </SendButtonBox>
        </Box>
        <SignUpSuccessDialog
          visible={verifyAccountDialogVisible}
          onContinue={handleEmailVerified}
          header={configJSON.verifyAccountHeader}
          content={configJSON.verifyAccounBodyMessage}
        />
      </Box>
      // Customizable Area End
    );
  }
}


//@ts-ignore
export default withStyles(styles)(Verification);