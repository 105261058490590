import React, { FunctionComponent } from 'react';

import QuestionCard from './QuestionCard.web';
import Input from './CustomInput.web';

interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey:boolean;
}

const Header: FunctionComponent<Props> = ({ onChange, data, onDeleteClick,isLiveorPastSurvey }) => {
  return (
    <QuestionCard
      isLiveorPastSurvey={isLiveorPastSurvey}
      data={data}
      title='Create header'
      onDeleteClick={() => onDeleteClick(data?.ind)}
    >
      <Input
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        value={data?.question_title}
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event.target.value,
          })
        }
      />
      {data?.question_title?.length > 128 && (
        <span style={{ color: 'red', fontFamily:'aeonik-regular' }}>    
          Text is too long. Please enter 128 characters or less.
        </span>
      )}
    </QuestionCard>
  )
};

export default Header;
