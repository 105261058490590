import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import DateTypeController, { Props } from "./DateTypeController.web";
import DatePicker from "react-multi-date-picker";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DateTypeChart from "../../../components/src/DateTypeChart";
interface DateObject {
  month?: {
    number: number;
  };
  year: number;
}
// Customizable Area End

export class DateType extends DateTypeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { yearValue, dateValue, showMonth, activeBtn,inputWidth } = this.state;

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid container style={{display:"flex",justifyContent:"end"}}>
          {/* <Grid item xs={8} /> */}

          <Box className={classes.dateField}>
            {!showMonth ? (
              <>
                <DatePicker
                 ref={this.datePickerRef}
                  placeholder="Select a year"
                  onlyYearPicker
                  value={yearValue}
                  className={classes.datePicker}
                  style={{ width: inputWidth }}
                  calendarPosition='bottom-end'
                  highlightToday={false}
                  onChange={value =>
                    this.handleYearChange(value as DateObject)
                  }
                />
                <IconButton className={classes.iconButton} onClick={this.handleIconClick}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </>
            ) : (
              <>
                <DatePicker
                 ref={this.datePickerRef}
                  format="MMMM YYYY"
                  onlyMonthPicker
                  className={classes.datePicker}
                  calendarPosition='bottom-end'
                  highlightToday={false}
                  value={dateValue}
                  onChange={value =>
                    this.handleDateChange(value as DateObject)
                  }
                />
                <IconButton className={classes.iconButton} onClick={this.handleIconClick}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </>
            )}
          </Box>
          <Box className={classes.toggleBTN}>
            <button
              onClick={this.handleMonthClick}
              className={
                activeBtn === "month" ? classes.active : classes.Deative
              }
            >
              Monthly
            </button>
            <button
              onClick={this.handleDayClick}
              className={
                activeBtn === "day" ? classes.active : classes.Deative
              }
            >
              Day
            </button>
          </Box>
        </Grid>
        <DateTypeChart graphData={this.formatData()} classes={classes} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  datePicker: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    border: "1px solid black"
  },
  iconButton: {
    marginLeft: "-40px"
  },
  dateField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .rmdp-input": {
      height: "40px",
      padding: " 2px 9px",
      width: "147px"

    },
    "& ::placeholder":{
      color: "black",
      opacity:'1'
    },
    "& .DateType-datePicker-59": {
      border: "none",
    },
    "& .DateType-datePicker-60": {
      border: "1px solid #0000"
    },
    '& .rmdp-shadow':{
      border: 'none'
    },
    '& .rmdp-day span': {
      borderRadius:'6px',
      padding: '20px',
      boxShadow:"0px 0px 1px #80808078"
    },
    '& .rmdp-day.rmdp-selected span:not(.highlight)':{
      background:'#304a7c'
    },
    '& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover':{
      background:'#304a7c'
    },
    '& .only.rmdp-year-picker': {
      height: "240px",
      width: "340px"
    },
    '& .only.rmdp-month-picker': {
      width: "340px"
    },
    '& .ep-arrow':{
      display:'none',
      Visibility:"hidden"
    },
    '& .DateType-datePicker-69': {
      border: "none"
    },
    '& .rmdp-arrow ': {
      border: 'solid #6a6a6a',
      borderTopWidth: 'inherit',
      borderLeftWidth: 'inherit',
    }
  },
  toggleBTN: {
    display: "flex",
    backgroundColor: "rgba(189, 201, 226, 0.57)",
    padding: "5px",
    width: "fit-content",
    borderRadius: "12px"
  },
  active: {
    backgroundColor: "#304a7c",
    color: "white",
    border: "none",
    padding: "12px",
    borderRadius: "7px",
    fontSize: "16px"
  },
  Deative: {
    border: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    padding: "8px 13px"
  }
};
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(DateType);
// Customizable Area End
