import React, { Component } from "react";
// Customizable Area Start
import { Grid, Link, Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Input from "../../../components/src/CustomInput.web";

const styles = {
    loginFormWrap: {
        display: 'flex',
        flexDirection: 'column' as const,
        flex: '1 1 0px',
        padding: '32px 52px',
        height: 'calc(100% - 164px)',
    },
    forgotWrapper:{
        display:'flex',
        justifyContent:'flex-end'
    },
    linkButton: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        textAlign: 'right' as const,
        display: 'block'
    },
    navigation: {
        display: 'flex'
    },
    notRegistered: {
        color: '#202020',
        fontFamily: 'aeonik-regular',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
    },
    signUp: {
        color: '#BFCAE4',
        fontFamily: 'aeonik-bold',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        marginLeft: '4px',
        cursor: 'pointer',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    forgotPassword: {
        fontFamily: 'aeonik-regular',
        display:'inline-block',
        // backgroundColor:'red'
    },
    buttonBoxContinue: {
        display: 'flex',
        justifyContent: 'flex-end',
        '@media (max-width:768px)':{
            justifyContent:'center',
        }
    },
    btn: {
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        borderRadius: "10px",
        padding: "18px 24px",
        display: "flex",
        alignItems: "center",
        color: "white",
        minWidth: '200px',
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "unset" as const,
        border: 'none',
        userSelect: 'none' as const,
        cursor: 'pointer',
        justifyContent: 'center',
        fontFamily: 'aeonik-bold',
      },
      spacer: {
        display: 'flex',
        flex: '1 1 0px'
      }
};
// Customizable Area End
interface LoginFormProps {
    emailError: string;
    passwordError: string;
    // Customizable Area Start
    resetLoginError: (val: string) => void;
    classes: any;
    loginForm: {
        email: string;
        password: string;
    };
    handleLoginFieldChange: (event: any) => void;
    onSignUpClick: () => any;
    onLoginClick: () => any;
    // Customizable Area End
}

interface S {
    email: string;
    password: string;
    // Customizable Area Start
    // Customizable Area End
}
export class LoginForm extends Component<LoginFormProps, S> {
    render() {
        // Customizable Area Start
        const { loginForm, classes, emailError, passwordError, handleLoginFieldChange, onSignUpClick, onLoginClick } = this.props;

        const handleEnterPressed = (e: any) => {
            if(e.key === 'Enter') {
                onLoginClick()
            }
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.loginFormWrap}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Input
                            data-test-id="emailInput"
                            error={emailError}
                            value={loginForm.email}
                            onChange={handleLoginFieldChange}
                            placeholder='Example: user@gmail.com'
                            label="Email address"
                            // type="email"
                            name="email"
                            style={{ marginBottom: '24px' }}
                        />
                        <Input
                            data-test-id="passwordInput"
                            error={passwordError}
                            value={loginForm.password}
                            onChange={handleLoginFieldChange}
                            onKeyPress={handleEnterPressed}
                            placeholder='Example: Abc'
                            label="Password"
                            type="password"
                            name="password"
                            style={{ marginBottom: '12px' }}
                        />
                        <div className={classes.forgotWrapper}>
                        <Link href="/ForgotPassword" className={classes.linkButton}>
                            <Typography className={classes.forgotPassword}>Forgot password?</Typography>
                        </Link>
                        </div>
                        <div className={classes.navigation}>
                            <p className={classes.notRegistered}>Not registered yet?</p>
                            <p onClick={onSignUpClick} className={classes.signUp}>sign up</p>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.spacer} />
                <Box className={classes.buttonBoxContinue}>
                    <button onClick={onLoginClick} className={classes.btn}>
                        Login
                    </button>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

export default withStyles(styles)(LoginForm);
