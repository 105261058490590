import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import ExcelJS from 'exceljs'; 
import { saveAs } from 'file-saver';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  surveyList: any;
  tabValue: number;
  surveyName:string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSurveyListCallId: string = "";
  postSurveyDetailsCallId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      tabValue: 0,
      surveyList: [],
      surveyName:"",
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.getSurveyListCallId && responseJson) {
      this.getSurveyListReceive(responseJson);
    }
    if (apiRequestCallId === this.postSurveyDetailsCallId && responseJson) {
      this.exportSurveyReceive(responseJson);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    await Promise.all([this.getSurveyList()]);
    // Customizable Area End
  }

  getSurveyList = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSurveyListApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSurveyListApiMethod
    );

    this.getSurveyListCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  getSurveyListReceive = (surveyResponseJson: any) => {
    // Customizable Area Start
    if (surveyResponseJson.surveys.data) {
      const filteredSurveys = surveyResponseJson.surveys.data.filter(
        (survey: any) => survey.attributes.status !== "scheduled" && survey.attributes.status !== "draft"
      );
      this.setState({ surveyList: filteredSurveys });
    }
    // Customizable Area End
  };

  // Customizable Area Start

  exportSurveyReport = (surveyId:number,surveyName:any) => {
    this.setState({surveyName:surveyName});
    let postData = {
      survey_ids: surveyId,
    };
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": 'application/json',
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_surveys/surveys/export_dashboard`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(postData)
    );
    this.postSurveyDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jsonToExcel = async (jsonReport:any) => {
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    const headers = [
      'Survey name', 'First name', 'Last name', 'Email address', 
      'Associated groups', 'Local authority', 'Industry sector', 
      'Date shared', 'Date filled', 'Question', 'Question type', 
      'Response'
    ];
  
    const columnWidths = [35, 25, 25, 45, 30, 30, 30, 20, 20, 55, 20, 70];
    worksheet.columns = headers.map((header, index) => ({
      header,
      key: header.toLowerCase().replace(/ /g, '_'),
      width: columnWidths[index]
    }));
  
    // Add data to the worksheet 
    jsonReport.forEach((data:any) => {
      worksheet.addRow({
        survey_name: data.survey_name,
        first_name: data.first_name,
        last_name: data.last_name,
        email_address: data.email_address,
        associated_groups: data.associated_groups,
        local_authority: data.local_authority,
        industry_sector: data.industry_sector,
        date_shared: data.date_shared,
        date_filled: data.date_filled,
        question: data.question,
        question_type: data.question_type,
        response: data.response
      });
    });
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'left', vertical: 'top' };
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob,`${this.state.surveyName}.xlsx`,{ autoBom: false });
  };
  exportSurveyReceive = (response:any) => {
    this.jsonToExcel(response);
  }
  handleChangeTab = (event: any, newValue: number) => {
    // this.props.navigation.navigate("Dashboard", { page: this.tabs[newValue] })
    this.setState({ tabValue: newValue });
    if (newValue === 0) {
      this.getSurveyList();
    } 
  };
  // Customizable Area End
}
