import React from "react";

import {
  Box,
  // Customizable Area Start
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  styled
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start

// Customizable Area End

import AdminSettingsController, {
  Props,
} from "./AdminSettingsController.web";
import AdminSettingsSidebar from "../../../components/src/AdminSettngs.web";
import BasicSettings from "./BasicSettings.web";


export default class AdminSettings extends AdminSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const renderSelectedItem = () => {
      const { selectedItem } = this.state;
      if (!selectedItem) {
        return null;
      }
      const { type } = selectedItem;

      if (type === 'basicSettings') {
        return <BasicSettings navigation={undefined} id={""} />;
      } else if (type === 'billingPlans') {
        return <BasicSettings navigation={undefined} id={""} />;
      } else if (type === 'logout') {
        return <BasicSettings navigation={undefined} id={""} />;
      } else {
        return <BasicSettings navigation={undefined} id={""} />
      }
    };

    return (
      // Customizable Area Start
      <div style={{ flex: "1", width: '100%' }}>
        <MainBox >
          <Box style={{ display: "flex", padding: "20px", borderBottom: "1px solid #DEDEDE", fontFamily: 'aeonik-regular', fontWeight: 700, fontSize: "24px" }}>Settings</Box>
          <Container >

            {/* select field type  */}

            <AdminSettingsSidebar
              navigation={this.props.navigation}
              renderItems={this.renderItems()}
              logOut={this.handleLogout}
              activeItem={this.state.activeItem}
              selectedItem={this.state.selectedItem}
              onItemClick={this.onItemClick}
            />

            {/* fields */}
            <Box style={{ width: "100%" }}>{renderSelectedItem()}</Box>
          </Container>

        </MainBox>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.showLogoutDialog} onClose={this.handleCancelLogout} fullWidth maxWidth="sm"
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "auto",
              padding: "25px",
              position: 'absolute',
              top: "0px"
            },
          }}>
          <DialogContent>
            <Grid item xs={12} style={webStyle.surveyTital}>
              Log out
            </Grid>
            <Grid item xs={12} style={webStyle.mainLogoutContent}>
              Are you sure you want to quit the application?
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div style={webStyle.modalbtn}>
                <Button
                  data-test-id="create-btn"
                  style={webStyle.edit}
                  onClick={this.handleConfirmLogout}
                >
                  Yes
                </Button>
                <Button data-test-id="cancel-btn" style={webStyle.close} onClick={this.handleCancelLogout}>No</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Container = styled('div')({
  display: 'flex',
  '@media (min-width: 100px) and (max-width:1000px)': {
    flexDirection: 'column',
  },
});
const MainBox = styled('div')({
  border: '1px solid #ddd',
  margin: '50px',
  boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
  borderRadius: '20px',
  background: "#FFFFFF",
  height: "95%",
  '@media (min-width: 600px) and (max-width:1000px)': {
    margin:'25px 25px 50px 25px',
    borderRadius:'15px',
  },
  '@media (min-width: 100px) and (max-width:600px)': {
    margin:'20px 20px 50px 20px',
    borderRadius:'15px',
  },
})
const webStyle = {
  container: {
    display: 'flex',
    '@media (min-width:600px) and (max-width:1080px)': {
      flexDirection: 'column',
    }
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "15px"
  },
  edit: {
    padding: '18px 75px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    marginBottom: "10px",
    textTransform:'none' as const,
  },
  mainLogoutContent: {
    color: "#202020",
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    marginBottom: "10px"
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column' as const,
    borderRadius: '12px',
    background: 'var(--basic-white, #FFF)',
    boxShadow: '0px 4px 8px 0px #00000008, 0px 8px 32px 0px #0000000F',
    width: '17%',
    height: '100%',
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  text: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    color: 'rgba(121, 121, 121, 1)'
  },
  item: {
    cursor: 'pointer',
    alignItems: 'center',
    padding: '8px 0px',
    display: 'flex',
  },
  img: {
    width: '24px',
    height: '24px'
  },

  addNewField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0px',
    borderRadius: '28px',
    border: '0.5px dashed #A7A7A7',
    cursor: 'pointer'
  },
  questionBlock: {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '0px 24px',
    flex: '1 1 0px'
  },
  active: {
    borderRadius: '5px 0px 0px 5px',
    height: '56px',
    width: '4px',
  },
  plusIcon: {
    alignItems: 'center',
    marginLeft: '21px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '42px',
    height: '42px',
    display: 'flex',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px 40px 24px 40px'
  },
  addNewText: {
    lineHeight: 'normal',
    color: '#212529',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  secondaryBtn: {
    borderRadius: '10px',
    minWidth: '180px',
    padding: '18px 32px',
    marginRight: '16px',
    fontWeight: 700,
    border: '1px solid #1C386D',
    color: '#1C386D',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  primaryBtn: {
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    padding: '18px 32px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px'
  },
  mainBox: {
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    height: "95%",
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },

};
// Customizable Area End
