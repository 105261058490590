// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { buildRequestMessage } from "../../../components/src/buildRequestMessage.web";

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    classes: any;
}
interface OriginalQuestion {
    id: number;
    title: string;
    data: {
        type: string;
        analytics: any; // Adjust as per actual structure
        answer: string;
        date_answer: string | null;
        slider: any; // Adjust as per actual structure
        option_ids: string[] | null;
        respondents: number;
        min_value:number,
        max_value:number,
    };
}

interface TransformedQuestionShortText {
    question_title: string;
    question_type: "short-text";
    answer: string;
}

interface TransformedQuestionRadio {
    question_title: string;
    question_type: "radio";
    answer: string[];
    total: number;
    options: {
        option: string;
        average: number;
    }[];
}

interface TransformedQuestionCheckbox {
    question_title: string;
    question_type: "checkbox";
    answer: string[];
    total: number;
    options: {
        option: string;
        average: number;
    }[];
}

interface TransformedQuestionLongText {
    question_title: string;
    question_type: "long-text";
    answer: string;
}

interface TransformedQuestionDate {
    question_title: string;
    question_type: "date";
    answer: string;
    analytics: any[]; // Adjust as per actual structure
    slider: any; // Adjust as per actual structure
    option_ids: any; // Adjust as per actual structure
    respondents: number;
}

interface TransformedQuestionRanking {
    question_title: string;
    question_type: "ranking";
    answer: string;
    analytics: {
        option: {
            id: number;
            name: string;
        };
        score: number;
        rank: number;
    }[];
}

type TransformedQuestion =
    | TransformedQuestionShortText
    | TransformedQuestionRadio
    | TransformedQuestionCheckbox
    | TransformedQuestionLongText
    | TransformedQuestionDate
    | TransformedQuestionRanking;

interface OriginalSection {
    header: string;
    questions: OriginalQuestion[];
}

interface TransformedSection {
    header: string;
    questions: TransformedQuestion[];
}


interface S {
    snackbar: {
        open: boolean;
        severity: 'success' | 'info' | 'warning' | 'error' | undefined;
        message: string;
    };
    questionsReport: any[];
    currentSectionTitle:string,
    previousSectionTitle:string,
    currentSurveySection:TransformedSection[],
    previousSurveySection:TransformedSection[],
    isPreviousSurveyExist:boolean,
}

interface SS {
    navigation: any;
}

export default class SurveyReportController extends BlockComponent<
    Props,
    S,
    SS
> {

    public RequestMessage = {
        SurveyReport: buildRequestMessage(Method.GET, configJSON),
        Null: undefined as any,
    };

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            snackbar: {
                open: false,
                severity: 'info',
                message: ''
            },
            questionsReport: [],
            currentSectionTitle:'',
            previousSectionTitle:'',
            currentSurveySection:[],
            previousSurveySection:[],
            isPreviousSurveyExist:false,
        };
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getSurveyReports();
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            switch (callID) {
                case this.RequestMessage.SurveyReport.messageId:
                    this.handleSurveyReportResponse(response);
                    break;
            }
        }

    }

    handleSurveyReportResponse = (response: any) => {
        if (response?.errors?.message === 'Authentication token invalid') {
            const msg: Message = new Message(
                getName(MessageEnum.NavigationAuthBlockMessage)
            );
            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(msg);
        }
        if (response?.message) {
            this.setState({
                snackbar: {
                    open: true,
                    severity: 'error',
                    message: response.message
                }
            })
        } else {
            if (response.previous_survey_report?.sections_list){
                const PreviousTransformedSections: TransformedSection[] = this.transformSections(response.previous_survey_report?.sections_list);
                this.setState({
                    isPreviousSurveyExist: true,
                    previousSectionTitle: response.previous_survey_report?.title,
                    previousSurveySection: PreviousTransformedSections,
                })
            }
            const transformedSections: TransformedSection[] = this.transformSections(response.suvery_report);
            this.setState({
                currentSurveySection: transformedSections,
                currentSectionTitle: response.overview.title,

            })
        }
    }
    transformSections = (sections: OriginalSection[]): TransformedSection[] => {

        function radioReturnFunction(question:any){
            if(question.data.option_ids !== null)
                {
                   return question.data.answer.split(', ').map((id:any) => {
                        const matchedOption = question.data.analytics.find((option:any) => option.optionId.toString() === id);
                        return matchedOption ? matchedOption.optionName : '';
                        });
                }
                    return [];
        }

        function CheckboxReturnFunction(question:any){
        if(question.data.answer!==null){
            const result = question.data.answer.split(', ').map((id:any) => {
                const matchedOption = question.data.analytics.find((option:any) => option.optionId.toString() === id);
                return matchedOption ? matchedOption.optionName : '';
            });
            result.sort((a: string, b: string) => {
                const numA = parseFloat(a);
                const numB = parseFloat(b);
                return numA - numB;
            });
            return result;
            } 
                return []
        }
        return sections.map((section) => {
            const transformedQuestions: TransformedQuestion[] = section.questions.map((question) => {
                switch (question.data.type) {
                    case "short-text":
                        return {
                            question_title: question.title,
                            question_type: "short-text",
                            answer: question.data.answer
                        } as TransformedQuestionShortText;
                    case "slider":
                        return {
                            question_title: question.title,
                            question_type: "slider",
                            answer: question.data.answer,
                            respondents: question.data.respondents,
                            min_value: question.data.analytics.min_value,
                            max_value:question.data.analytics.max_value,
                            max_label:question.data.analytics.max_label,
                            min_label:question.data.analytics.min_label,
                            average_value:question.data.analytics.avg_value,
                            avgPercent:question.data.analytics.avgPercent,
                        } as any;
                    case "radio":
                        const radioOptions = question.data.analytics.map((option:any) => ({
                            option: option.optionName,
                            average: parseFloat(option.percentage),
                        }));
                    const RadioAnswer=radioReturnFunction(question);
                        return {
                            question_title: question.title,
                            question_type: "radio",
                            answer: RadioAnswer,
                            total: question.data.respondents,
                            options: radioOptions
                        } as TransformedQuestionRadio;

                    case "checkbox":
                        const checkboxOptions = question.data.analytics.map((option:any) => ({
                            option: option.optionName,
                            average: parseFloat(option.percentage)
                        }));
                        let checkboxAnswer=CheckboxReturnFunction(question);
                        return {
                            question_title: question.title,
                            question_type: "checkbox",
                            answer: checkboxAnswer,
                            total: question.data.respondents,
                            options: checkboxOptions
                        } as TransformedQuestionCheckbox;
   
                    case "long-text":
                        return {
                            question_title: question.title,
                            question_type: "long-text",
                            answer: question.data.answer
                        } as TransformedQuestionLongText;

                    case "date":
                        return {
                            question_title: question.title,
                            question_type: "date",
                            answer: question.data.date_answer,
                            // analytics: question.data.analytics,
                            // slider: question.data.slider,
                            // option_ids: question.data.option_ids,
                            respondents: question.data.respondents
                        } as TransformedQuestionDate;

                    case "ranking":
                        const rankingOptions = question.data.analytics.map((item:any) => ({
                            option: item.option,
                            average: item.score * 25 // Adjust based on your scoring logic
                        }));
                        return {
                            total: question.data.respondents,
                            question_title: question.title,
                            question_type: "ranking",
                            answer: question.data.answer,
                            analytics: question.data.analytics
                        } as TransformedQuestionRanking;

                    default:
                        throw new Error(`Unsupported question type: ${question.data.type}`);
                }
            });
            return {
                header: section.header,
                questions: transformedQuestions
            };
        });
    }

    getSurveyReports = () => {
        let surveyId: any = this.props.navigation.getParam('surveyId');
        this.RequestMessage.SurveyReport.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSurveyReports + "/" + surveyId + "/survey_report_for_user"
        );

        runEngine.sendMessage(this.RequestMessage.SurveyReport.id, this.RequestMessage.SurveyReport);
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                message: '',
                open: false,
                severity: 'info'
            }
        })
    }
}
// Customizable Area End
