import React, { FunctionComponent } from 'react';

import QuestionCard from './QuestionCard.web';
import Input from './CustomInput.web';
import { Box, makeStyles } from '@material-ui/core';

interface Props {
    onChange: (data: any) => any;
    onDeleteClick: (data: any) => any;
    data: any;
    isLiveorPastSurvey:boolean;
}
const useStyles = makeStyles({
    mainBox: {
      fontFamily: 'aeonik-regular',
      "& ::placeholder":{
          fontFamily: 'aeonik-regular',
      },
  }
  });
const ShortTextQuestion: FunctionComponent<Props> = ({ onChange, data, onDeleteClick,isLiveorPastSurvey}) => {
    const classes = useStyles();
    return (
        <Box className={classes.mainBox}>
        <QuestionCard
            isLiveorPastSurvey={isLiveorPastSurvey}
            title='Text box'
            data={data}
            onDeleteClick={() => onDeleteClick(data?.ind)}
            onRequiredCheck={(val) => onChange({
                ...data,
                required: val
            })}
            required
        >
            <Input
                readOnly={isLiveorPastSurvey}
                disabled={isLiveorPastSurvey}
                maxLength={128}
                style={styles.input}
                value={data?.question_title}
                placeholder='Add question text'
                
                onChange={(event) =>
                    onChange({
                        ...data,
                        question_title: event.target.value,
                    })
                }
            />
            <Input
                readOnly={isLiveorPastSurvey}
                disabled={isLiveorPastSurvey}
                maxLength={32}
                label='Text box label'
                value={data?.question_label}
                placeholder='Add placeholder text'
                onChange={(event) =>
                    onChange({
                        ...data,
                        question_label: event?.target.value,
                    })
                }
            />
        </QuestionCard>
        </Box>
    )
};
const styles = {
    input: {
        marginBottom: '16px'
    }
}
export default ShortTextQuestion;