Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.surveyApiEndPoint = "bx_block_surveys/surveys"
exports.surveyQuestionApiEndPoint = "bx_block_surveys/questions"
exports.surveyQuestionSubmissionsApiEndPoint = "bx_block_surveys/submissions"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";
exports.getUserSurvaysEndPoint = "bx_block_surveys/submissions/surveys";
exports.getPastApiEndPoint = "/bx_block_surveys/surveys/past_surveys"
exports.getLiveApiEndPoint = "bx_block_surveys/surveys/live_surveys"
exports.getScheduledApiEndPoint = "bx_block_surveys/surveys/scheduled_surveys"
exports.editScheduleSurvey = "bx_block_surveys/surveys/schedule_survey"
exports.getGroupApiEndPoint ="bx_block_groups/groups"
exports.getTagApiEndPoint ="bx_block_tags/tags/show"
exports.btnExampleTitle = "CLICK ME";
exports.editLiveSurvey = "bx_block_surveys/surveys"
exports.getSurveyUsersEndPoint = "bx_block_user/users/users_email_list_for_groups"
exports.removeUserGroupApiEndPoint = "/bx_block_groups/groups/remove_user"
exports.selectUserGroupApiEndPoint = "/bx_block_groups/groups/add_user"
// Customizable Area End