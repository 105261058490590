import React from 'react';

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import AdminLoginController, {
  Props,
} from './AdminLoginController';

import {
  Box,
  Button,
  Tabs,
  Tab,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import AdminCart from './AdminCart';
import LoginForm from "./LoginForm";
import SignupForm from "./Signup";
import Verification from './Verification';
import TermsAndConditionDialog from "../../../components/src/TermsAndPrivacyDialog.web";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    flex: '1 1 0px',
    '@media (max-width:500px)':{
      width:'100%',
    },
    '@media (min-width:501px) and (max-width:1245px)':{
      width:'100%',
    }
  },
  mainBox: {
    // Define your custom styles here
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    display: 'flex',
    flex: '1 1 0px',
    '@media (max-width:500px)':{
      margin:'40px 25px'
    }
  },
  tab: {
    '&.MuiTab-root': {
      fontWeight: '700',
      fontSize: '20px',
      fontFamily: 'aeonik-bold',
      lineHeight: ' 23px',
      color: ' #202020',
      opacity: ' 0.4',
      textTransform: 'none',
      minHeight: "100px",
      '@media (max-width:600px)':{
        minWidth:'160px',
      }
    },
    '&.MuiTab-root.Mui-selected': {
      color: ' #202020',
      opacity: 1
    },
  },
  cardHeader: {
    borderBottom: '1px solid #E7E7E7',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonBoxContinue: {
    display: 'flex', justifyContent: 'flex-end',
    padding: '0 50px 40px'
  },
  Ptab:{
    '@media (max-width:1980px)':{
      background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
      height: "10px",
      marginLeft:'52px',
      width: '62px', 
      borderRadius: "15px 15px 0 0"
    },
    '@media (max-width:500px)':{
      marginLeft:'52px',
    }

  },
  btn: {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    color: "white",
    minWidth: '200px',
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "unset !important",
    border: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    fontFamily: 'aeonik-bold',
  }
};
// Customizable Area End
export class AdminLoginBlock extends AdminLoginController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { tabValue } = this.state;
    // Customizable Area End
    return (
      // Required for all blocks
      // Customizable Area Start   
      <div className={classes.container}>
        <Box className={classes.mainBox}>

          {this.state.step === 2 &&
            <Box data-test-id='signin-signup-form' sx={{ width: '100%' }} className={classes.tabs}>
              <Box className={classes.cardHeader} >
                <Tabs value={tabValue} onChange={this.handleChangeTab} aria-label="basic tabs example" id="tabChangeSection"
                  TabIndicatorProps={{ className:classes.Ptab, style: { background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)", height: "10px", width: '62px', borderRadius: "15px 15px 0 0" }}
                  }>
                  <Tab className={classes.tab} label="Login" {...{ id: `simple-tab-${0}`, 'aria-controls': `simple-tabpanel-${0}` }} />
                  <Tab className={classes.tab} label="Sign up" {...{ id: `simple-tab-${1}`, 'aria-controls': `simple-tabpanel-${1}` }} />
                </Tabs>
              </Box>
              {tabValue === 0 && (
                <LoginForm
                  loginForm={this.state.logInForm}
                  emailError={this.state.emailError}
                  handleLoginFieldChange={this.handleLoginFieldChange}
                  passwordError={this.state.passwordError}
                  resetLoginError={this.resetLoginError}
                  onSignUpClick={this.onSignUpClick}
                  onLoginClick={this.handleClickLoginButton}
                />
              )}
              {tabValue === 1 && (
                <SignupForm
                  handleTermsOfServiceChange={this.handleTermsOfServiceChange}
                  touched={this.state.touched}
                  showPasswordConfirm={this.state.showPasswordConfirm}
                  setShowPasswordConfirm={this.setShowPasswordConfirm}
                  handleSignUpClick={this.handleSignUpClick}
                  handleSignUpFieldChange={this.handleSignUpFieldChange}
                  signUpForm={this.state.signUpForm}
                  handleContinueToVerifyEmail={this.handleContinueToVerifyEmail}
                  signUpConfirmaionDialogVisible={this.state.signUpConfirmaionDialogVisible}
                  errors={this.state.errors}
                  industrySectors={this.state.industrySectors}
                  onSignUpClick={this.handleSignUpClick}
                  handleOpenTermsAndConditionDialog={this.handleOpenTermsAndConditionDialog}
                  handleOpenPrivacyPolicyDialog={this.handleOpenPrivacyPolicyDialog}
                />
              )}
            </Box>}
          {this.state.step === 3 && (
            <>
              <Verification
                code={this.state.code}
                handleVerificationCodeChange={this.handleVerificationCodeChange}
                verifyAccountDialogVisible={this.state.signUpConfirmaionDialogVisible}
                handleEmailVerified={this.handleEmailVerified}
                handleResendCode={this.handleResendCode}
                handleSendVerification={this.handleSendVerification}
              />
            </>
          )}
        </Box>
        <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={3000}
          open={this.state.snackbar.open}
          onClose={this.handleCloseSnackbar}
        >
          <Alert severity={this.state.snackbar.severity}>
            {this.state.snackbar.message}
          </Alert>
        </Snackbar>
        <TermsAndConditionDialog
          title={this.renderTermsAndPrivacyData()?.title}
          data={this.renderTermsAndPrivacyData()?.data}
          visible={this.state.termsAndConditionDialogVisible}
          onClose={this.handleCloseTermsAndCondtionDioalg}
        />
      </div>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withStyles(styles)(AdminLoginBlock);