import React from 'react';
import { styled } from "@material-ui/core/styles";
import { Radio, Typography } from "@material-ui/core";

const CustomRadio = styled(Radio)(({ theme }) => ({
  width: 20, 
  height: 20, 
  color: '#9e9e9e', 
  '&.Mui-checked': {
    color: '#3f51b5', 
  },
}));

const RadioWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
});

interface RadioOptionProps {
  option: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  key:number;
  name:string;
}

const RadioOption: React.FC<RadioOptionProps> = ({ option, disabled, onChange, checked,key,name }) => {
  return (
    <RadioWrapper>
      <CustomRadio
        disabled={disabled}
        value={option} 
        checked={checked}
        onChange={onChange}
        name={name} 
        key={key}
      />
      <Typography style={{ color: '#202020', fontSize: '16px', fontWeight: 500 }}>
        {option}
      </Typography>
    </RadioWrapper>
  );
};

export default RadioOption;
