import React from 'react';

import {
    Typography,
    Button,
} from "@material-ui/core";

import Dialog from './Dialog.web';

interface Props {
    visible: boolean;
    content?: string;
    header?: string;
    onContinue?: () => void;
}

export default function SignUpSuccessDialog({ visible, onContinue, content, header }: Props) {
    const handleSaveClick = () => {
        if (onContinue) {
            onContinue();
        }
    }

    return (
        <Dialog style={styles.dialog} visible={visible}>
            <div style={styles.dialogBody}>
                <div style={styles.headContainer}>
                    <Typography style={styles.dialogHeader}>
                        {header}
                    </Typography>
                </div>
                <div style={styles.dialogContent}>
                    <Typography style={styles.body}>{content}</Typography>
                    <Button onClick={handleSaveClick} style={styles.btn}>Continue</Button>
                    <div style={styles.buttonContainer}>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const styles = {
    dialog: {
        borderRadius: '16px',
    },
    dialogBody: {
        borderRadius: '16px',
        background: '#FFF',
        padding: '42px 38px 50px 38px',
        
    },
    headContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '32px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    btn: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px',
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        fontSize: '18px',
        fontWeight: 600,
        color: 'rgba(255, 255, 255, 1)',
        lineHeight: 'normal',
        fontStyle: 'normal',
        flex: '1 1 0px',
        marginTop: '32px',
        textTransform: 'unset' as const,
        minWidth: '200px',
        fontFamily: 'aeonik-bold',
    },
    dialogHeader: {
        color: '#000',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '25px',
        fontFamily: 'aeonik-bold',
    },
    body: {
        color: '#000',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '25px',
        textAlign: 'center' as const,
        fontFamily: 'aeonik-regular',
    }
}