Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Groups";
exports.labelBodyText = "Groups Body";
exports.getGroupApiEndPoint="bx_block_groups/groups";
exports.createUserApiEndPoint="bx_block_groups/groups/create_user";
exports.createGroupApiEndPoint="bx_block_groups/groups/create";
exports.removeGroupApiEndPoint="bx_block_groups/groups/remove_user";
exports.getUserListApiEndPoint="bx_block_user/users/users_list";
exports.addUserToGroupEndPoint="bx_block_groups/groups/add_user"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End