Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";

const urlConfig = require("../../../framework/src/config");
exports.APIBaseURL = urlConfig.baseURL; 
exports.updateUserEndPoint = "bx_block_user/users/update_user";
exports.getUserEndPoint = "bx_block_user/users/edit_user";
exports.deleteUserAccountEndPoint = "bx_block_user/users/delete_user";

exports.getTermsAndConditions = "bx_block_terms_and_conditions/terms_and_conditions/latest_record";
exports.getPrivacyPolicy = "bx_block_privacy_policy/privacy_policies/latest_record";
exports.changePassword="bx_block_user/users/change_password";
exports.token = "token";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End