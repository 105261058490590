import React, { FunctionComponent } from 'react';

import {
    Box,
    Button,
    Typography,
    Grid
} from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const cardIcon = require("./card-icon.png");

interface Props {
    navigation: any;
    item: any;
    onDeleteClick: (id: number) => void;
    onEditClick: (item: any) => void;
}

const SurveyTemplateCard: FunctionComponent<Props> = ({ item, onDeleteClick, onEditClick, ...props }) => {
    return (
        <Grid item xs={12} md={6} lg={3}>
            <Box style={webStyle.card} gridColumn="span 3">
                <div style={webStyle.container}>
                <div style={webStyle.body}>
                    <div style={webStyle.editDeleteActionContainer}>
                        <img style={webStyle.img} src={cardIcon} alt="cardIcon" />
                        <div style={webStyle.actions}>
                            <button onClick={() => onDeleteClick(item?.id)} style={webStyle.icons}>
                                <DeleteIcon htmlColor='#9f9f9f' />
                            </button>
                            <button disabled={item.status === 'past' || item.status === 'live'}  onClick={() => onEditClick(item)} style={webStyle.icons}>
                                <EditIcon style={item.status === 'past'? {color:'#c0c0c0'}:{color:'#9f9f9f'}}  />
                            </button>
                        </div>
                    </div>
                    <Typography style={webStyle.cardHeader}>{item?.name}</Typography>
                    <Typography style={webStyle.text}>{item?.description}</Typography>
                </div>
                    <div style={webStyle.actions}>
                        <Button
                            onClick={() => props.navigation.navigate("SurveyQuestions", { surveyId: item?.id })}
                            data-test-id='fill-btn'
                            style={item.status !== "scheduled" && item.status !== "draft" ? { ...webStyle.btn, background: 'gray' } : webStyle.btn}
                            // disabled={item.status !== "scheduled" && item.status !== "draft"}
                        >
                            Edit
                        </Button>


                    </div>
                </div>
            </Box>
        </Grid>
    )
}

const webStyle = {
    card: {
        height: "300px",
        borderRadius: '28px' as const,
        border: '0.5px solid #DEDEDE',
        background: '#FCFCFC',
    },
    container: {
        padding: '24px',
        display: 'flex',
        flexDirection: 'column' as const,
        color: 'var(--text, #202020)',
        height: "100%",
        boxSizing: "border-box" as const,
        justifyContent: "space-between"
    },
    body: {
        overflow: 'hidden',
        marginBottom: '30px'
    },
    img: {
        width: '40px',
        height: '40px',
        marginBottom: '24px'
    },
    cardHeader: {
        fontFamily: 'aeonik-regular',
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: '12px',
        lineHeight: 'normal',
        fontStyle: 'normal',
        color: '#121212',
        wordBreak:'break-word' as const
    },
    text: {
        fontFamily: 'aeonik-regular',
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: '36px',
        lineHeight: 'normal',
        fontStyle: 'normal',
        color: '#121212',
        overflowWrap: 'break-word' as const, 
        wordWrap: 'break-word' as const, 
        wordBreak: 'break-all' as const, 
        maxWidth: '100%', 
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    editDeleteActionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    editDeleteAction: {
        display: 'flex',
        justifyContent: 'center'
    },
    icons: {
        marginLeft: '8px',
        cursor: 'pointer',
        padding: '0px',
        border: 'none',
        background: 'none'
    },
    btnSec: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        border: '1px solid #BFCAE4',
        fontSize: '16px',
        fontWeight: 700,
        color: 'rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)',
        lineHeight: 'normal',
        fontStyle: 'normal',
        marginRight: '20px',
        flex: '1 1 0px',
        fontFamily: 'aeonik-bold',
        textTransform: 'unset' as const,
    },
    btn: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        fontSize: '16px',
        fontWeight: 700,
        color: '#fff',
        lineHeight: 'normal',
        fontStyle: 'normal',
        flex: '1 1 0px',
        fontFamily: 'aeonik-bold',
        textTransform: 'unset' as const,
    },
};

export default SurveyTemplateCard;
