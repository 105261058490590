// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

interface NavigationProps {
    navigate: (route: string) => void;
    getParam: (param: string, defaultValue?: string) => string | undefined;
}

interface User {
    email: string;
    first_name: string | null;
    last_name: string | null;
    user_id: string | number;
    confirm_password: string;
    password: string;
    activated: boolean;
    industry_sector: string | null;
    company: string | null;
    local_authority: string | null;
    district: string | null;
    terms_of_service: boolean;
    type: string;
    group_ids: number[];
    isUserIngroup: boolean;
    name:string
}


interface SubmitUserListResponse {
    password: string;
    email: string;
    message: string;
}

interface APIResponse {
    total_count: number;
    users: User[];
}


interface Group {
    id: number;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
    group_name: string | null;
}

interface AddUserToGroupResponse {
    group: Group;
    user: User;
}

export interface Props {
    navigation: NavigationProps
    id: string;
    classes: { [key: string]: string };
}

interface S {
    tabValue: number;
    email: string,
    cancelModal: boolean;
    page: number,
    error: boolean,
    createUserAdd: {
        email: string;
        name: string;
    },
    nameError: boolean;
    emailError: boolean;
    userList: User[];
    currentPage: number;
    itemsPerPage: number,
    totalCount: number,
    userlistPerPage: number,
    perPage: number,
    group: [],
    user: [],
}
interface SS {
    id: number;
}


export default class UserListsController extends BlockComponent<
    Props,
    S,
    SS
> {
    getGroupDetailCallId: string = ""
    createGroupCallId: string = ""
    onCreateCallId: string = ""
    getUserListCallId: string = ""
    addUserToGroupId: string = ""
    onCreateUserListCallId: string = ""
    removeUserToGroupId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            tabValue: 1,
            cancelModal: false,
            page: 0,
            email: '',
            error: false,
            createUserAdd: {
                email: '',
                name: '',
            },
            nameError: false,
            emailError: false,
            userList: [],
            currentPage: 1,
            itemsPerPage: 20,
            totalCount: 0,
            perPage: 20,
            userlistPerPage: 20,
            group: [],
            user: [],
        };

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));


        if (!apiRequestCallId || !responseJson) {
            return;
        }
        if (apiRequestCallId === this.getUserListCallId && responseJson) {
            this.getUserListDetailReceive(responseJson)
        }
        else if (apiRequestCallId === this.onCreateUserListCallId && responseJson) {
            this.submitUserListReceiver(responseJson)
        }
        else if (apiRequestCallId === this.addUserToGroupId && responseJson) {
            this.addUserToGroupReceive(responseJson)
        }
        else if (apiRequestCallId === this.removeUserToGroupId && responseJson) {
            this.removeUserToGroupReceive(responseJson)
        }

        runEngine.debugLog("Message Recived", message);
        // Customizable Area End

    }

    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const perPage = 20;
        const page = 1;
        await Promise.all([this.getUserListDetails(perPage, page)]);
    }

    getUserListDetails = async (perPage: number, page: number): Promise<void> => {

        const queryString = `bx_block_user/users/users_list?per_page=${perPage}&page=${page}`;

        const header1 = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("token")

        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header1)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            queryString
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getUserListCallId = requestMessageList.messageId;
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }


    getUserListDetailReceive = (responseJson: APIResponse) => {
        const groupId = this.props.navigation.getParam("id") ? Number(this.props.navigation.getParam("id")) : ''
        if (responseJson?.users) {
            const response = (responseJson.users || []).map((user) => {
                const isUserIngroup = (user?.group_ids || []).some((index: number) => index === groupId)
                return {
                    ...user,
                    isUserIngroup
                }
            })
            this.setState({
                userList: response,
                totalCount: responseJson.total_count,
            });
        }
    }


    handleClickOpen = () => {
        this.setState({ cancelModal: true });
    };

    handleClose = () => {
        this.setState({ cancelModal: false });
    };

    handleSnackbarClose = () => {
        this.setState({ emailError: false, nameError: false });
    };

    handleRedirect = (): void => {
        this.props.navigation.navigate("Groups");
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue });
    };

    addUserToGroup = async(index: number | string) => {
        
        const groupId = Number(this.props.navigation.getParam("id"))

        const header = {
            token: await getStorageData("token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addUserToGroupEndPoint}?id=${index}&group_id=${groupId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        this.addUserToGroupId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addUserToGroupReceive = (responseJson: AddUserToGroupResponse) => {
        responseJson && this.getUserListDetails(20, 1)
    };

    removeUserToGroup = async (index: number | string) => {
        const groupId = Number(this.props.navigation.getParam("id"))

        
        const header = {
            token: await getStorageData("token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.removeGroupApiEndPoint}?id=${index}&group_id=${groupId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        this.removeUserToGroupId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeUserToGroupReceive = (responseJson: AddUserToGroupResponse) => {
        responseJson && this.getUserListDetails(20, 1)
    };

    handleUserListSubmit = async () => {
        const groupId = Number(this.props.navigation.getParam("id"))
        const { name, email } = this.state.createUserAdd;
        this.setState({ nameError: !name });
        this.setState({ emailError: !email });
        if (email) {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: await getStorageData("token")
            };

            let formData = {
                email: this.state.createUserAdd.email,
                name: this.state.createUserAdd.name,
                group_ids: groupId
            };

            const requestMessage1
                = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
            requestMessage1
                .addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
            requestMessage1
                .addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.createUserApiEndPoint
                );
            requestMessage1
                .addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.exampleAPiMethod
                );
            requestMessage1
                .addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(formData)
                );
            this.onCreateUserListCallId = requestMessage1.messageId;

            runEngine.sendMessage(requestMessage1.id, requestMessage1
            );
        }

    };

    submitUserListReceiver = (responseJson: SubmitUserListResponse) => {
        if (responseJson) {
            this.setState({
                createUserAdd: {
                    email: "",
                    name: ""
                },

            }, () => {
                const { userlistPerPage, currentPage } = this.state;
                this.getUserListDetails(userlistPerPage, currentPage);
            });
            this.handleClose();
        }
    }

    handleChangeCreateuser = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        this.setState((prevState) => ({
            createUserAdd: {
                ...prevState.createUserAdd,
                [name]: value,
            },
        }));
    };


    handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({ currentPage: page }, () => {
            const { itemsPerPage } = this.state;
            this.getUserListDetails(itemsPerPage, page);
        });
    };


    // Customizable Area End
}

// Customizable Area End