Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfReportSurveyResult";
exports.labelBodyText = "CfReportSurveyResult Body";

exports.token = "token";
const urlConfig = require("../../../framework/src/config");
exports.APIBaseURL = urlConfig.baseURL;

exports.getSurveyReports = "bx_block_surveys/surveys";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End