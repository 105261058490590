const listeners = {};

export const subscribe = (eventName, callback) => {
  if (!listeners[eventName]) {
    listeners[eventName] = [];
  }
  listeners[eventName].push(callback);
};

export const publish = (eventName, data) => {
  if (listeners[eventName]) {
    listeners[eventName].forEach(callback => {
      callback(data);
    });
  }
};
