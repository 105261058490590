import React, { FunctionComponent, useState, useEffect } from 'react';
import { styled, Typography } from "@material-ui/core";

interface Props {
  data: any;
}

const RankingQuestion: FunctionComponent<Props> = ({ data }) => {

  return (
      <div style={{width : "70%"}}>
        {data.analytics.map((options: any) => {
          return (
            <RankWrapper key={options.option.id}>
              <NameWrapper>{options.option.name}</NameWrapper>
              <RankDetails>
                <RankBox>
                  <RankValue>Rank {options.rank}</RankValue>
                </RankBox>
              </RankDetails>
            </RankWrapper>
          );
        })}
      </div>
  )
};

const RankWrapper = styled('div')({
  background: '#f5f6f8',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  padding: '10px',
  borderRadius: '7px',
  alignItems: 'center',
});
const NameWrapper = styled(Typography)({
  width: '50%',
  fontSize: '13px',
  fontFamily: 'aeonik-regular'
});
const RankDetails = styled('div')({
  display: 'flex',
});

const RankBox = styled('div')({
  backgroundColor: '#e4e8f3',
  margin: '0 5px',
  padding: '5px 10px',
  borderRadius: '6px',
  // textAlign: 'center',
});
const RankValue = styled(Typography)({
  color: '#4B6EB0',
  fontFamily: 'aeonik-regular'
});


export default RankingQuestion;
