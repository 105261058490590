// Customizable Area Start
import React from "react";

import {
	Box,
	Snackbar,
	Typography,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { styled, withStyles } from "@material-ui/core/styles";

import SurveyReportController, { Props } from "./SurveyReportController.web";
import SurveyReportCards from "../../../components/src/SurveyReportCards.web";
import { Link as RouterLink } from "react-router-dom";

export class SurveyReport extends SurveyReportController {
	constructor(props: Props) {
		super(props);
	}
	render() {
		const { classes } = this.props;
		return (
			<Container>
				<MainBox>
					<Box className={classes.cardHeader}>
						<CardTitle variant="h4" align="center" gutterBottom>
							Current survey report - {this.state.currentSectionTitle}
						</CardTitle>
					</Box>
					<Box className={classes.body}>
						<SurveyReportCards
							reports={this.state.currentSurveySection}
							previousSurvey={false}
						/>
					</Box>
					<ButtonWrapper >
					{!this.state.isPreviousSurveyExist &&
					<RouterLink
						to="/SurveysWeb"
						data-test-id="close-button"
						className={classes.closeButton}
					>
						Close survey report
					</RouterLink>
					}	
					</ButtonWrapper>
				</MainBox>
				{this.state.isPreviousSurveyExist &&
				<MainBox style={{marginBottom:'40px',marginTop:'10px'}}>
					<Box className={classes.cardHeader}>
						<CardTitle variant="h4" align="center" gutterBottom>
							Previous survey report - {this.state.previousSectionTitle}
						</CardTitle>
					</Box>
					<Box className={classes.body}>
						<SurveyReportCards
							reports={this.state.previousSurveySection}
							previousSurvey={true}
						/>
					</Box>
					<ButtonWrapper >
					<RouterLink
						to="/SurveysWeb"
						data-test-id="close-button"
						className={classes.closeButton}
					>
						Close survey report
					</RouterLink>
					</ButtonWrapper>
				</MainBox>
				}
				<Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					autoHideDuration={3000}
					open={this.state.snackbar.open}
					onClose={this.handleCloseSnackbar}
				>
					<Alert severity={this.state.snackbar.severity}>
						{this.state.snackbar.message}
					</Alert>
				</Snackbar>
			</Container>
		);
	}
}
const ButtonWrapper = styled('div')({
	display:'flex',
	flexDirection:'row-reverse',
})
const Container = styled('div')({
	display: "flex",
	flexDirection: "column",
	flex: '1 1 0px',
	'@media screen and (max-width:500px)':{
		width:'82%',
		padding:'25px'
	}
})
const MainBox = styled('div')({
	display: 'flex',
	flexDirection: 'column' as const,
	border: '1px solid #ddd',
	margin: '50px',
	boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
	borderRadius: '20px',
	background: "#FFFFFF",
	minHeigth: '70%',
	'@media screen and (max-width:500px)':{
		margin:'0px',
		borderRadius:'10px',
	}
})
const CardTitle = styled(Typography)({
	fontWeight: 700,
	fontSize: '24px',
	lineHeight: ' 23px',
	textAlign: 'left',
	marginBottom: '0px',
	fontFamily: 'aeonik-bold',
	'@media screen and (max-width:500px)':{
		textAlign:"center",
	}
})
const styles = {
	container: {
		display: "flex",
		flexDirection: "column" as const,
		flex: '1 1 0px',
		backgroundColor:'red',
	},
	mainBox: {
		display: 'flex',
		flexDirection: 'column' as const,
		border: '1px solid green',
		margin: '50px',
		boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
		borderRadius: '20px',
		background: "#FFFFFF",
		minHeigth: '70%',
		backgroundColor:'blue',
	},
	cardHeader: {
		borderBottom: '1px solid #E7E7E7',
		padding: '26px 30px',
	},
	cardTitle: {
		fontWeight: 700,
		fontSize: '20px',
		lineHeight: ' 23px',
		textAlign: 'left' as const,
		marginBottom: '0px',
		fontFamily: 'aeonik-bold',
	},
	closeButton: {
		background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
		borderRadius: "10px",
		border: "1px solid #304a7c",
		fontFamily: "aeonik-regular",
		textTransform: "none" as const,
		color: "white",
		fontWeight: 600,
		padding: "14px 10px",
		textDecoration: "none",
		width: "max-content",
		margin: "30px",
		marginTop:'20px'
	  },
};

export default withStyles(styles)(SurveyReport);

// Customizable Area End
