import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  Slide,
  Tabs,
  Tab,
  Grid,
  TextField,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  TextareaAutosize
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/core/styles";


interface TabPanelData {
  value: number;
  index: number;
  children: React.ReactNode;
}

// Customizable Area End

import GroupController, {
  Props,
  User,
  GroupObj,
} from "./GroupsController";
import { plusIcon, cardIcon2 } from "./assets";

export class Groups extends GroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const { tabValue, totalCount, itemsPerPage, userlistPerPage, currentPage, useListcurrentPage } = this.state;
    const { classes } = this.props;
    const totalPages = Math.ceil(totalCount / itemsPerPage);



    function a11yProps(index: number) {
      return {
        'aria-controls': `simple-tabpanel-${index}`,
        id_all: `simple-tab-${index}`,
      };
    }

    const TabPanelSurvey = (props: TabPanelData) => {
      const { index, children, value } = props;

      return (
        <div
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          role="tabpanel"
          aria-labelledby={`simple-tab-${index}`}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    };

    return (
      // Customizable Area Start
      <Box className={classes.mainBox}>
        {
          <Box sx={{ width: '100%' }}  >
            <Box className={classes.cardHeader} >
              <Tabs value={tabValue} data-test-id="tabValue" aria-label="basic tabs example" id="tabChangeSection" onChange={this.handleChangeTab}
                TabIndicatorProps={{
                  style: {
                    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                    height: "10px",
                    borderRadius: "15px 15px 0 0"
                  }
                }
                }>
                <Tab label="Groups" className={classes.tabsurvey} {...a11yProps(1)} />
                <Tab label="UserList" className={classes.tabsurvey} {...a11yProps(0)} />
              </Tabs>
            </Box>
            {tabValue === 1 && (
              <TabPanelSurvey value={tabValue} index={1} data-test-id='tab-panel'>
                <Grid spacing={2} container style={{ padding: "20px" }}>
                  <Grid item xs={6} style={{ justifyContent: 'flex-start' }}>
                    <Button className={classes.createUser} size="large" data-test-id="create-user" onClick={() => this.handleClickOpen()}>
                      Create User
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer >
                      <Table aria-label="customized table" className={classes.table}>
                        <TableHead className={classes.tableheader}>
                          <TableRow>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(this.state.userList || []).map((userList: User, index: number) => (
                            <TableRow key={index}>
                              <TableCell align="center">{userList.name ?? "-"}</TableCell>
                              <TableCell align="center">{userList.email ?? "-"}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box style={{ display: "flex", justifyContent: "center", padding: "20px", border: "1px solid #BBBBBB", alignItems: "center", gap: "10px" }}>
                      {`Showing ${((useListcurrentPage - 1) * userlistPerPage) + 1} to ${Math.min(useListcurrentPage * userlistPerPage, totalCount)} of ${totalCount} Items`}
                      <Pagination
                        className={classes.root}
                        variant="outlined"
                        shape="rounded"
                        id="userPage"
                        count={totalPages}
                        page={useListcurrentPage}
                        onChange={this.handlePageChangeUserList}
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanelSurvey>
            )}
            {tabValue === 0 && (
              <TabPanelSurvey value={tabValue} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <div className={classes.cardBox}>
                      <div className={classes.rectangleBody}>
                        <div className={classes.circle}>
                          <img className={classes.plusIcon} src={plusIcon} data-test-id="img-click" onClick={() => this.handleCreateGroup()} alt="plusIcon" />
                        </div>
                        <Typography className={classes.surveyText}>Create New Group</Typography>
                      </div>
                    </div>
                  </Grid>
                  {(this.state.groupList || []).map((groupList: GroupObj, index: number) => (
                    <Grid key={index} item xs={12} md={6} lg={3}>
                      <div className={classes.card} data-test-id={`card-list`} onClick={() => this.handleUserList(groupList?.id)}>
                        <div className={classes.container}>
                          <div className={classes.mainContainer}>
                            <img className={classes.imgEle} src={cardIcon2} alt="cardIcon" />
                            <Typography className={classes.Respondents}>{groupList?.user_count}<span style={{ marginLeft: "10px" }}>Users</span></Typography>
                          </div>
                          <Typography className={classes.cardHeaderName}>{groupList?.name}</Typography>
                          <Typography style={{textOverflow: "ellipsis",overflow: "hidden"}}>{groupList?.description}</Typography>

                        </div>
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                          <Button size="large" fullWidth data-test-id={`Edit-button${index}`} className={classes.btnEle} >
                            Edit
                          </Button>
                        </Grid>
                      </div>

                    </Grid>
                  ))}
                </Grid>
              </TabPanelSurvey>
            )}
          </Box>
        }

        <Dialog aria-labelledby="customized-dialog-title" open={this.state.createGroup} fullWidth onClose={this.handleCloseGroup}
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <Box className={classes.surveyTital}>
            Create Group
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 20px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Enter the Group Name</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="user-email-name" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.createGroupAdd.email}
                  onChange={(event) => this.handleChangeCreateGroup(event)}
                  name="email"
                  error={this.state.groupError}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Group Description</InputLabel>
                <TextareaAutosize aria-label="empty textarea" data-test-id="user-text-name" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                  width: "100%",
                  height: "116px",
                  padding: "10px",
                  fontSize: "15px"
                }}
                  name="description"
                  value={this.state.createGroupAdd.description}
                  onChange={(event) => this.handleChangeCreateGroup(event)}
                />
              </Grid>
            </Grid>
            <Snackbar open={Boolean(this.state.groupError)} autoHideDuration={4000}
              TransitionComponent={Slide}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              TransitionProps={{
                timeout: 500,
                style: { transformOrigin: 'top center' },
              }}
              onClose={this.handleSnackbarClose}>
              <Alert
                color="error"
                severity="warning"
                style={{ borderRadius: "10px", border: "1px solid #DEDEDE", backgroundColor: 'white', color: 'black', }}>
                Missing required field.
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-create-btn"
                  className={classes.edit}
                  onClick={this.handleGroupSubmit}
                >
                  Create
                </Button>
                <Button data-test-id="cancel-btn-1" className={classes.close} onClick={() => this.handleCloseGroup()} style={{ marginBottom: "30px" }}>cancel</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        {/* create user dialog */}

        <Dialog fullWidth aria-labelledby="customized-dialog-title" open={this.state.cancelModal}
          PaperProps={{
            style: {
              position: 'absolute',
              top: 100,
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
            },
          }}>
          <Box className={classes.surveyTital}>
            Create User
          </Box>
          <DialogContent style={{ padding: "9px 35px 20px 35px", height: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>User Name</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="add-name" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.createUserAdd.name}
                  onChange={(event) => this.handleChangeCreateUser(event)}
                  error={this.state.nameError}
                  name="name"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>User Email Address</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="add-email" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.createUserAdd.email}
                  onChange={(event) => this.handleChangeCreateUser(event)}
                  error={this.state.emailError}
                  name="email"
                />
              </Grid>
            </Grid>
            <Snackbar open={Boolean(this.state.emailError)} autoHideDuration={4000}
              TransitionComponent={Slide}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose} style={{marginTop:"55px"}}>
              <Alert
                color="error"
                severity="warning"
                style={{ backgroundColor: 'white', borderRadius: "10px", color: 'black', border: "1px solid #DEDEDE" }}>
                Email is not a valid email address
              </Alert>
            </Snackbar>
            <Snackbar open={Boolean(this.state.nameError)} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                Missing required field.
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-btn"
                  className={classes.edit}
                  onClick={this.handleUserListSubmit}
                >
                  Create
                </Button>
                <Button data-test-id="cancel-btn-2" className={classes.close} onClick={() => this.handleClose()} style={{ marginBottom: "30px" }}>cancel</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>


        {tabValue === 0 && (
          <Box style={{ display: "flex", justifyContent: "center", padding: "20px", alignItems: "center", gap: "10px" }}>
            {`Showing ${((currentPage - 1) * itemsPerPage) + 1} to ${Math.min(currentPage * itemsPerPage, totalCount)} of ${totalCount} Items`}
            <Pagination
              className={classes.root}
              id="page"
              variant="outlined"
              shape="rounded"
              count={totalPages}
              page={currentPage}
              onChange={this.handlePageChange}
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  root: {
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: "#BFCAE4"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#BFCAE4"
    },
    "& .MuiPaginationItem-outlined": {
      border: '2px solid #F1F1F1',
    },
    "& .MuiPaginationItem-rounded": {
      borderRadius: '8px'
    },
  },
  cardHeader: {
    padding: '0 40px',
    alignItems: 'center',
    display: 'flex',
    minHeight: '72px',
    borderBottom: '1px solid #E7E7E7',
  },
  mainBox: {
    background: "#FFFFFF",
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    margin: '50px',
    border: '1px solid #ddd',
    height: "auto",
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },
  tabsurvey: {
    '&.MuiTab-root': {
      lineHeight: ' 23px',
      fontFamily: 'aeonik-regular',
      opacity: ' 0.4',
      fontWeight: 700,
      color: ' #202020',
      textTransform: 'capitalize' as const,
      minHeight: "100px",
      fontSize: '20px',

    },
    '&.MuiTab-root.Mui-selected': {
      opacity: 1,
      color: ' #202020',
    },
  },
  lable: {
    marginBottom: "10px",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    color: "#333333",

  },
  edit: {
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    padding: '18px 55px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    borderRadius: '10px',
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    lineHeight: 'normal',
  },
  modalbtn: {
    alignItems: "center",
    display: 'flex',
    flexDirection: 'column' as const,
    marginTop: "15px",
    gap: "10px",
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  surveyTital: {
    fontSize: "24px",
    color: "#202020",
    fontFamily: 'aeonik-regular',
    fontWeight: 700,
    padding: "20px",
    display: "flex",
    borderBottom: "1px solid #E7E7E7",
    justifyContent: "center",
    marginBottom: "10px"
  },
  rectangleBody: {
    alignItems: 'center',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    display: 'flex',
  },
  circle: {
    width: '58px',
    borderRadius: '50%',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    height: '58px',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '24px',
    justifyContent: 'center',
  },
  plusIcon: {
    height: '43.5px',
    width: '43.5px',
  },
  surveyText: {
    fontWeight: 700,
    fontSize: '16px',
    color: 'rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)',
    lineHeight: 'normal',
  },
  btnEle: {
    padding: '14px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontStyle: 'normal',
    flex: '1 1 0px',
    textTransform: "capitalize" as const,
  },
  card: {
    flexDirection: 'column' as const,
    display: 'flex',
    minHeight: '240px',
    justifyContent: "space-between",
    borderRadius: '28px' as const,
    padding: '24px 16px',
    background: '#FCFCFC',
    border: '0.5px solid #DEDEDE',
  },
  container: {
    flexDirection: 'column' as const,
    color: 'var(--text, #202020)',
    display: 'flex',
  },
  imgEle: {
    height: '50px',
    marginBottom: '24px',
    width: '50px',
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'center',
    border: "1px dashed #A7A7A7",
    height: '280px',
    borderRadius: '28px' as const,
    background: '#FCFCFC',
  },
  Respondents: {
    borderRedius: "5px",
    height: "22px",
    fontWeight: 600,
    gap: "10px",
    textAlign: "center" as const,
    fontSize: "15px",
    padding: "5px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
  },
  createUser: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    fontWeight: 700,
    width: "20%"
  },
  table: {
    border: "1px solid #B2B2B2",
  },
  tableheader: {
    backgroundColor: "#ECF2FF"
  },
  cardHeaderName: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '16px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    color: "#121212",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
};

export default withStyles(styles)(Groups);
// Customizable Area End
