import React, { FunctionComponent, ChangeEvent } from 'react';

import {
    Typography
} from "@material-ui/core";
import Placeholder from 'react-select/src/components/Placeholder';

interface Props {
    onChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void;
    readOnly?:boolean,
    disabled?:boolean,
    label?: string
    value?: any;
    style?: React.CSSProperties;
    error?: string;
    isHeadingBold?: boolean;
    maxLength?: number;
    placeholder?: string;
}

const Input: FunctionComponent<Props> = ({ 
    readOnly,
    disabled,
    onChange, 
    label, 
    style, 
    value, 
    error = '', 
    isHeadingBold = true, 
    maxLength = undefined, 
    placeholder = "", 
    ...props 
}) => {

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div style={{ ...styles.container, ...style }}>
            <label htmlFor="myInput">
                <Typography style={isHeadingBold ? styles.labelBold : styles.labelNormal}>{label}</Typography>
            </label>
            <textarea readOnly={readOnly} disabled={disabled} value={value} onChange={handleInputChange} 
            style={{ ...styles.input,...(error && styles.error),}}{...props} maxLength={maxLength} placeholder={placeholder}/>
            {error && <Typography style={styles.errorText}>{error}</Typography>}
        </div>
    )
};


const styles = {
    container: {
        display: 'flex',
        flex: '1 1 0px',
        flexDirection: 'column' as const,
    },
    input: {
        display: 'flex',
        padding: '16px',
        flex: '1 0 0',
        borderRadius: '10px',
        border: '1px solid #E0E0E0',
        background: '#FFF',
        outline: 'none',
        color: '#505050',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        maxWidth: '100%',
        minHeight: '140px',
        fontFamily: 'aeonik-regular',
        resize: 'none' as const        
    },
    error: {
        border: '1px solid red',
    },
   labelBold: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontFamily: 'aeonik-bold',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '12px'
    },
    labelNormal: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginBottom: '12px',
        fontFamily: 'aeonik-regular',
    },
    errorText: {
        color: 'red',
        marginTop: '8px'
    }
};

export default Input;