Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getTermsConditions = "bx_block_terms_and_conditions/terms_and_conditions";
exports.getPrivacyPolicy = "bx_block_privacy_policy/privacy_policies";
exports.token = "token";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End