import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import moment from "moment";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tagsList: any[];
  isLoading: boolean;
  page: number;
  perPage: number;
  totalCount: number;
  createModalVisible: boolean;
  isEditModal: boolean;
  editTagId: number;
  tagName: string;
  tagNameError: string;
  tagDescription: string;
  tagDescriptionError: string;
  apiErrorMessage: string;
  apiError: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TagsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTagsListCallId: string = "";
  submitTagCallId: string = "";

  private observer: IntersectionObserver | null = null;
  public loadingRef: React.RefObject<HTMLDivElement> = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      tagsList: [],
      isLoading: false,
      page: 1,
      createModalVisible: false,
      isEditModal: false,
      editTagId: 0,
      tagName: "",
      tagNameError: "",
      tagDescription: "",
      tagDescriptionError: "",
      apiError: false,
      apiErrorMessage: "",
      perPage: 12,
      totalCount: 0,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.getTagsListCallId:
        this.tagListReceive(responseJson);
        break;
      case this.submitTagCallId:
        this.handleTagSubmissionReceive(responseJson);
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  loadMoreData = () => {
    this.setState(
      (prevState) => ({ ...prevState, page: prevState.page + 1, isLoading: true }),
      this.getTagsList
    );
  };

  handleObserver(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    const y = entries[0].isIntersecting;
    if (entries[0].isIntersecting) {
      if (this.state.tagsList.length < this.state.totalCount && !this.state.isLoading) {
        this.loadMoreData();
      }
    }
  }

  async componentDidMount(): Promise<void> {
    await Promise.all([this.getTagsList()]);
    var options = {
      rootMargin: "0px",
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);
    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }
  }

  getTagsList = () => {
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTagsApiEndPoint}?page=${this.state.page}&per_page=${this.state.perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTagsApiMethod
    );

    this.getTagsListCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  tagListReceive = (responseJson: any) => {
    if (responseJson?.tags) {
      this.setState((prevState) => ({
        ...prevState,
        tagsList: [...prevState.tagsList, ...responseJson.tags],
        totalCount: responseJson?.total_count,
        isLoading: false,
      }));
    }
  };

  openCreateTagModal = () => {
    this.setState({
      createModalVisible: true,
      isEditModal: false,
    });
  };

  openEditTagModal = (name: string, description: string, tagId: number) => {
    this.setState({
      createModalVisible: true,
      tagName: name,
      tagDescription: description,
      isEditModal: true,
      editTagId: tagId,
    });
  };

  closeCreateTagModal = () => {
    this.setState({
      createModalVisible: false,
      tagName: "",
      tagNameError: "",
      tagDescription: "",
      tagDescriptionError: "",
      editTagId: 0,
    });
  };

  tagNameChange = (e: any) => {
    this.setState({
      tagName: e.target.value,
      tagNameError: "",
    });
  };

  tagDescriptionChange = (e: any) => {
    this.setState({
      tagDescription: e.target.value,
      tagDescriptionError: "",
    });
  };

  createTagSubmit = () => {
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    const jsonBody = {
      name: this.state.tagName,
      description: this.state.tagDescription,
    };

    const endPoint = this.state.isEditModal
      ? `${configJSON.updateTagApiEndPoint}?id=${this.state.editTagId}`
      : configJSON.createTagApiEndPoint;

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isEditModal ? configJSON.updateTagApiMethod : configJSON.createTagApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(jsonBody)
    );
    this.submitTagCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTagClick = () => {
    if (!this.state.tagName) {
      this.setState((prevState) => {
        return { ...prevState, tagNameError: "tag name is required" };
      });
      return;
    }

    if (!this.state.tagDescription) {
      this.setState((prevState) => {
        return { ...prevState, tagDescriptionError: "tag description is required" };
      });
      return;
    }

    this.createTagSubmit();
  };

  handleTagSubmissionReceive(responseJson: any) {
    if (!responseJson || responseJson?.errors) {
      return this.setState({
        apiError: true,
        apiErrorMessage: responseJson?.errors?.[0] || "Something went wrong",
      });
    }

    this.closeCreateTagModal();
    if (this.state.isEditModal) {
      let tagsList = [...this.state.tagsList];
      let editInd = tagsList.findIndex((tag) => tag.id === responseJson.id);
      if (editInd !== -1) {
        tagsList[editInd] = responseJson;
      }
      this.setState({ tagsList: [...tagsList] });
    } else if (this.state.tagsList.length >= this.state.totalCount) {
      console.log("here")
      this.setState((prevState) => ({
        ...prevState,
        tagsList: [...prevState.tagsList, responseJson],
        totalCount: prevState.totalCount + 1,
      }));
    }
  }

  handleSnackbarClose = () => {
    this.setState({ apiError: false, apiErrorMessage: "" });
  };

  async componentWillUnmount(): Promise<void> {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  // Customizable Area End
}
