import React from "react";

import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // Customizable Area Start
  Paper,
  Typography,
  styled,
  // Customizable Area End
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import TextualTypeController, { Props } from "./TextualTypeController.web";
// Customizable Area End

export class TextualType extends TextualTypeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ResponseTypography = ({ index }: { index: number }) => {
    return (
      <Typography
        style={{
          color: '#9BB4F4',
          fontSize: '14px',
          fontFamily: 'aeonik-bold',
          fontWeight: 500,
          marginBottom: '6px',
          marginTop: '6px',
        }}>
        Response {index + 1}
      </Typography>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { responseList, responseCount, page, rowsPerPage } = this.state;
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <StyledTypography>
          {`(${responseCount}  Responses)`}
        </StyledTypography>
        <Box>
          <StyledPaper>
            {responseList.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <this.ResponseTypography index={index} />
                <Typography variant="body2"
                  style={{
                    fontSize: '14px',
                    fontFamily: 'aeonik-regular',
                  }}>
                  {data.data ? data.data : 'No Response'}
                </Typography>
              </React.Fragment>

            ))}
          </StyledPaper>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledTypography = styled(Typography)({
  fontSize: '14px',           
  margin: '6px',
  display: 'inline',
  '&.detail': {
  }
});

const StyledPaper = styled(Paper)({
  padding: '10px',
  fontFamily: 'aeonik-regular',
  margin: '6px',
  paddingTop: "3px",
  background: '#E4E8F2',
  elevation: 2,
});

const styles = {
  mainContainer: {
    border: "1px solid #ddd",
    margin: "50px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    height: "auto",
    padding: "32px",
    "@media (max-width: 500px)": {
      margin: "20px",
    },
  },
  header: {
    height: "48px",
    borderBottom: "1px solid #dedede",
    marginBottom: "42px",
  },
  heading: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    color: "#202020",
  },
};
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(TextualType);
// Customizable Area End
