import React, { FunctionComponent } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import QuestionCard from './QuestionCard.web';
import Input from './CustomInput.web';

interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey:boolean;
}
const useStyles = makeStyles({
  mainBox: {
    fontFamily: 'aeonik-regular',
    "& ::placeholder":{
        fontFamily: 'aeonik-regular',
    },
}
});

const SliderQuestion: FunctionComponent<Props> = ({ onChange, data, onDeleteClick,isLiveorPastSurvey }) => {
  const classes = useStyles()
  const minNumber = parseInt(data?.min_number, 10);
  const maxNumber = parseInt(data?.max_number, 10);
  const isWarningVisible = maxNumber - minNumber < 2 || maxNumber - minNumber > 100;

  return (
    <Box className={classes.mainBox}>
    <QuestionCard
      isLiveorPastSurvey={isLiveorPastSurvey}
      title='Slider question'
      data={data}
      required
      onDeleteClick={() => onDeleteClick(data?.ind)}
      onRequiredCheck={(val) => onChange({
        ...data,
        required: val
      })}
    >
      <Input
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        maxLength={128}
        placeholder='Add question text'
        value={data?.question_title}
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event?.target.value,
          })
        }
      />
      <div style={styles.selectContainer}>
        <Input
          readOnly={isLiveorPastSurvey}
          disabled={isLiveorPastSurvey}
          name='min-input'
          maxLength={10}
          style={styles.select}
          type='number'
          min={0}
          max={98}
          value={data?.min_number}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            let value = target.value;
            const numericValue = parseFloat(value);

            if (value === '' || (!isNaN(numericValue) && numericValue <= 98 && numericValue >= 0)) {
              onChange({
                ...data,
                min_number: value
              });
            }
          }}
          onKeyDown={(event) => {
            const target = event.target as HTMLInputElement;
            const value = parseFloat(target.value);

            if (event.key === 'ArrowUp' && value >= 100) {
              event.preventDefault();
            }
            if (event.key === 'ArrowDown' && value <= 2) {
              event.preventDefault();
            }
          }}
        />
        <Typography style={styles.to}>to</Typography>
        <Input
          readOnly={isLiveorPastSurvey}
          disabled={isLiveorPastSurvey}
          name='max-input'
          maxLength={10}
          style={styles.select}
          type='number'
          min={2}
          max={100}
          value={data?.max_number}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            let value = target.value;
            const numericValue = parseFloat(value);
            console.log({  numericValue })
            if (value === '' || (!isNaN(numericValue) && numericValue <= 100 && numericValue >= 1)) {
              console.log({ numericValue }, "inside-if-87")
              onChange({
                ...data,
                max_number: numericValue 
              });
            }
         
          }}
          onKeyDown={(event) => {
            const target = event.target as HTMLInputElement;
            const value = parseFloat(target.value);

            if (event.key === 'ArrowUp' && value >= 100) {
              event.preventDefault();
            }
            if (event.key === 'ArrowDown' && value <= 2) {
              event.preventDefault();
            }
          }}
        />
      </div>

      {isWarningVisible && (
        <div>
          <p style={styles.warning}>
             Maximum value should be greater than minimum value with a minimum difference of 2, and maximum value is 100 {/* change the warning message based ont he requirement. */}
          </p>
        </div>
      )}
      <div style={styles.inputContainer}>
        <Input
          readOnly={isLiveorPastSurvey}
          disabled={isLiveorPastSurvey}
          maxLength={32}
          style={{ ...styles.minInput, ...styles.input }}
          value={data?.lower_label}
          placeholder='Minimum'
          label='Lower label'
          onChange={(event) => {
            onChange({
              ...data,
              lower_label: event?.target.value,
            })
          }}
        />
        <Input
          readOnly={isLiveorPastSurvey}
          disabled={isLiveorPastSurvey}
          maxLength={32}
          style={{ ...styles.input }}
          value={data?.higher_label}
          placeholder='Maximum'
          label='Higher label'
          onChange={(event) => {
            onChange({
              ...data,
              higher_label: event?.target.value,
            })
          }}
        />
      </div>
    </QuestionCard>
    </Box>
  );
};

const styles = {
  select: {
    maxWidth: '105px'
  },
  selectContainer: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  to: {
    margin: '0px 24px',
    fontFamily: 'aeonik-regular'
  },
  warning: {
    marginTop: '6px',
    color: '#f54f5f',
    fontFamily: 'aeonik-regular'
  },
  minInput: {
    marginRight: '24px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px'
  },
  input: {
    maxWidth: '267px'
  }
};

export default SliderQuestion;
