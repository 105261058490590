import React, {
  FunctionComponent,
  } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  value: number;
  text: string;
  classes: any;
  barColor: string;
  bgColor: string;
  textColor: string;
}

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor:  'white',
    border: "1px solid #ccc",
    padding: "5px 10px",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
    color: theme.palette.common.black,
    fontSize: 14,
  },
}));

function BootstrapTooltip(props:any) {
  const classes = useStylesBootstrap();

  return <Tooltip classes={classes} {...props} />;
}

const ProgressBar: FunctionComponent<Props> = ({
  value,
  text,
  classes,
  barColor,
  bgColor,
  textColor
}) => {

  return (
    <BootstrapTooltip  title={text} placement="top">
      <div className={classes.outerContainer} style={{ flexDirection: "row", backgroundColor: bgColor }}>
        <div
          style={{
            width: `${value}%`,
            boxSizing: "border-box",
            position: "relative",
            backgroundColor: barColor
          }}
          className={classes.innerContainer}
        >
          <div
            style={{ color: textColor }}
          >
          </div>
          <div style={{color: textColor}} className={classes.percentValue}>{value} %</div>
        </div>
      </div>
    </BootstrapTooltip>
  );
};

const styles = {
  outerContainer: {
    width: "100%",
    backgroundColor: "transparent",
    margin: "18px 0",
    display: "flex",
    alignItems: "center",
  },
  innerContainer: {
    height: "30px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  },
  percentValue: {
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
    fontSize: "14px",
    whiteSpace: "nowrap" as const,
    position: "absolute" as const,
    left:"10px"  // Added px to the value
  },
};

export default withStyles(styles)(ProgressBar);
