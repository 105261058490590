import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  Typography,
  Slide,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  TextareaAutosize,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import { Alert } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import TagsController, { Props } from "./TagsController.web";
import { uploadIcon, rectangleIcon, plusIcon, cardIcon2, deleteIcon } from "./assets";
// Customizable Area End

export class TagsCRUD extends TagsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      tagsList,
      createModalVisible,
      isEditModal,
      tagName,
      tagDescription,
      tagNameError,
      tagDescriptionError,
      apiError,
      apiErrorMessage,
      isLoading,
    } = this.state;

    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <div
              className={classes.cardBox}
              onClick={this.openCreateTagModal}
              data-test-id="create-tag"
            >
              <div className={classes.rectangleBody}>
                <div className={classes.circle}>
                  <img className={classes.plusIcon} src={plusIcon} alt="plusIcon" />
                </div>
                <Typography className={classes.surveyText}>Create New Tag</Typography>
              </div>
            </div>
          </Grid>
          {(tagsList || []).map((unitTag: any, ind: any) => (
            <Grid key={ind} item xs={12} md={6} lg={3} data-test-id={`unit-tag-${ind}`}>
              <div className={classes.card}>
                <div className={classes.container}>
                  <div className={classes.mainContainer}>
                    <img className={classes.img} src={cardIcon2} alt="cardIcon" />
                    <Typography className={classes.Respondents}>
                      {unitTag.user_count || 0} Users
                    </Typography>
                  </div>
                  <Typography style={{ wordWrap: "break-word" as const }} className={classes.cardHeaderName}>
                    {unitTag?.name}
                  </Typography>
                  <Typography style={{ wordWrap: "break-word" as const }}>{unitTag?.description}</Typography>
                </div>
                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      onClick={() =>
                        this.openEditTagModal(unitTag?.name, unitTag?.description, unitTag?.id)
                      }
                      size="large"
                      fullWidth
                      data-test-id={`edit-tag-btn-${ind}`}
                      className={classes.createUser}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ))}
          <div data-test-id="loadingRef" ref={this.loadingRef} style={{ minHeight: "10px", width: "100%" }}>
            {isLoading ? (
              <Box style={styles.spinnerContainer}>
                <CircularProgress color="primary" />
              </Box>
            ) : null}
          </div>
        </Grid>

        {/* createTag */}
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={createModalVisible}
          fullWidth
          onClose={this.closeCreateTagModal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
              top: 40,
              position: "absolute",
            },
          }}
        >
          <Box data-test-id="modal-title" className={classes.surveyTital}>
            {isEditModal ? "Edit Tag" : "Create Tag"}
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 15px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Enter the Tag Name</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  data-test-id="tag-name"
                  className={classes.inputBox}
                  value={tagName}
                  onChange={(event) => this.tagNameChange(event)}
                  name="tagName"
                  error={!!tagNameError}
                  helperText={tagNameError}
                  placeholder="Enter name"
                  inputProps={{maxLength: 64}}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Tag Description</InputLabel>
                <TextareaAutosize
                  aria-label="empty textarea"
                  style={{
                    border: tagDescriptionError ? "1px solid #f44336" : "1px solid #E0E0E0",
                    borderRadius: "10px",
                    width: "100%",
                    height: "116px",
                    padding: "14px",
                    fontSize: "16px",
                    resize: "none",
                    fontFamily: 'aeonik-regular',
                    fontWeight: 500,
                    color: "#202020",
                  }}
                  name="description"
                  value={tagDescription}
                  onChange={(event) => this.tagDescriptionChange(event)}
                  placeholder="Enter description"
                  data-test-id="tag-description"
                  maxLength={512}
                  // error={tagDescriptionError}
                />
                {tagDescriptionError && (
                  <p className={classes.errorHelper}>{tagDescriptionError}</p>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Submit-btn"
                  className={classes.edit}
                  onClick={this.createTagClick}
                >
                  {isEditModal ? "Update" : "Create"}
                </Button>
                <Button
                  data-test-id="cancel-btn-tag"
                  className={classes.close}
                  onClick={this.closeCreateTagModal}
                  style={{ marginBottom: "30px" }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
          <Snackbar
            open={apiError}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={Slide}
            TransitionProps={{
              style: { transformOrigin: "top center" },
              timeout: 500,
            }}
            onClose={this.handleSnackbarClose}
          >
            <Alert
              severity="error"
              color="error"
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                border: "1px solid #DEDEDE",
              }}
            >
              {apiErrorMessage}
            </Alert>
          </Snackbar>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  mainBox: {
    border: "1px solid #ddd",
    margin: "50px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    height: "auto",
    "@media (max-width: 500px)": {
      margin: "20px",
    },
  },
  cardBox: {
    display: "flex",
    justifyContent: "center",
    border: "1px dashed #A7A7A7",
    height: "100%",
    minHeight: "270px",
    borderRadius: "28px" as const,
    background: "#FCFCFC",
    cursor: "pointer",
  },
  rectangleBody: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    borderRadius: "50%",
    width: "58px",
    height: "58px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "24px",
  },
  plusIcon: {
    width: "43.5px",
    height: "43.5px",
  },
  surveyText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    color: "rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)",
  },
  card: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    minHeight: "270px",
    padding: "24px 16px",
    borderRadius: "28px" as const,
    border: "0.5px solid #DEDEDE",
    background: "#FCFCFC",
    height: "100%",
    boxSizing: "border-box" as const
  },
  container: {
    display: "flex",
    flexDirection: "column" as const,
    color: "var(--text, #202020)",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  img: {
    width: "50px",
    height: "50px",
    marginBottom: "24px",
  },
  Respondents: {
    // width: "150px",
    height: "22px",
    borderRedius: "5px",
    gap: "10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
    padding: "5px",
    color: "#304a7c",
  },
  cardHeaderName: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "16px",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    color: "#121212",
  },
  createUser: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    fontWeight: 700,
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px",
  },
  inputBox: {
    borderRadius: "10px",
    fontSize: "16px",
    fontWeight: 500,
    color: "#202020",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#e0e0e0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#e0e0e0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
  modalbtn: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "5px",
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  edit: {
    padding: "18px 55px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    fontSize: "16px",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    "&:hover": {
      backgroundColor: "#1C386D",
      boxShadow: "none",
    },
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    margin: "10px 0",
  },
  errorHelper: {
    fontSize: "1rem",
    marginLeft: "14px",
    marginTop: "3px",
    fontWeight: 400,
    color: "#f44336",
    fontFamily: "Roboto",
  },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90px",
  },
};

export default withStyles(styles)(TagsCRUD);
// Customizable Area End
