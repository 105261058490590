import React, {
  FunctionComponent,
  // Customizable Area Start
  // Customizable Area End
} from "react";

import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
// Customizable Area Start
const surveyCardIcon = require("./clarity_form-line.svg");
import moment from "moment";
// Customizable Area End

import {
  Box,
  Grid,
  Typography,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

interface Props {
  // Customizable Area Start
  surveyData: any;
  surveyInd: number;
  classes: any;
  exportSurvey: () => void
  // Customizable Area End
}

const SurveyCard: FunctionComponent<Props> = ({
  // Customizable Area Start
  surveyData, surveyInd, classes,exportSurvey
// Customizable Area End
}) => {
// Customizable Area Start
const getCardDate = (date: string, time: string) => {
  if (date === null || time === null) {
    return "Not scheduled"
  }
  const editedTime = time?.split("T")[1]?.slice(0, 5) || ''
  const local = moment.utc(`${date} ${editedTime}`,"YYYY-MM-DD HH:mm")
  return local?.local()?.format("DD MMMM YYYY")
}
// Customizable Area End
  return (
    // Customizable Area Start
    <Grid item xs={12} md={6} lg={3} key={surveyInd}>
      <Box className={classes.card}>
        <div className={classes.container}>
          <Box className={classes.mainContainer}>
            <img className={classes.img} src={surveyCardIcon} alt="cardIcon" />
            <Typography
              data-test-id={`user-label-${surveyData?.id}`}
              className={classes.Respondents}
            >{`${+surveyData?.attributes?.respondents} Respondents`}</Typography>
          </Box>
          <Typography className={classes.cardHeaderName}>{surveyData?.attributes?.name}</Typography>
          <Box className={classes.dateAndTime}>
            <Box>
              <Typography className={classes.date}>
                <span className={classes.title}>Start Date</span>
              </Typography>
              <Typography className={classes.dateshow1}>
                <span className={classes.dateBox}>
                  {getCardDate(surveyData?.attributes?.start_date, surveyData?.attributes?.start_time)}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.date}>
                <span className={classes.title}>End Date</span>
              </Typography>
              <Typography className={classes.dateshow2}>
                <span className={classes.dateBox}>
                  {getCardDate(surveyData?.attributes?.end_date, surveyData?.attributes?.end_time)}
                </span>
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <RouterLink to={`/AnalyticsWeb?surveyId=${surveyData?.id}`}>
                <Button
                  size="large"
                  fullWidth
                  data-test-id={`survey-export-button-${surveyData?.id}`}
                  className={classes.btn}
                >
                  View results
                </Button>
              </RouterLink>
            </Grid>
            <Grid item xs={12}>
              
                <Button
                  onClick={exportSurvey}
                  size="large"
                  fullWidth
                  data-test-id={`survey-view-result-button-${surveyData?.id}`}
                  className={classes.btnSecondary}
                >
                  Export results
                </Button>
              
            </Grid>
          </Grid>
        </div>
      </Box>
    </Grid>
    // Customizable Area End
  );
};

// Customizable Area Start
const styles = {
  card: {
    minHeight: "280px",
    borderRadius: "28px" as const,
    border: "0.5px solid #DEDEDE",
    background: "#FCFCFC",
  },
  container: {
    padding: "12px 16px",
    '@media (max-width: 1340px)': {
      padding : "12px 11px",
    },
    display: "flex",
    flexDirection: "column" as const,
    color: "var(--text, #202020)",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  img: {
    width: "50px",
    height: "50px",
    marginBottom: "24px",
  },
  cardHeaderName: {
    height:"30px",
    fontSize: "20px",
    marginBottom:"8px",
    overflow:'auto',
    fontWeight: 600,
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    color: "#121212",
  },
  Respondents: {
    width: "150px",
    height: "22px",
    padding: "8px",
    borderRedius: "5px",
    gap: "10px",
    backgroundColor: "#E9EFFD",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
  },
  dateAndTime: {
    padding : "2px",
    display: "flex",
    gap: "2em",
    '@media (max-width: 1420px)': {
      gap: "0.4em",
      padding : "0px",
    },
    alignItems: "center",            
  },
  date: {
    fontSize: "11px",
    color: "#202020",
    marginBottom: "10px",
    fontFamily: 'aeonik-regular',
  },
  title: {
    color: "Gray",
    fonSize: "10px",
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  dateshow1: {
    padding: "7px",
    borderRadius: "5px",
    '@media (max-width: 1340px)': {
      padding: "6px",
    },
    gap: "8px",
    backgroundColor: "#DEF0DB",
    color: "#202020",
    width: "max-content",
  },
  dateshow2: {
    padding: "7px",
    '@media (max-width: 1340px)': {
      padding: "6px",
    },
    borderRadius: "5px",
    gap: "8px",
    backgroundColor: "#FFF3DB",
    color: "#202020",
    width: "max-content",
  },
  dateBox: {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  btn: {
    borderRadius: "10px",
    background: "var(--g, linear-gradient(180deg, #365081 100%, #BFCAE4 0%))",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontSize:"18px"
  },
  btnSecondary: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    fontSize:"18px",
    textTransform: "none" as const,
    fontWeight: 700,
  },
};

export default withStyles(styles)(SurveyCard);
// Customizable Area End
